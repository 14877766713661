/**
 * onlyNumbers
 * @param {string} text
 * @returns string
 */
export function onlyNumbers(text) {
	if (!text) {
		return null;
	}

	const numbers = text.replace(/\D+/g, '');

	if (!numbers) {
		return null;
	}

	return numbers;
}

/**
 * onlyLetters
 * @param {string} text
 * @returns string
 */
export function onlyLetters(text) {
	if (!text) {
		return null;
	}

	const letters = text.replace(/[^A-Za-z_ ]/gi, '');

	return letters;
}

/**
 * phoneFormat
 * @param {string} phone
 * @return {string} formatted phone like (00) 0000-0000 or (00) 00000-0000
 */
export const phoneFormat = phone => {
	if (!phone) {
		return phone;
	}
	const str = `${phone}`;
	const onlyNums = str.replace(/[^\d]/g, '');
	if (onlyNums.length === 2) {
		return `(${onlyNums})`;
	}
	if (onlyNums.length <= 2) {
		return onlyNums;
	}
	if (onlyNums.length <= 6) {
		return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}`;
	}
	if (onlyNums.length <= 10) {
		return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(
			6,
			11
		)}`;
	}
	return `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
};
