import React from 'react';
import { useMutation } from '@apollo/client';

import UserForm from 'components/users/UserForm';
import { useNavigation } from 'components/navigation/hooks';
import { MUTATION_CREATE_USER } from 'components/users/graphql';

import validationSchema from './validationSchema';

const EXCLUDE_ON_SUBMIT = ['countryCode', 'passwordConfirmation'];

const INITIAL_VALUES = {
	firstName: '',
	lastName: '',
	role: '',
	email: '',
	country: '',
	state: '',
	city: '',
};

const MyAccountForm = () => {
	const { navigate } = useNavigation();
	const [createUser, { loading }] = useMutation(MUTATION_CREATE_USER);

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await createUser({
					variables: {
						input,
					},
				});
				navigate('/admin/usuarios');
			} catch (err) {
				console.log('handleOnSubmit error', err);
				throw err;
			}
		},
		[createUser, navigate]
	);

	return (
		<UserForm
			loading={loading}
			initialValues={INITIAL_VALUES}
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			excludeOnSubmit={EXCLUDE_ON_SUBMIT}
		/>
	);
};

export default React.memo(MyAccountForm);
