import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

const TheTableCell = ({ item, fields, formatter }) => {
	const setContent = React.useCallback(() => {
		if (formatter) {
			if (fields.length === 1) {
				let params = {};
				let field = fields[0];

				if (field.includes('.')) {
					params[field] = field.split('.').reduce((o, i) => o[i], item);
				} else {
					params[field] = item[field];
				}

				return formatter(params);
			} else {
				let params = {};

				fields.forEach((el, idx, arr) => {
					if (el.includes('.')) {
						params[el] = el.split('.').reduce((o, i) => o[i], item);
					} else {
						params[el] = item[el];
					}

					params[el] = item[el];
				});

				return formatter(params);
			}
		}

		return fields[0].split('.').reduce((o, i) => o[i], item);
	}, [fields, formatter, item]);

	const content = setContent();

	return <TableCell>{content}</TableCell>;
};

const TheTableBody = ({ data, dataMap, onRowClick, actions: Actions }) => {
	return (
		<TableBody>
			{data.map((item, idx) => (
				<TableRow
					hover
					key={`item${idx}`}
					onClick={() => onRowClick(item.id, item)}
				>
					{dataMap.map((dMap, idx) => (
						<TheTableCell
							key={idx}
							item={item}
							fields={dMap.fields}
							formatter={dMap.formatter}
						/>
					))}
					{Actions && (
						<TableCell component="th" scope="row" align="right">
							<Actions item={item} />
						</TableCell>
					)}
				</TableRow>
			))}
		</TableBody>
	);
};

export default React.memo(TheTableBody);
