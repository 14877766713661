import { t } from '@lingui/macro';

export const ADMIN = 'ADMIN';
export const CUSTOMER = 'CUSTOMER';
export const TECHNICIAN = 'TECHNICIAN';

export const ROLES = {
	[ADMIN]: t({
		id: 'role.admin',
		message: 'Administrador',
	}),
	[CUSTOMER]: t({
		id: 'role.customer',
		message: 'Cliente',
	}),
	[TECHNICIAN]: t({
		id: 'role.technician',
		message: 'Técnico',
	}),
};

export const ROLES_ARRAY = [
	{
		label: t({
			id: 'role.admin',
			message: 'Administrador',
		}),
		value: ADMIN,
		route: {
			label: t({
				id: 'role.p.admin',
				message: 'Administradores',
			}),
			param: 'administradores',
			paramSingular: 'administrador',
		},
	},
	{
		label: t({
			id: 'role.customer',
			message: 'Cliente',
		}),
		value: CUSTOMER,
		route: {
			label: t({
				id: 'role.customers',
				message: 'Clientes',
			}),
			param: 'clientes',
			paramSingular: 'cliente',
		},
	},
	{
		label: t({
			id: 'role.technician',
			message: 'Técnico',
		}),
		value: TECHNICIAN,
		route: {
			label: t({
				id: 'role.technicians',
				message: 'Técnicos',
			}),
			param: 'tecnicos',
			paramSingular: 'tecnico',
		},
	},
];
