import React from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import EventIcon from '@material-ui/icons/Event';
import CategoryIcon from '@material-ui/icons/Category';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { QUERY_REPORT } from 'components/reports/graphql';
import { millissecondsToDateAndTime } from 'utils/date';

import Loading from 'UI/Loading';

const Card = styled.div`
	display: flex;
	margin-bottom: 1rem;
	position: relative;
	flex-direction: row;
	width: 100%;
	border: solid 1px ${({ theme }) => theme.palette.secondary.main};
	padding: 0;
	border-radius: ${p => p.theme.shape.borderRadius}px;
	background-color: #fff;

	@media (max-width: 720px) {
		flex-direction: column;
	}

	svg {
		margin-right: 0.5rem;
	}
`;

const Name = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	padding: 1.4rem 1.4rem;
`;

const Date = styled.div`
	display: flex;
	align-items: center;
	padding: 1.4rem 1.4rem;
	border-left: solid 1px ${({ theme }) => theme.palette.secondary.main};
	border-right: solid 1px ${({ theme }) => theme.palette.secondary.main};

	@media (max-width: 720px) {
		border: 0;
		border-top: solid 1px ${({ theme }) => theme.palette.secondary.main};
		border-bottom: solid 1px ${({ theme }) => theme.palette.secondary.main};
	}
`;

const Action = styled.div`
	display: flex;
	align-items: center;
	padding: 1.4rem 1.4rem;
	cursor: pointer;
`;

const SerialNumber = styled.div`
	font-weight: bolder;
	padding: 0 0.4rem;
`;

const Item = ({ id, serialNumber, autoclave, csvCreatedAt }) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const [downloadFile, setDownloadFile] = React.useState(false);

	const [report, { data, loading }] = useLazyQuery(QUERY_REPORT, {
		fetchPolicy: 'network-only',
	});

	const reportPath = data?.report?.path;

	const handleReport = React.useCallback(async () => {
		try {
			setIsLoading(true);
			await report({
				variables: {
					id,
				},
			});
			setDownloadFile(true);
		} catch (error) {
			console.log('handleReport', error);
		} finally {
			setIsLoading(false);
		}
	}, [id, report]);

	React.useEffect(() => {
		if (reportPath && downloadFile) {
			setIsLoading(true);

			let timer;
			timer = setTimeout(() => {
				var link = document.createElement('a');
				link.setAttribute('id', 'tempReportLink');
				link.href = reportPath;
				link.download = `relatorio-${serialNumber}.pdf`;
				document.body.appendChild(link);
				link.dispatchEvent(new MouseEvent('click'));
				document.body.removeChild(link);

				setIsLoading(false);
				setDownloadFile(false);

				clearTimeout(timer);
			}, 4000);
		}
	}, [downloadFile, reportPath, serialNumber]);

	return (
		<>
			{(loading || isLoading) && <Loading fullScreen />}
			<Card>
				<Name>
					<CategoryIcon />
					<Trans id="ui.abbr.serialNumber">S/N</Trans>{' '}
					<SerialNumber>{serialNumber}</SerialNumber>{' '}
					<Trans id="ui.model">Modelo</Trans> {autoclave?.model?.name}
				</Name>
				<Date>
					<EventIcon />
					{millissecondsToDateAndTime(csvCreatedAt)}
				</Date>
				<Action onClick={handleReport}>
					<PictureAsPdfIcon />
					<Trans id="ui.download">Download</Trans>
				</Action>
			</Card>
		</>
	);
};

Item.propTypes = {};

export default Item;
