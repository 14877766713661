import React from 'react';
import { useParams } from 'react-router-dom';

import browserHistory from 'router/browserHistory';
import * as storage from 'components/localStorage';
import { dynamicActivate } from 'components/intl/i18n';

const LanguageSelect = () => {
	const { language } = useParams();

	const handleLanguage = React.useCallback(async () => {
		const localLanguage = await storage.getItem('language');

		if (!localLanguage || localLanguage !== language) {
			await storage.setItem('language', language);
		}

		dynamicActivate(language);

		browserHistory.replace('/');
	}, [language]);

	React.useEffect(() => {
		handleLanguage();
	}, [handleLanguage, language]);

	return language;
};

export default React.memo(LanguageSelect);
