import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Field } from 'react-final-form';

import FinalForm from 'components/form/Form';
import { useNavigation } from 'components/navigation/hooks';
import { MUTATION_PASSWORD_NEW } from 'components/auth/graphql';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import validationSchema from './validationSchema';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Submit = styled(SubmitButton)`
	margin: ${props => props.theme.spacing(3, 0, 2)};
`;

const INITIAL_VALUES = {
	password: '',
	passwordConfirmation: '',
};

const PasswordResetNewForm = () => {
	const { token } = useParams();
	const { navigate } = useNavigation();
	const [passwordResetRequest, { loading }] = useMutation(MUTATION_PASSWORD_NEW);

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await passwordResetRequest({
					variables: {
						input: {
							token,
							password: input.password,
						},
					},
				});
				navigate('/login');
			} catch (error) {
				throw error;
			}
		},
		[navigate, passwordResetRequest, token]
	);

	return (
		<FinalForm
			formName="passwordResetRequest"
			onSubmit={handleOnSubmit}
			initialValues={INITIAL_VALUES}
			validationSchema={validationSchema()}
			validateOnBlur={false}
			subscription={{ values: true, errors: true }}
			render={({ handleSubmit, submitting, pristine }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<Field
							type="password"
							name="password"
							component="input"
							variant="outlined"
							fullWidth
							label={t({
								id: 'ui.password',
								message: 'Senha',
							})}
							autoComplete="new-password"
						>
							{props => <TextField {...props} />}
						</Field>
						<Field
							type="password"
							name="passwordConfirmation"
							component="input"
							variant="outlined"
							fullWidth
							label={t({
								id: 'ui.passwordConfirmation',
								message: 'Confirmar senha',
							})}
							autoComplete="off"
							options={[{ label: '' }]}
						>
							{props => <TextField {...props} />}
						</Field>
						<Submit
							type="submit"
							fullWidth
							size="large"
							variant="contained"
							loadingColor="accent"
							color="primary"
							disabled={submitting || pristine}
							isLoading={submitting || loading}
						>
							<Trans id="ui.saveNewPw">Salvar nova senha</Trans>
						</Submit>
					</Form>
				);
			}}
		/>
	);
};

export default React.memo(PasswordResetNewForm);
