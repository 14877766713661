import React from 'react';

import { useNavigation } from 'components/navigation/hooks';
import { logout } from 'components/auth/actions';

const LogoutPage = () => {
	const { replace } = useNavigation();

	React.useEffect(() => {
		logout()
			.then(() => {
				replace('/');
			})
			.catch(error => console.log('LogoutPage error', error));
	}, [replace]);

	return null;
};

export default React.memo(LogoutPage);
