import gql from 'graphql-tag';

export const LOGIN_FRAGMENT = gql`
	fragment loginFragment on LoginResponse {
		user {
			id
			email
			role
			firstName
			lastName
		}
		token
		tokenExpiration
	}
`;

export const MUTATION_LOGIN = gql`
	mutation Login($input: LoginInput!) {
		login(input: $input) {
			...loginFragment
		}
	}
	${LOGIN_FRAGMENT}
`;

export const MUTATION_PASSWORD_RESET_REQUEST = gql`
	mutation PasswordResetRequest($input: PasswordResetRequestInput!) {
		passwordResetRequest(input: $input) {
			success
		}
	}
`;

export const MUTATION_PASSWORD_NEW = gql`
	mutation PasswordResetRequest($input: PasswordNewInput!) {
		passwordNew(input: $input) {
			success
		}
	}
`;

export const MUTATION_EMAIL_VALIDATION_REQUEST = gql`
	mutation EmailValidationRequest($input: EmailValidationRequestInput!) {
		emailValidationRequest(input: $input) {
			success
		}
	}
`;

export const MUTATION_EMAIL_VALIDATION = gql`
	mutation EmailValidation($input: EmailValidationInput!) {
		emailValidation(input: $input) {
			success
		}
	}
`;

// export const QUERY_REFRESH_TOKEN = gql`
// 	query refreshToken {
// 		accessToken
// 	}
// `;
