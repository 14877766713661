import gql from 'graphql-tag';

export const COUNTRY_FRAGMENT = gql`
	fragment countryFragment on Country {
		id
		name
		code
		translations {
			name
			language
		}
	}
`;

export const QUERY_COUNTRIES = gql`
	query Countries {
		countries {
			...countryFragment
		}
	}
	${COUNTRY_FRAGMENT}
`;
