import React from 'react';
import { Trans } from '@lingui/macro';

import Home from 'pages/public/Home';
import SignUp from 'pages/public/SignUp';
import Login from 'pages/public/Login';
import Logout from 'pages/public/Logout';
import PasswordReset from 'pages/public/PasswordReset';
import PasswordNew from 'pages/public/PasswordNew';
import EmailValidation from 'pages/public/EmailValidation';
import LanguageSelect from 'pages/public/LanguageSelect';

import NotFound from 'pages/public/NotFound';

const routes = [
	{
		path: '/',
		title: <Trans id="route.c.home">EVOXX Autoclaves</Trans>,
		component: Home,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/criar-conta',
		title: <Trans id="route.c.signUp">Criar conta</Trans>,
		component: SignUp,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/confirmar-email/:token',
		title: <Trans id="route.c.emailValidation">Confirmar email</Trans>,
		component: EmailValidation,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/login',
		title: <Trans id="route.c.login">Login</Trans>,
		component: Login,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/logout',
		title: <Trans id="route.c.logout">Logout</Trans>,
		component: Logout,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/redefinir-senha',
		title: <Trans id="route.c.passwordReset">Redefinir senha</Trans>,
		component: PasswordReset,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/nova-senha/:token',
		title: <Trans id="route.c.passwordNew">Definir nova senha</Trans>,
		component: PasswordNew,
		isPrivate: false,
		exact: true,
	},
	{
		path: '/lang/:language',
		title: <Trans id="route.c.home">EVOXX Autoclaves</Trans>,
		component: LanguageSelect,
		isPrivate: false,
		exact: true,
	},
	{
		path: '*',
		title: <Trans id="route.c.notFound">404</Trans>,
		component: NotFound,
		isPrivate: false,
	},
];

export default routes;
