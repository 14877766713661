import React from 'react';
import PropTypes from 'prop-types';

import Select from 'UI/Select';

export const SelectField = ({
	id,
	input: { value, onChange: onChangeValue },
	meta: { touched, error, submitError, submitFailed },
	placeholder,
	helperText,
	disabled,
	onChange,
	className,
	options,
	...rest
}) => {
	const handleChange = React.useCallback(
		e => {
			onChange(e.target.value);
			onChangeValue(e.target.value, value);
		},
		[onChange, onChangeValue, value]
	);

	const theError = error || submitError;
	const theHelperText =
		helperText || ((touched || submitFailed) && theError)
			? theError || helperText
			: null;

	return (
		<Select
			{...rest}
			id={id}
			autoWidth
			value={value}
			placeholder={placeholder}
			disabled={disabled}
			className={className}
			options={options}
			onChange={handleChange}
			error={!!((touched || submitFailed) && theError)}
			helperText={theHelperText}
		/>
	);
};

SelectField.propTypes = {
	id: PropTypes.string,
	input: PropTypes.object.isRequired,
	meta: PropTypes.object.isRequired,
	placeholder: PropTypes.string,
	helperText: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		})
	).isRequired,
};

SelectField.defaultProps = {
	id: undefined,
	input: {},
	meta: {},
	placeholder: undefined,
	helperText: undefined,
	disabled: undefined,
	onChange: () => null,
	className: undefined,
};

export default React.memo(SelectField);
