import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Logo from 'components/layout/LogoEvoxx';
import Symbol from 'components/layout/SymbolEvoxx';
import UserCard from 'components/layout/UserCard';

import { DRAWER_WIDTH } from './config';

const DrawerStyled = styled(Drawer)`
	& div:first-child {
		background-color: ${props => props.theme.palette.secondary.main};
	}
`;

const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 3em 1em 2em;
`;

const IconContainer = styled.div`
	/* display: none; */
`;

const useStyles = makeStyles(theme => ({
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: DRAWER_WIDTH,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(7),
		},
	},
}));

const TheDrawer = ({ children, homePath, onDrawerOpens }) => {
	const classes = useStyles();
	const [open, setOpen] = React.useState(true);

	React.useEffect(() => {
		onDrawerOpens(open);
	}, [onDrawerOpens, open]);

	return (
		<DrawerStyled
			p={10}
			open={open}
			variant="permanent"
			$onDrawerOpens={open}
			classes={{
				paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
			}}
		>
			<Box p={0.4}>
				{open && (
					<IconContainer>
						<IconButton onClick={() => setOpen(false)}>
							<ChevronLeftIcon color="primary" />
						</IconButton>
					</IconContainer>
				)}
				{!open && (
					<IconContainer>
						<IconButton onClick={() => setOpen(true)}>
							<ChevronRightIcon color="primary" />
						</IconButton>
					</IconContainer>
				)}
			</Box>

			<Link to={homePath}>
				<LogoContainer>
					{open ? <Logo size={110} /> : <Symbol size={27} />}
				</LogoContainer>
			</Link>

			{children}

			<UserCard isOpen={open} />
		</DrawerStyled>
	);
};

export default React.memo(TheDrawer);
