import React from 'react';
import { t } from '@lingui/macro';
import PeopleIcon from '@material-ui/icons/People';
import PersonIcon from '@material-ui/icons/Person';
import CategoryIcon from '@material-ui/icons/Category';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LabelIcon from '@material-ui/icons/Label';
import BuildIcon from '@material-ui/icons/Build';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import TuneIcon from '@material-ui/icons/Tune';
import HistoryIcon from '@material-ui/icons/History';
import DescriptionIcon from '@material-ui/icons/Description';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';

import MenuList from 'components/layout/MenuList';

export const MainMenu = ({ drawerIsOpen }) => {
	const MENU_PRIMARY = [
		{
			to: '/admin/dashboard',
			label: t({
				id: 'ui.dashboard',
				message: 'Dashboard',
			}),
			icon: <DashboardIcon />,
		},
		{
			to: '/admin/autoclaves',
			label: t({
				id: 'ui.autoclaves',
				message: 'Autoclaves',
			}),
			icon: <CategoryIcon />,
			showDivider: true,
			items: [
				{
					to: '/admin/autoclaves/importar',
					label: t({
						id: 'ui.autoclavesImport',
						message: 'Importar Autoclaves',
					}),
					icon: <CloudUploadIcon />,
				},
			],
		},
		{
			to: '/admin/alerta-ciclos',
			label: t({
				id: 'ui.cyclesAlert',
				message: 'Alerta Ciclos',
			}),
			icon: <ErrorOutlineIcon />,
			showDivider: true,
			items: [
				{
					to: '/admin/alerta-ciclos/300',
					label: t({
						id: 'ui.alert300',
						message: 'Alerta 300',
					}),
					icon: <ErrorIcon />,
				},
				{
					to: '/admin/alerta-ciclos/600',
					label: t({
						id: 'ui.alert600',
						message: 'Alerta 600',
					}),
					icon: <ErrorIcon />,
				},
			],
		},
		{
			to: '/admin/manutencao',
			label: t({
				id: 'ui.maintenance',
				message: 'Manutenção',
			}),
			icon: <TuneIcon />,
			showDivider: true,
			items: [
				{
					to: '/admin/historico-manutencao',
					label: t({
						id: 'ui.maintenanceHistory',
						message: 'Histórico de Manutenção',
					}),
					icon: <HistoryIcon />,
					showDivider: true,
				},
			],
		},
		{
			to: '/admin/relatorios',
			label: t({
				id: 'ui.reports',
				message: 'Relatórios',
			}),
			icon: <DescriptionIcon />,
		},
		{
			to: '/admin/autoclaves/modelos',
			label: t({
				id: 'ui.models',
				message: 'Modelos',
			}),
			icon: <LabelIcon />,
		},
		{
			to: '/admin/usuarios',
			label: t({
				id: 'ui.users',
				message: 'Usuários',
			}),
			icon: <PeopleIcon />,
			items: [
				{
					to: '/admin/usuarios/clientes',
					label: t({
						id: 'ui.customers',
						message: 'Clientes',
					}),
					icon: <PersonIcon />,
				},
				{
					to: '/admin/usuarios/tecnicos',
					label: t({
						id: 'ui.techinicians',
						message: 'Técnicos',
					}),
					icon: <BuildIcon />,
				},
				{
					to: '/admin/usuarios/administradores',
					label: t({
						id: 'ui.admins',
						message: 'Administradores',
					}),
					icon: <SupervisorAccountIcon />,
				},
			],
		},
	];

	const MENU_LOGOUT = [
		{
			to: '/logout',
			label: t({
				id: 'ui.logout',
				message: 'Sair',
			}),
			icon: <ArrowBackIcon />,
		},
	];

	return (
		<>
			<MenuList drawerIsOpen={drawerIsOpen} items={MENU_PRIMARY} showDivider />
			<MenuList drawerIsOpen={drawerIsOpen} items={MENU_LOGOUT} />
		</>
	);
};

export default React.memo(MainMenu);
