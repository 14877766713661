import { object, string, ref } from 'yup';
import { t } from '@lingui/macro';

/*

/^
  (?=.*\d)          // should contain at least one digit
  (?=.*[a-z])       // should contain at least one lower case
  (?=.*[A-Z])       // should contain at least one upper case
  [a-zA-Z0-9]{8,}   // should contain at least 8 from the mentioned characters
$/

 */

const validationSchema = () => {
	const minVal = 8;
	const maxVal = 32;
	const required = t({
		id: 'validation.required',
		message: 'Requirido',
	});
	const aboutFormat = t({
		id: 'validation.passwordFormat',
		message:
			'Mínimo de 8 caracteres, ter número, letras maiúsculas, minúsculas e um caracter especial',
	});
	const minChars = t({
		id: 'validation.minPwChars',
		message: 'Mínimo de 8 caracteres',
	});
	const maxChars = t({
		id: 'validation.maxPwChars',
		message: 'Máximo de 32 caracteres',
	});
	const passwordMustMatch = t({
		id: 'validation.passwordMustMatch',
		message: 'As senhas devem corresponder',
	});

	const regex = /^(?=.*[0-9])(?=.*[\\?!@#$%^&*\\.])[a-zA-Z0-9\\?!@#$%^&*\\.]{8,32}$/;

	return object().shape({
		password: string()
			.min(minVal, minChars)
			.max(maxVal, maxChars)
			.matches(regex, {
				message: aboutFormat,
			})
			.oneOf([ref('passwordConfirmation'), null], passwordMustMatch)
			.required(required),
		passwordConfirmation: string().oneOf([ref('password'), null], passwordMustMatch),
	});
};

export default validationSchema;
