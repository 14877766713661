import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

// import Breadcrumbs from 'components/layout/Breadcrumbs';

const Page = ({ children, title }) => (
	<Box pt={6}>
		<Container maxWidth="md" component="main">
			<Typography variant="h1">{title}</Typography>
			{/* <Breadcrumbs title={title} /> */}
			<Box pt={6} display="flex" alignItems="center" flexDirection="column">
				{children}
			</Box>
		</Container>
	</Box>
);

export default React.memo(Page);
