import React from 'react';
import { Trans } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import UpdateUserForm from 'components/users/UpdateUserForm';

const UpdateUserPage = props => {
	const { id } = useParams();

	return (
		<>
			<Container disableGutters>
				<Typography>
					<Trans id="users.useForm.edit">
						Atualizar o formulário para editar o usuário.
					</Trans>
				</Typography>
			</Container>
			<Container maxWidth="sm" disableGutters>
				<Box pt={4} display="flex" flexDirection="column">
					<UpdateUserForm userId={id} />
				</Box>
			</Container>
		</>
	);
};

export default React.memo(UpdateUserPage);
