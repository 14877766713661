import React from 'react';
import { t } from '@lingui/macro';
import { ValidationError } from 'yup';
import { Form } from 'react-final-form';
import { FORM_ERROR, setIn } from 'final-form';
import deepmerge from 'deepmerge';

import processError from 'utils/errors';
import useStatus from 'components/status/hooks';

import { propTypes, defaultProps } from './propTypes';

const convertDotKey = (errObj, baseObject = {}) => {
	const result = {};
	Object.entries(errObj).forEach(([key, value]) => {
		key.split('.').reduce(
			(r, e, i, arr) => (r[e] = r[e] || (arr[i + 1] ? {} : value)),
			result
		);
	});
	return deepmerge(baseObject, result);
};

const convertGraphQLErrors = err => {
	const message = 'Error';
	try {
		let validationErrors = {};

		if (err && Array.isArray(err)) {
			for (let x = 0; x < err.length; x++) {
				const error = err[x];

				if (error.state) {
					// eslint-disable-next-line no-loop-func
					Object.keys(error.state).forEach(field => {
						validationErrors[field] = error.state[field];
					});
				} else if (error.validationErrors) {
					validationErrors = convertDotKey(error.validationErrors);
					break;
				}
				validationErrors.error = error.message || message; // eslint-disable-line
			}
		}
		return validationErrors;
	} catch (err1) {
		return {
			error: message,
		};
	}
};

const TheForm = ({
	id,
	formName,
	onSubmit,
	transformValues,
	excludeOnSubmit,
	validationError,

	keepDirtyOnReinitialize,
	validateOnBlur,
	initialValues,
	mutators,
	subscription,
	decorators,
	resetOnSuccess,
	debug,

	onSubmitSuccess,
	showSuccess,

	validationSchema,
	showValidate,
	validate,

	render,
	children,
	...rest
}) => {
	const { addSuccess, addError } = useStatus();

	const handleSubmitSuccess = React.useCallback(
		(form, res) => {
			const newSaveResult = res;

			if (showSuccess) {
				addSuccess(
					t({
						id: 'ui.success',
						message: 'Sucesso',
					})
				);
			}

			if (resetOnSuccess) {
				setTimeout(() => form.restart(), 1000);
			}

			onSubmitSuccess(newSaveResult);
		},
		[addSuccess, onSubmitSuccess, resetOnSuccess, showSuccess]
	);

	const handleExcludeOnSubmit = React.useCallback(
		values => {
			let dataClean = { ...values };
			if (excludeOnSubmit) {
				for (let i = 0; i < excludeOnSubmit.length; i++) {
					const deleteItem = excludeOnSubmit[i];
					delete dataClean[deleteItem];
				}
			}
			return dataClean;
		},
		[excludeOnSubmit]
	);

	const handleSubmit = React.useCallback(
		async (values, form) => {
			try {
				const cleanData = handleExcludeOnSubmit(values);
				const data = transformValues(cleanData);

				const res = await onSubmit(data, id);

				let errors = res?.extensions?.validationErrors;
				if (!errors && res?.errors) {
					errors = res.errors[0]?.validationErrors;
					errors = !errors && res.errors[0]?.message;
				}
				if (errors) {
					const err = new Error();
					err.graphQLErrors = errors;
					throw err;
				}

				setTimeout(() => handleSubmitSuccess(form, res), 300);
			} catch (err) {
				console.log('Form err', err);

				let message = t({
					id: 'ui.somethingWentWrong',
					message: 'Algo deu errado...',
				});

				if (err.graphQLErrors) {
					if (typeof err.graphQLErrors === 'string') {
						addError(err.graphQLErrors);

						return {
							[FORM_ERROR]: err.graphQLErrors,
						};
					}

					const errors = convertGraphQLErrors(err.graphQLErrors);

					if (Object.keys(errors).length > 0) {
						if (!validationError || !Object.keys(validationError).length) {
							message = processError(err);
							addError(message);
						}

						return {
							[FORM_ERROR]: errors.error
								? errors.error
								: t({
										id: 'ui.pleaseCheckFormErrors',
										message:
											'Por favor, verifique os erros do formulário',
								  }),
							...errors,
						};
					}
				}

				addError(message);

				return {
					[FORM_ERROR]: message,
				};
			}
		},
		[
			handleExcludeOnSubmit,
			transformValues,
			onSubmit,
			id,
			handleSubmitSuccess,
			addError,
			validationError,
		]
	);

	const handleValidate = React.useCallback(
		async values => {
			try {
				const vals = transformValues(values);

				if (!vals) {
					throw new Error('transformValues returned undefined, did you forget to return "data"')
				}


				if (validationSchema) {
					try {
						await validationSchema.validate(vals, {
							context: vals,
							abortEarly: false,
						});
					} catch (err) {
						if (!(err instanceof ValidationError)) {
							return {
								[FORM_ERROR]: 'Something went wrong',
							};
						}

						if (showValidate && !validationError) {
							// addError('Check the fields');
						}

						let errors = {};

						if (err && err.inner) {
							if (err.inner.length === 0) {
								setIn(errors, err.path, err.message);
							} else {
								for (const error of err.inner) {
									if (!errors[error.path]) {
										errors = setIn(errors, error.path, error.message);
									}
								}
							}
						}

						return errors;
					}
				}
				return validate ? validate(vals) : {};
			} catch (err) {
				console.warn(err);
				throw err;
			}
		},
		[showValidate, transformValues, validate, validationError, validationSchema]
	);

	return (
		<Form
			onSubmit={handleSubmit}
			validate={handleValidate}
			validateOnBlur={validateOnBlur}
			initialValues={initialValues}
			keepDirtyOnReinitialize={keepDirtyOnReinitialize}
			mutators={mutators}
			subscription={{
				...subscription,
				submitting: true,
				validating: true,
				valid: true,
				submitFailed: true,
				submitSucceeded: true,
			}}
			decorators={decorators}
			debug={debug}
			render={render}
		/>
	);
};

TheForm.propTypes = propTypes;

TheForm.defaultProps = defaultProps;

export default React.memo(TheForm);
