import React from 'react';
import { Route } from 'react-router-dom';
import { useLingui } from '@lingui/react';

import * as storage from 'components/localStorage';
import { dynamicActivate } from 'components/intl/i18n';

const PublicRoute = ({ children, ...rest }) => {
	const { i18n } = useLingui();

	const checkLanguage = React.useCallback(async () => {
		try {
			const localLanguage = await storage.getItem('language');

			if (i18n.locale !== localLanguage) {
				dynamicActivate(localLanguage);
			}
		} catch (err) {
			console.log('checkLanguage err', err);
		}
	}, [i18n.locale]);

	React.useEffect(() => {
		checkLanguage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <Route {...rest}>{children}</Route>;
};

export default React.memo(PublicRoute);
