import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ImportAutoclaveForm from 'components/autoclaves/ImportAutoclaveForm';

const ImportAutoclavesPage = props => {
	return (
		<>
			<Container disableGutters>
				<Typography align="left">
					<Trans id="autoclaves.importInfo">
						Selecione o modelo e o arquivo .txt com os números de série para
						importar e criar Autoclaves.
					</Trans>
				</Typography>
			</Container>
			<Container maxWidth="sm" disableGutters>
				<Box pt={4}>
					<ImportAutoclaveForm />
				</Box>
			</Container>
		</>
	);
};

export default React.memo(ImportAutoclavesPage);
