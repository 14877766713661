import React from 'react';
import { Trans } from '@lingui/macro';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import EditIcon from '@material-ui/icons/Edit';

import { ADMIN, TECHNICIAN } from 'components/users/config';
import { QUERY_ME } from 'components/users/graphql';
import { getInitials } from 'utils/text';

const Card = styled.div`
	position: relative;
	display: flex;
	height: 88px;
	padding: 0 1.2rem;
	border: solid 1px ${props => props.theme.palette.primary.main};
	border-radius: ${props => props.theme.shape.borderRadius}px;
`;

const Name = styled(Link)`
	width: 100%;
	color: ${props => props.theme.palette.primary.main};
	padding-bottom: 0.1rem;
	text-decoration: none;
	text-overflow: ellipsis;
	/* Needed to make it work */
	overflow: hidden;
	white-space: nowrap;
`;

const Initials = styled(Link)`
	align-items: center;
	justify-content: center;
	display: flex;
	border: solid 1px ${props => props.theme.palette.primary.main};
	border-radius: 100%;
	width: 46px;
	height: 46px;
	color: #fff;
	text-decoration: none;
	transition: background-color 400ms linear, border 400ms linear;

	${props =>
		props.$small &&
		css`
			width: 32px;
			height: 32px;
			font-weight: 700;
			border: 0;
			font-size: 0.65rem;
			border: solid 1px ${props => props.theme.palette.primary.main};
		`}

	&:hover {
		border: solid 1px transparent;
		background-color: ${props => props.theme.palette.primary.main};
	}
`;

const MyAccount = styled(Link)`
	color: #fff;
	text-decoration: none;
`;

const Edit = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;

	a {
		color: #fff;
		font-size: 1rem;
	}
`;

const UserCard = ({ isOpen }) => {
	const { data } = useQuery(QUERY_ME);

	if (!data) {
		return (
			<Box mt="auto" p={1.4} pb={2}>
				<Card />
			</Box>
		);
	}

	const name = `${data.me.firstName} ${data.me.lastName}`;
	const initials = getInitials(name);
	const roleKey = data?.me?.role;
	const goTo =
		roleKey === ADMIN
			? '/admin/minha-conta'
			: roleKey === TECHNICIAN
			? '/tecnico/minha-conta'
			: '/cliente/minha-conta';

	if (!isOpen) {
		return (
			<Box mt="auto" p={1.4} pb={2}>
				<Initials to={goTo} $small>
					{initials}
				</Initials>
			</Box>
		);
	}

	return (
		<Box mt="auto" p={2} pb={2}>
			<Card>
				<Box display="flex" alignItems="center">
					<Initials to={goTo}>{initials}</Initials>
				</Box>
				<Box
					pl={2.2}
					display="flex"
					alignItems="flex-start"
					justifyContent="center"
					flexDirection="column"
				>
					<Name to={goTo}>{name}</Name>
					<MyAccount to={goTo}>
						<Trans id="ui.myAccount">Minha Conta</Trans>
					</MyAccount>
				</Box>
				<Edit>
					<Link to={goTo}>
						<EditIcon fontSize="inherit" />
					</Link>
				</Edit>
			</Card>
		</Box>
	);
};

export default React.memo(UserCard);
