import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import MaintenanceTable from 'components/maintenance/MaintenanceTable';

const AutoclavePage = () => (
	<Container disableGutters>
		<Grid container component="main">
			<Grid item xs={12} sm={8} md={9}>
				<Typography paragraph>
					<Trans id="maintenance.yourHistory">
						Histórico de suas manutenções em Autoclaves EVOXX.
					</Trans>
				</Typography>
			</Grid>
		</Grid>

		<Box pt={4}>
			<MaintenanceTable />
		</Box>
	</Container>
);

export default React.memo(AutoclavePage);
