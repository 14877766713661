import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import { useNavigation } from 'components/navigation/hooks';
import MyAutoclavesList from 'components/autoclaves/MyAutoclavesList';

import Button from 'UI/Button';

const MyAutoclavesAddPage = () => {
	const { navigate } = useNavigation();

	return (
		<>
			<Container disableGutters>
				<Grid container component="main">
					<Grid item xs={12} sm={8} md={9}>
						<Typography paragraph>
							<Trans id="autoclave.addInfo">
								Adicione suas Autoclaves EVOXX usando o número de série
								para Ativar, Desbloquear e gerar Relatórios de manutenção.
							</Trans>
						</Typography>
					</Grid>

					<Grid item xs={12} sm={4} md={3}>
						<Box align="right">
							<Button
								type="button"
								variant="outlined"
								color="primary"
								onClick={() => navigate('/cliente/adicionar-autoclave')}
							>
								<Trans id="ui.u.add">ADICIONAR</Trans>
							</Button>
						</Box>
					</Grid>
				</Grid>

				<Box pt={4}>
					<MyAutoclavesList />
				</Box>
			</Container>
		</>
	);
};

export default React.memo(MyAutoclavesAddPage);
