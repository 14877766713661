import { object, string, mixed } from 'yup';
import { t } from '@lingui/macro';

const validationSchema = () => {
	const required = t({
		id: 'validation.required',
		message: 'Requirido',
	});
	const supportedFormats = ['text/plain'];
	const unsupportedFormat = t({
		id: 'validation.formatNotSupported',
		message: 'Formato não suportado',
	});
	const fileWeightMsg = t({
		id: 'validation.maxFileSize500',
		message: 'Tamanho máximo permitido é 500kb',
	});
	const fileWeight = 500000;

	return object().shape({
		model: string().nullable().required(required),
		csvFile: mixed()
			.nullable()
			.test('fileSize', fileWeightMsg, value => {
				return value.size <= fileWeight;
			})
			.test('fileType', unsupportedFormat, value => {
				return (
					supportedFormats.includes(value.type) || value.name.includes('.txt')
				);
			})
			.required(required),
	});
};

export default validationSchema;
