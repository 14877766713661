import gql from 'graphql-tag';

export const REPORT_STAGES_FRAGMENT = gql`
	fragment reportStagesFragment on ReportStages {
		id
		stage
		dateTimeStart
		dateTimeEnd
		temperatureStart
		temperatureEnd
		pressureStart
		pressureEnd
		error
	}
`;

export const REPORT_FRAGMENT = gql`
	fragment reportFragment on Report {
		id
		serialNumber
		autoclave {
			id
			model {
				name
			}
		}
		lastMaintenance
		totalCycles
		maintenance600Cycles
		csvCreatedAt
		stages {
			...reportStagesFragment
		}
	}
	${REPORT_STAGES_FRAGMENT}
`;

export const REPORTS_FRAGMENT = gql`
	fragment reportsFragment on ReportListResult {
		edges {
			...reportFragment
		}
		totalCount
		pageInfo {
			hasNextPage
		}
	}
	${REPORT_FRAGMENT}
`;

export const QUERY_REPORTS = gql`
	query Reports(
		$limit: Int = 5
		$page: Int = 0
		$autoclaveId: ID
		$listType: ListType
		$searchText: String
		$startDate: Date
		$endDate: Date
	) {
		reports(
			limit: $limit
			page: $page
			autoclaveId: $autoclaveId
			listType: $listType
			searchText: $searchText
			startDate: $startDate
			endDate: $endDate
		) {
			...reportsFragment
		}
	}
	${REPORTS_FRAGMENT}
`;

export const QUERY_REPORT = gql`
	query Report($id: ID!) {
		report(id: $id) {
			id
			path
			serialNumber
		}
	}
`;

export const MUTATION_IMPORT_CYCLES = gql`
	mutation ImportReport($input: ReportImportInput!) {
		importReport(input: $input) {
			id
		}
	}
`;

export const MUTATION_IMPORT_MULTIPLE_REPORTS = gql`
	mutation ImportReports($input: ReportImportInput!) {
		importReports(input: $input) {
			total
			errors
			success
			alreadyExist
		}
	}
`;

export const MUTATION_REMOVE_REPORT = gql`
	mutation RemoveReport($id: ID!) {
		removeReport(id: $id) {
			id
		}
	}
`;
