import gql from 'graphql-tag';

export const CITY_FRAGMENT = gql`
	fragment stateFragment on City {
		id
		name
	}
`;

export const QUERY_CITIES = gql`
	query Cities($stateId: ID!) {
		cities(stateId: $stateId) {
			...stateFragment
		}
	}
	${CITY_FRAGMENT}
`;
