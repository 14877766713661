const AVAILABLE_LANGUAGES = ['en', 'pt', 'es'];
const DEFAULT_LANGUAGE = 'en';

export const getLanguage = () => {
	if (typeof window === 'undefined') {
		return DEFAULT_LANGUAGE;
	}
	const nav = window?.navigator;
	return nav.languages ? nav.languages[0] : nav.language || nav.userLanguage;
};

export const getAvailableLocale = language => {
	const theLanguage = language || getLanguage();

	const languageCode = theLanguage.includes('-')
		? theLanguage.split('-')[0]
		: theLanguage;

	return AVAILABLE_LANGUAGES.includes(languageCode) ? languageCode : DEFAULT_LANGUAGE;
};
