import PropTypes from 'prop-types';

export const propTypes = {
	excludeOnSubmit: PropTypes.array,
	onSubmit: PropTypes.func,
	validationSchema: PropTypes.func,
	initialValues: PropTypes.object,
	loading: PropTypes.bool,
	isUpdate: PropTypes.bool,
	ownerLabel: PropTypes.string,
};

export const defaultProps = {
	excludeOnSubmit: [],
	onSubmit: () => null,
	validationSchema: () => null,
	initialValues: {},
	loading: false,
	isUpdate: false,
	ownerLabel: '',
};
