import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { QUERY_STATUS, MUTATION_RESET_STATUS } from 'components/status/graphql';

import Snackbar from 'UI/Snackbar';

const AUTOHIDE = 3000;

const GeneralSnackbar = () => {
	const statusData = useQuery(QUERY_STATUS);
	const [clearErrors] = useMutation(MUTATION_RESET_STATUS);

	const currentStatus = statusData.data?.local__status;

	const handleClear = React.useCallback(() => {
		clearErrors();
	}, [clearErrors]);

	let type = currentStatus?.type || 'info';
	let message = currentStatus?.message;

	return (
		<Snackbar
			// icon={icon}
			open={!!message}
			onClose={handleClear}
			autoHideDuration={AUTOHIDE}
			message={message}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			type={type}
		></Snackbar>
	);
};

export default React.memo(GeneralSnackbar);
