import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormHelperText from '@material-ui/core/FormHelperText';

import Loading from 'UI/Loading';
import Button from 'UI/Button';

const LabelStyled = styled.label`
	display: inline-flex;
	flex-direction: column;
	width: auto;
	position: relative;
`;

const InputFileStyled = styled.input`
	display: flex;
	top: 2px;
	height: 32px;
	left: 5%;
	position: absolute;
	width: 90%;
	z-index: -1;
`;

export const TextInputField = props => {
	const {
		input,
		meta: { touched, error, submitError, submitFailed },
		required,
		helperText,
		disabled,
		displayError,
		onChange,
		className,
		loadingColor,
		label,
		accept,
		maxFiles,
		externalError,
	} = props;

	const [localError, setLocalError] = React.useState(null);

	const handleChange = React.useCallback(
		async e => {
			try {
				const {
					target: {
						files,
						validity,
						// files: [file],
					},
				} = e;

				if (files.length > maxFiles) {
					const errorMessage = `${t({
						id: 'ui.maxFilesExceeded',
						message: 'Quantidade de arquivos excedido:',
					})} ${maxFiles}`;

					setLocalError(errorMessage);
				} else {
					if (validity) {
						input.onChange(files);
						onChange(files);
					}
				}
			} catch (error) {
				console.log('handleChange error', error);
			}
		},
		[input, onChange, maxFiles]
	);

	const theError = error || submitError;
	const { onChange: ignore, value, ...restInput } = input;

	const isLoading = false;

	const fileName =
		value.length === 1
			? value[0].name
			: value.length > 1
			? `${value.length} ${t({
					id: 'ui.files',
					message: 'arquivos',
			  })}`
			: '';

	return (
		<LabelStyled htmlFor={input.name}>
			<InputFileStyled
				{...restInput}
				type="file"
				id={input.name}
				name={input.name}
				onChange={handleChange}
				disabled={disabled}
				accept={accept}
				required={required}
				multiple={input.multiple}
			/>

			<Button
				type="button"
				color="secondary"
				variant="contained"
				component="span"
				disabled={disabled}
				startIcon={
					isLoading && (
						<Loading color={loadingColor} fullScreen={false} size={20} />
					)
				}
				endIcon={<CloudUploadIcon />}
			>
				{label}
			</Button>

			{(((touched || submitFailed) && theError) ||
				helperText ||
				externalError ||
				fileName ||
				localError) && (
				<FormHelperText
					error={
						!!((touched || submitFailed || displayError) && theError) ||
						!!externalError
					}
				>
					{theError || externalError || localError || fileName || helperText}
				</FormHelperText>
			)}
		</LabelStyled>
	);
};

TextInputField.propTypes = {
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	label: PropTypes.string,
	type: PropTypes.string,
	onChange: PropTypes.func,
	loadingColor: PropTypes.string,
	accept: PropTypes.string,
	externalError: PropTypes.string,
};

TextInputField.defaultProps = {
	disabled: false,
	required: false,
	label: 'Selecionar arquivo',
	type: 'text',
	onChange: () => undefined,
	loadingColor: 'accent',
	accept: undefined,
	externalError: undefined,
};

export default React.memo(TextInputField);
