import gql from 'graphql-tag';

export const STATE_FRAGMENT = gql`
	fragment stateFragment on State {
		id
		name
		code
	}
`;

export const QUERY_STATES = gql`
	query States {
		states {
			...stateFragment
		}
	}
	${STATE_FRAGMENT}
`;
