import { i18n } from '@lingui/core';
import { pt, es, en } from 'make-plural/plurals';

import { getAvailableLocale } from 'utils/intl';

export const LOCALES = {
	pt: 'Português',
	es: 'Español',
	en: 'English',
};

export const defaultLocale = getAvailableLocale();

i18n.loadLocaleData({
	pt: { plurals: pt },
	es: { plurals: es },
	en: { plurals: en },
});

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export async function dynamicActivate(locale = defaultLocale) {
	const theLocale = getAvailableLocale(locale);

	const { messages } = await import(`../../locales/${theLocale}/messages`);
	i18n.load(theLocale, messages);
	i18n.activate(theLocale);
}
