import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Logo from 'components/layout/LogoEvoxx';
import PasswordResetRequestForm from 'components/auth/forms/PasswordResetRequestForm';

const PasswordResetPage = () => (
	<Container maxWidth="xs">
		<Box
			py={6}
			display="flex"
			flexGrow={1}
			flexDirection="column"
			alignItems="center"
		>
			<Logo size={120} textColor="#000" />
		</Box>

		<Box m={1} display="flex" flexDirection="column">
			<Typography variant="h3" paragraph>
				<Trans id="auth.resetPassword">Redefinir Senha</Trans>
			</Typography>

			<Box pt={1} pb={4}>
				<Typography>
					<Trans id="auth.infomEmailReceiveLink">
						Informe seu email abaixo para enviarmos um link de redefinição de
						senha.
					</Trans>
				</Typography>
			</Box>

			<PasswordResetRequestForm />
		</Box>
	</Container>
);

export default React.memo(PasswordResetPage);
