import React from 'react';
import { Switch } from 'react-router-dom';

import PublicRoute from 'router/PublicRoute';
import PrivateRoute from 'router/PrivateRoute';
import Drawer from 'components/layout/Drawer';
import Content from 'components/layout/Content';
import Container from 'components/layout/Container';
import MenuTechnician from 'components/layout/MenuTechnician';
import HtmlHeader from 'components/layout/HtmlHeader';
import Page from 'components/layout/Page';
import Footer from 'components/layout/Footer';

import routes from './routes';

const TechnicianRoutes = () => {
	const [drawerIsOpen, setDrawerIsOpen] = React.useState(true);

	const homePath = routes.find(item => item.isHome)?.path;

	return (
		<Container>
			<Drawer homePath={homePath} onDrawerOpens={status => setDrawerIsOpen(status)}>
				<MenuTechnician drawerIsOpen={drawerIsOpen} />
			</Drawer>

			<Content drawerIsOpen={drawerIsOpen}>
				<Switch>
					{routes.map(route => {
						if (!route) {
							return null;
						}

						const Component = route.component;

						if (route.isPrivate) {
							return (
								<PrivateRoute
									key={route.path}
									path={route.path}
									exact={route.exact}
									allowRole={route.allowRole}
								>
									<Page {...route}>
										<HtmlHeader {...route} />
										<Component {...route} />
									</Page>
								</PrivateRoute>
							);
						}

						return (
							<PublicRoute
								key={route.path}
								path={route.path}
								exact={route.exact}
								component={route.component}
							>
								<Page {...route}>
									<HtmlHeader {...route} />
									<Component {...route} />
								</Page>
							</PublicRoute>
						);
					})}
				</Switch>

				<Footer />
			</Content>
		</Container>
	);
};

export default TechnicianRoutes;
