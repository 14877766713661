import React from 'react';
import styled from 'styled-components/macro';
// import Container from '@material-ui/core/Container';

// const Gradient = styled.div`
// 	display: flex;
// 	background: linear-gradient(90deg, #09307A 20%, #F4FAFF 20%);
// `;

const ContainerStyled = styled.div`
	display: flex;
`;

const TheContainer = ({ children, ...rest }) => (
	<ContainerStyled {...rest}>{children}</ContainerStyled>
);

export default React.memo(TheContainer);
