import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import UsersTable from 'components/users/UsersTable';

const UsersPage = () => (
	<Container disableGutters>
		<Typography>
			<Trans id="users.allAdded">Todos os usuários cadastrados.</Trans>
		</Typography>
		<Box pt={4}>
			<UsersTable />
		</Box>
	</Container>
);

export default React.memo(UsersPage);
