export default {
	primary: {
		main: '#28AAB9',
		contrastText: '#FFFFFF',
	},
	secondary: {
		main: '#09307A',
		light: '#96A5C3',
		contrastText: '#FFFFFF',
	},
	error: {
		main: '#980123',
		contrastText: '#FFFFFF',
	},
	background: {
		default: '#F4FAFF',
	},
};
