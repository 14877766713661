import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CreateModelForm from 'components/models/CreateModelForm';

const CreateModelPage = props => {
	return (
		<>
			<Container disableGutters>
				<Typography>
					<Trans id="models.useForm.add">
						Utilize o formulário abaixo para adicionar um modelo de Autoclave.
					</Trans>
				</Typography>
			</Container>
			<Container maxWidth="sm" disableGutters>
				<Box pt={4} display="flex" flexDirection="column">
					<CreateModelForm />
				</Box>
			</Container>
		</>
	);
};

export default React.memo(CreateModelPage);
