import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@lingui/macro';
import Styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';

import TextField from 'components/form/TextField';

const Container = Styled.div`
	position: relative;
`;

const ButtonClear = Styled.button`
	position: absolute;
	display: flex;
    align-items: center;
    justify-content: center;
	background: none;
	border: 0;
	padding: 0;
	outline: none;
	right: 0;
	top: 16px;
	height: 56px;
	width: 56px;
	cursor: pointer;
	z-index: 20;
`;

const FieldButton = Styled.div`
	position: absolute;
	top: 16px;
	width: 100%;
	height: 56px;
	z-index: 10;
	cursor: pointer;
`;

const Options = Styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	background: #FFF;
	padding: 0 0 1rem;
	z-index: 10;
	width: 100%;
	max-height: 200px;
	border: solid 1px #E0E0E0;
	border-radius: 4px;
	margin-top: -9px;
	overflow: auto;

	div:last-child{
		border-bottom: 0;
	}
`;

const Filter = Styled.div`
	width: 100%;
	padding: 1rem 1rem;
	background:  #F5F5F5;

	input {
		width: 100%;
		padding: 0.5rem 1rem;
		border: solid 1px #E0E0E0;
		border-radius: 4px;
		font: inherit;
		outline: none;

	}
`;

const Option = Styled.div`
	width: 100%;
	border: 0;
	border-bottom: solid 1px #E0E0E0;
	padding: 0.5rem 1rem;
	background: none;
	text-align: left;
	cursor: pointer;

	&:hover {
		background: #F5F5F5;
	}
`;

export const AutocompleteField = ({
	options,
	valueKey,
	formatLabel,
	input,
	onChangeFilter,
	ownerLabel,
	loading,
	...rest
}) => {
	const [label, setLabel] = React.useState(ownerLabel);
	const [showOptions, setShowOptions] = React.useState(false);

	const handleContainerOnBlur = e => {
		if (!document.getElementById(`field__${input.name}`).contains(e?.target)) {
			setShowOptions(false);
		}
	};

	React.useEffect(() => {
		window.addEventListener('click', handleContainerOnBlur, true);

		return () => {
			window.removeEventListener('click', handleContainerOnBlur, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnClick = React.useCallback(
		async option => {
			setLabel(formatLabel(option));
			input.onChange(option?.[valueKey]);
			setShowOptions(false);
		},
		[formatLabel, input, valueKey]
	);

	const handleOnFocus = React.useCallback(() => {
		if (!showOptions) {
			onChangeFilter('');
			setShowOptions(true);
		} else {
			setShowOptions(false);
		}
	}, [onChangeFilter, showOptions]);

	const handleOnChange = React.useCallback(
		e => {
			const value = e.target.value;
			onChangeFilter(value);
		},
		[onChangeFilter]
	);

	const handleClearOnClick = React.useCallback(() => {
		input.onChange('');
		setLabel('');
	}, [input]);

	return (
		<Container id={`field__${input.name}`}>
			{input.value && !loading && (
				<ButtonClear type="button" onClick={handleClearOnClick}>
					<CloseIcon />
				</ButtonClear>
			)}

			<FieldButton onClick={handleOnFocus} />

			<TextField
				{...rest}
				input={{
					name: `label__${input.name}`,
					value: label,
				}}
			/>

			<input {...input} type="hidden" />

			{showOptions && (
				<Options>
					<Filter>
						<input
							name="filter"
							autoComplete="off"
							placeholder={t({
								id: 'ui.typeToSearch',
								message: 'Digite para buscar...',
							})}
							onChange={handleOnChange}
						/>
					</Filter>
					{options.map((option, idx) => (
						<Option key={idx} onClick={() => handleOnClick(option)}>
							{formatLabel(option)}
						</Option>
					))}
				</Options>
			)}
		</Container>
	);
};

AutocompleteField.propTypes = {
	options: PropTypes.array,
};

AutocompleteField.defaultProps = {
	options: [],
};

export default React.memo(AutocompleteField);
