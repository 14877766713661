import React from 'react';
import { Helmet } from 'react-helmet';

const HtmlHeader = ({ title, description }) => (
	<Helmet>
		<title>EVOXX</title>
		<meta name="description" content={description} />
	</Helmet>
);

export default React.memo(HtmlHeader);
