import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ReportsList from 'components/reports/ReportsList';
import { useNavigation } from 'components/navigation/hooks';

import Button from 'UI/Button';

const ReportsListPage = () => {
	const { navigate } = useNavigation();

	return (
		<Container disableGutters>
			<Grid container component="main">
				<Grid item xs={12} sm={8} md={9}>
					<Typography paragraph>
						<Trans id="upload.generateRecord">
							Faça o upload do arquivo para gerar o registro de ciclo de sua
							autoclave.
						</Trans>
					</Typography>
				</Grid>

				<Grid item xs={12} sm={4} md={3}>
					<Box align="right">
						<Button
							type="button"
							variant="outlined"
							color="primary"
							onClick={() => navigate('/cliente/importar-ciclos')}
						>
							<Trans id="ui.u.upload">UPLOAD</Trans>
						</Button>
					</Box>
				</Grid>
			</Grid>

			<Box pt={4}>
				<ReportsList />
			</Box>
		</Container>
	);
};

export default React.memo(ReportsListPage);
