import React from 'react';
import { Trans } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import UpdateAutoclaveForm from 'components/autoclaves/UpdateAutoclaveForm';

const UpdateAutoclavePage = () => {
	const { id } = useParams();

	return (
		<>
			<Container disableGutters>
				<Typography paragraph>
					<Trans id="autoclaves.formEdit.info">
						Atualizar o formulário para editar a Autoclave EVOXX.
					</Trans>
				</Typography>
				<Typography>
					<Trans id="autoclaves.formEdit.changeModelOnly">
						Não é possível alterar o número de série depois de adicionar uma
						Autoclave, somente alterar o seu Modelo.
					</Trans>
				</Typography>
			</Container>
			<Container maxWidth="sm" disableGutters>
				<Box pt={4} display="flex" flexDirection="column">
					<UpdateAutoclaveForm autoclaveId={id} />
				</Box>
			</Container>
		</>
	);
};

export default React.memo(UpdateAutoclavePage);
