import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import LoginForm from 'components/auth/forms/LoginForm';
import Logo from 'components/layout/LogoEvoxx';

const LoginPage = () => (
	<Container maxWidth="xs">
		<Box
			py={6}
			display="flex"
			flexGrow={1}
			flexDirection="column"
			alignItems="center"
		>
			<Logo size={120} textColor="#000" />
		</Box>

		<Box m={1} display="flex" flexDirection="column">
			<Typography variant="h3" paragraph>
				<Trans id="login">Login</Trans>
			</Typography>

			<Box pt={1} pb={4}>
				<Typography>
					<Trans id="login.yourAccount">Faça login na sua conta EVOXX.</Trans>
				</Typography>
			</Box>

			<LoginForm />
		</Box>
	</Container>
);

export default React.memo(LoginPage);
