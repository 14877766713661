import { createMuiTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography from './typography';
import shadows from './shadows';

// A custom theme for this app
const theme = createMuiTheme({
	palette,
	typography,
	shape: {
		borderRadius: 6,
	},
	shadows,
});

export default theme;
