/**
 * Convert a url with path params to a url without them
 */
const replaceParams = (url, params) => {
	const remainingParams = { ...params };
	const newUrl = Object.keys(params).reduce((acc, val) => {
		const parsedUrl = acc.replace(`:${val}`, params[val]);
		if (parsedUrl !== acc) {
			delete remainingParams[val];
		}
		return parsedUrl;
	}, url);

	return [newUrl, remainingParams];
};

export const buildUrl = (pathname, params = {}) => {
	const base = window.location.href;
	const { screen, ...rest } = params;
	const [parsedUrl, remainingParams] = replaceParams(
		// we assume if a screen is passed *that* is the specific url
		screen || pathname,
		rest
	);
	const url = new URL(parsedUrl, base);
	const searchParams = new URLSearchParams(remainingParams);
	url.search = searchParams.toString();
	const newUrl = url.toString();

	const finalUrl = newUrl.replace(url.origin, '');

	return finalUrl;
};

export const removeLeadingSlash = path => (path.charAt(0) === '/' ? path.slice(1) : path);
