import gql from 'graphql-tag';

import { VALIDATION_ERROR } from './config';

const ROOT = `
	local__status
`;

const defaultReducer = {
	local__status: null,
};

const STATUS_FRAGMENT = gql`
	fragment statusFragment on Local__Status {
		type
		message
	}
`;

const typeDefs = gql`
	enum Local__StatusType {
		INFO
		ERROR
		SUCCESS
		WARNING
		VALIDATION_ERROR
	}

	type Local__Status {
		type: StatusType!
		message: String
	}

	input Local__StatusInput {
		type: Local__StatusType!
		message: String
	}

	input Local__StatusResetInput {
		types: [Local__StatusType]!
	}

	extend type Query {
		local__status: Local__Status!
	}

	extend type Mutation {
		local__setStatus(input: Local__StatusInput!): Local__Status!
		local__resetStatus(input: Local__StatusResetInput): Local__Status
	}
`;

export const QUERY_STATUS = gql`
	query local__Status {
		local__status @client {
			...statusFragment
		}
	}
	${STATUS_FRAGMENT}
`;

export const MUTATION_SET_STATUS = gql`
	mutation Local__SetStatus($input: Local__StatusInput!) {
		local__setStatus(input: $input) @client {
			...statusFragment
		}
	}
	${STATUS_FRAGMENT}
`;

export const MUTATION_RESET_STATUS = gql`
	mutation Local__ResetStatus($input: Local__StatusResetInput) {
		local__resetStatus(input: $input) @client {
			...statusFragment
		}
	}
	${STATUS_FRAGMENT}
`;

const resolvers = {
	Query: {
		local__status: (root, args, { cache, client }) => {
			const res = client.readQuery({ query: QUERY_STATUS });
			return res.local__status;
		},
	},
	Mutation: {
		local__setStatus: (root, { input }, { cache, client }) => {
			const { type, message } = input;
			if (type === VALIDATION_ERROR && !message) {
				// We don't want to overwrite existing specific validation errors with general validation errors
				const existing = client.readQuery({ query: QUERY_STATUS });
				if (existing.local__status?.type === VALIDATION_ERROR) {
					return existing.local__status;
				}
			}
			client.writeQuery({
				query: QUERY_STATUS,
				data: {
					local__status: {
						type,
						message,
						__typename: 'Local__Status',
					},
				},
			});
			return { type, message, __typename: 'Local__Status' };
		},
		local__resetStatus: (root, { input }, { cache, client }) => {
			const { types } = input || {};
			const existing = client.readQuery({ query: QUERY_STATUS });
			if (!types || types.includes(existing?.type)) {
				client.writeQuery({
					query: QUERY_STATUS,
					data: {
						local__status: null,
					},
				});
				return null;
			} else {
				return existing?.local__status || null;
			}
		},
	},
};

export default {
	typeDefs,
	resolvers,
	defaultReducer,
	ROOT,
};
