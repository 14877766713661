import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CreateUserForm from 'components/users/CreateUserForm';

const CreateUserPage = () => (
	<>
		<Container disableGutters>
			<Typography>
				<Trans id="users.useForm.add">
					Utilize o formulário abaixo para adicionar um usuário.
				</Trans>
			</Typography>
		</Container>
		<Container maxWidth="sm" disableGutters>
			<Box pt={4} display="flex" flexDirection="column">
				<CreateUserForm />
			</Box>
		</Container>
	</>
);

export default React.memo(CreateUserPage);
