import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import ModelForm from 'components/models/ModelForm';
import { useNavigation } from 'components/navigation/hooks';
import { QUERY_MODEL, MUTATION_UPDATE_MODEL } from 'components/models/graphql';

import Loading from 'UI/Loading';

import validationSchema from './validationSchema';

const EXCLUDE_ON_SUBMIT = ['__typename'];

const UpdateModelForm = ({ model }) => {
	const { navigate } = useNavigation();
	const [updateModel, { loading }] = useMutation(MUTATION_UPDATE_MODEL);
	const { loading: loadingModel, data: dataModel } = useQuery(QUERY_MODEL, {
		variables: {
			id: model,
		},
	});

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await updateModel({
					variables: {
						input,
					},
				});
				navigate('/admin/autoclaves/modelos');
			} catch (err) {
				console.log('handleOnSubmit error', err);
				throw err;
			}
		},
		[updateModel, navigate]
	);

	if (!dataModel || loadingModel) {
		return <Loading fullScreen />;
	}

	const initialValues = dataModel.model;

	return (
		<ModelForm
			loading={loading}
			initialValues={initialValues}
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			excludeOnSubmit={EXCLUDE_ON_SUBMIT}
		/>
	);
};

export default React.memo(UpdateModelForm);
