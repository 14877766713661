import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { useQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import InputAdornment from '@material-ui/core/InputAdornment';

import FinalForm from 'components/form/Form';
import TextField from 'components/form/TextField';
import AutocompleteField from 'components/form/AutocompleteField';
import SelectField from 'components/form/SelectField';
import SubmitButton from 'components/form/SubmitButton';

import { CUSTOMER } from 'components/users/config';
import { QUERY_USERS } from 'components/users/graphql';
import { QUERY_MODELS } from 'components/models/graphql';

import Grid from 'UI/Grid';
import Loading from 'UI/Loading';

import { propTypes, defaultProps } from './propTypes';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Submit = styled(SubmitButton)`
	margin: ${props => props.theme.spacing(3, 0, 2)};
`;

const AutoclaveForm = ({
	excludeOnSubmit,
	onSubmit,
	validationSchema,
	initialValues,
	loading,
	isUpdate,
	ownerLabel,
}) => {
	const [models, setModels] = React.useState([]);
	const { loading: loadingModels, data: dataModels } = useQuery(QUERY_MODELS);
	const {
		loading: loadingUsers,
		data: dataUsers,
		refetch,
	} = useQuery(QUERY_USERS, {
		variables: {
			role: CUSTOMER,
			limit: 1000,
		},
		fetchPolicy: 'cache-and-network',
	});

	React.useEffect(() => {
		const edges = dataModels?.models?.edges;

		if (edges && !loadingModels && !models.length) {
			const options = edges.map(item => ({
				label: item.name,
				value: item.id,
			}));

			setModels(options);
		}
	}, [dataModels, loadingModels, models]);

	const handleFormatLabel = React.useCallback(({ firstName, lastName }) => {
		return `${firstName} ${lastName}`;
	}, []);

	const handleFilterChange = useDebouncedCallback(async value => {
		await refetch({
			limit: 1000,
			role: CUSTOMER,
			searchText: value,
		});
	}, 400);

	return (
		<>
			{loading && <Loading fullScreen />}
			<FinalForm
				formName="autoclaveForm"
				validateOnBlur={false}
				onSubmit={onSubmit}
				initialValues={initialValues}
				excludeOnSubmit={excludeOnSubmit}
				validationSchema={validationSchema()}
				subscription={{ values: true, errors: true, pristine: true }}
				render={({ handleSubmit, submitting, pristine }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Field
										fullWidth
										type="text"
										name="userId"
										component="input"
										variant="outlined"
										label={t({
											id: 'ui.owners',
											message: 'Proprietário',
										})}
										options={dataUsers?.users?.edges}
										valueKey="id"
										ownerLabel={ownerLabel}
										loading={loadingUsers}
										formatLabel={handleFormatLabel}
										onChangeFilter={handleFilterChange.callback}
										InputProps={{
											endAdornment: loadingUsers && (
												<InputAdornment>
													<Loading
														size={20}
														color="secondary"
														fullScreen={false}
													/>
												</InputAdornment>
											),
										}}
									>
										{props => <AutocompleteField {...props} />}
									</Field>
								</Grid>

								<Grid item xs={12}>
									<Field
										type="select"
										name="model"
										component="input"
										variant="outlined"
										required
										fullWidth
										label={t({
											id: 'ui.model',
											message: 'Modelo',
										})}
										autoComplete="off"
										options={models}
									>
										{props => <SelectField {...props} />}
									</Field>
								</Grid>

								<Grid item xs={12}>
									<Field
										required
										fullWidth
										readOnly={isUpdate}
										maxLength="8"
										type="text"
										name="serialNumber"
										component="input"
										variant="outlined"
										disabled={initialValues?.unlock ? true : false}
										label={t({
											id: 'ui.serialNumber',
											message: 'Número de série',
										})}
										autoComplete="off"
										helperText={
											initialValues?.unlock ? (
												<Trans id="autoclave.serialFieldDisabled">
													Não é possível alterar o número de
													série depois da ativação
												</Trans>
											) : null
										}
									>
										{props => <TextField {...props} />}
									</Field>
								</Grid>

								<Grid item xs={12} sm="auto">
									<Submit
										type="submit"
										size="large"
										variant="contained"
										loadingColor="accent"
										color="primary"
										disabled={submitting}
										isFormPristine={pristine}
										isLoading={submitting || loading}
									>
										<Trans id="ui.save">Salvar</Trans>
									</Submit>
								</Grid>
							</Grid>
						</Form>
					);
				}}
			/>
		</>
	);
};

AutoclaveForm.propTypes = propTypes;

AutoclaveForm.defaultProps = defaultProps;

export default React.memo(AutoclaveForm);
