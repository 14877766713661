import React from 'react';
import { t } from '@lingui/macro';
import { useMutation } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import { QUERY_MODELS, MUTATION_REMOVE_MODEL } from 'components/models/graphql';

import StandardTable from 'components/table/StandardTable';
import { useNavigation } from 'components/navigation/hooks';
import useApolloCache from 'components/apollo/hooks';
import useStatus from 'components/status/hooks';

import processError from 'utils/errors';

import Loading from 'UI/Loading';
import ConfirmationDialog from 'UI/ConfirmationDialog';

const INITIAL_VARIABLES = {
	page: 0,
	limit: 5,
};

const ModelsTable = () => {
	const { navigate } = useNavigation();
	const { addSuccess, addError } = useStatus();
	const { removeItemFromCache } = useApolloCache();
	const [itemToRemove, setItemToRemove] = React.useState(null);

	const [removeItem, { loading }] = useMutation(MUTATION_REMOVE_MODEL);

	const showRemoveDialog = React.useCallback((event, model) => {
		setItemToRemove(model);
		event.stopPropagation();
	}, []);

	const handleOnRowClick = React.useCallback(
		id => {
			navigate(`/admin/autoclave/modelo/${id}`);
		},
		[navigate]
	);

	const handleOnDismiss = React.useCallback(event => {
		setItemToRemove(null);
		event.stopPropagation();
	}, []);

	const handleRemove = React.useCallback(
		async (event, refetch) => {
			try {
				event.stopPropagation();

				await removeItem({
					variables: {
						id: itemToRemove.id,
					},
				});

				removeItemFromCache('Model', itemToRemove.id);

				addSuccess(
					t({
						id: 'models.removeSuccess',
						message: `Modelo ${itemToRemove.name} removido com sucesso`,
					})
				);
				setItemToRemove(null);
			} catch (error) {
				event.stopPropagation();

				console.log('handleRemove error', error);
				setItemToRemove(null);
				addError(processError(error));
			}
		},
		[addError, addSuccess, itemToRemove, removeItem, removeItemFromCache]
	);

	const DATA_MAP = [
		{
			title: t({
				id: 'ui.name',
				message: 'Nome',
			}),
			fields: ['name'],
		},
		{
			title: t({
				id: 'ui.description',
				message: 'Descrição',
			}),
			fields: ['description'],
		},
	];

	return (
		<>
			{loading && <Loading fullScreen />}
			<ConfirmationDialog
				isOpen={!!itemToRemove}
				title={t({
					id: 'models.remove',
					message: 'Remover modelo de Autoclave',
				})}
				message={t({
					id: 'models.remove.askTo',
					message: `Deseja realmente remover o modelo ${itemToRemove?.name}`,
				})}
				onConfirmation={handleRemove}
				onClose={handleOnDismiss}
			/>
			<StandardTable
				fieldKey="models"
				query={QUERY_MODELS}
				dataMap={DATA_MAP}
				initialRowsPerPage={5}
				initialVariables={INITIAL_VARIABLES}
				fetchPolicy="cache-and-network"
				onRowClick={handleOnRowClick}
				addRoute="/admin/autoclaves/modelos/adicionar"
				actions={({ item }) => {
					return (
						<>
							<IconButton
								size="small"
								onClick={event => showRemoveDialog(event, item)}
							>
								<DeleteIcon />
							</IconButton>
						</>
					);
				}}
			/>
		</>
	);
};

export default React.memo(ModelsTable);
