import React from 'react';
import { Trans } from '@lingui/macro';

import MyAccount from 'pages/client/MyAccount';
import MyAutoclaves from 'root/pages/client/MyAutoclaves';
import MyAutoclavesAdd from 'root/pages/client/MyAutoclavesAdd';
import MyAutoclave from 'root/pages/client/MyAutoclave';
import ReportsList from 'root/pages/client/ReportsList';
import ImportReport from 'root/pages/client/ImportReport';

import NotFound from 'pages/public/NotFound';

import { CUSTOMER } from 'components/users/config';

const routes = [
	{
		path: '/cliente/minhas-autoclaves',
		title: <Trans id="route.c.myAutoclaves">Minhas Autoclaves</Trans>,
		component: MyAutoclaves,
		isPrivate: true,
		exact: true,
		isHome: true,
		allowRole: [CUSTOMER],
	},
	{
		path: '/cliente/adicionar-autoclave',
		title: <Trans id="route.c.myAutoclavesAdd">Adicionar Autoclave</Trans>,
		component: MyAutoclavesAdd,
		isPrivate: true,
		exact: true,
		isHome: true,
		allowRole: [CUSTOMER],
	},
	{
		path: '/cliente/autoclave/:id',
		title: <Trans id="route.c.myAutoclave">Autoclave</Trans>,
		component: MyAutoclave,
		isPrivate: true,
		exact: true,
		allowRole: [CUSTOMER],
	},
	{
		path: '/cliente/relatorios',
		title: <Trans id="route.c.reportsList">Relatórios</Trans>,
		component: ReportsList,
		isPrivate: true,
		exact: true,
		allowRole: [CUSTOMER],
	},
	{
		path: '/cliente/importar-ciclos',
		title: <Trans id="route.c.importReport">Importar Ciclos</Trans>,
		component: ImportReport,
		isPrivate: true,
		exact: true,
		allowRole: [CUSTOMER],
	},
	{
		path: '/cliente/minha-conta',
		title: <Trans id="route.c.myAccount">Minha Conta</Trans>,
		component: MyAccount,
		isPrivate: true,
		exact: true,
		allowRole: [CUSTOMER],
	},
	{
		path: '*',
		title: <Trans id="route.c.notFound">404</Trans>,
		component: NotFound,
		isPrivate: false,
	},
];

export default routes;
