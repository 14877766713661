import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { useQuery, useMutation } from '@apollo/client';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';

import FinalForm from 'components/form/Form';
import UploadField from 'components/form/UploadField';
import SelectField from 'components/form/SelectField';
import SubmitButton from 'components/form/SubmitButton';
import { MUTATION_IMPORT_AUTOCLAVE } from 'components/autoclaves/graphql';
import { QUERY_MODELS } from 'components/models/graphql';

import Grid from 'UI/Grid';
import Loading from 'UI/Loading';

import validationSchema from './validationSchema';
import { propTypes, defaultProps } from './propTypes';
import { ListItemIcon } from '@material-ui/core';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Submit = styled(SubmitButton)`
	margin: ${props => props.theme.spacing(3, 0, 2)};
`;

const INITIAL_VALUES = {
	model: '',
	csvFile: '',
};

const ImportAutoclaveForm = () => {
	const [models, setModels] = React.useState([]);
	const { loading: loadingModels, data: dataModels } = useQuery(QUERY_MODELS);
	const [importAutoclave, { data, loading }] = useMutation(MUTATION_IMPORT_AUTOCLAVE);

	React.useEffect(() => {
		const edges = dataModels?.models?.edges;

		if (edges && !loadingModels && !models.length) {
			const options = edges.map(item => ({
				label: item.name,
				value: item.id,
			}));

			setModels(options);
		}
	}, [dataModels, loadingModels, models]);

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await importAutoclave({
					variables: {
						input,
					},
				});
				// navigate('/admin/autoclaves')
			} catch (err) {
				console.log('handleOnSubmit error', err);
				throw err;
			}
		},
		[importAutoclave]
	);

	return (
		<>
			{loading && <Loading fullScreen />}
			<FinalForm
				formName="importAutoclaveForm"
				validateOnBlur={false}
				onSubmit={handleOnSubmit}
				initialValues={INITIAL_VALUES}
				validationSchema={validationSchema()}
				subscription={{ values: true, errors: true, pristine: true }}
				render={({ handleSubmit, submitting, pristine }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Field
										required
										fullWidth
										type="file"
										name="csvFile"
										component="input"
										variant="outlined"
										label={t({
											id: 'ui.selectFile',
											message: 'Selecionar arquivo',
										})}
									>
										{props => (
											<UploadField {...props} accept="text/plain" />
										)}
									</Field>
								</Grid>
								<Grid item xs={12}>
									<Field
										type="select"
										name="model"
										component="input"
										variant="outlined"
										required
										fullWidth
										label={t({
											id: 'ui.model',
											message: 'Modelo',
										})}
										autoComplete="off"
										options={models}
									>
										{props => <SelectField {...props} />}
									</Field>
								</Grid>
								<Grid item xs={12} sm="auto">
									<Submit
										type="submit"
										size="large"
										variant="contained"
										loadingColor="accent"
										color="primary"
										disabled={submitting}
										isFormPristine={pristine}
										isLoading={submitting || loading}
									>
										<Trans id="ui.import">Importar</Trans>
									</Submit>
								</Grid>
							</Grid>
						</Form>
					);
				}}
			/>

			{data && (
				<Box pt={3} pb={0}>
					<Paper elevation={1}>
						<List
							dense
							subheader={
								<ListSubheader>
									<Trans id="ui.import.result">
										Resultado da importação
									</Trans>
								</ListSubheader>
							}
						>
							<ListItem>
								<ListItemText>
									<ListItemIcon>
										{data.importAutoclave.created}
									</ListItemIcon>
									<Trans id="ui.import.created">Criado</Trans>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<ListItemIcon>
										{data.importAutoclave.alreadyExisted}
									</ListItemIcon>
									<Trans id="ui.import.alreadyExist">Já existiam</Trans>
								</ListItemText>
							</ListItem>
						</List>

						<Divider />

						<List dense>
							<ListItem>
								<ListItemText>
									<ListItemIcon>
										{data.importAutoclave.invalidTotal}
									</ListItemIcon>
									<Trans id="ui.import.invalidItems">
										Itens inválidos
									</Trans>
								</ListItemText>
							</ListItem>
						</List>

						<Divider />

						<List dense>
							<ListItem>
								<ListItemText>
									<ListItemIcon>
										{data.importAutoclave.total}
									</ListItemIcon>
									<Trans id="ui.import.total">Total</Trans>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<ListItemIcon>
										{data.importAutoclave.duplicatedTotal}
									</ListItemIcon>
									<Trans id="ui.import.duplicatedItems">
										Itens duplicados no arquivo
									</Trans>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<ListItemIcon>
										{data.importAutoclave.itemsTotal}
									</ListItemIcon>
									<Trans id="ui.import.uniqueItems">
										Itens únicos que foram processados
									</Trans>
								</ListItemText>
							</ListItem>
						</List>
					</Paper>
				</Box>
			)}
		</>
	);
};

ImportAutoclaveForm.propTypes = propTypes;

ImportAutoclaveForm.defaultProps = defaultProps;

export default React.memo(ImportAutoclaveForm);
