import React from 'react';
import { Trans } from '@lingui/macro';

import MyAccount from 'pages/client/MyAccount';
import Maintenance from 'pages/technician/Maintenance';
import MaintenanceHistory from 'pages/technician/MaintenanceHistory';

import Reports from 'pages/technician/Reports';
import ImportReport from 'pages/technician/ImportReport';

import NotFound from 'pages/public/NotFound';

import { TECHNICIAN } from 'components/users/config';

const routes = [
	{
		path: '/tecnico/manutencao',
		title: <Trans id="route.c.maintenance">Manutenção</Trans>,
		component: Maintenance,
		isPrivate: true,
		exact: true,
		isHome: true,
		allowRole: [TECHNICIAN],
	},
	{
		path: '/tecnico/manutencao/historico',
		title: <Trans id="route.c.maintenanceHistory">Manutenção</Trans>,
		component: MaintenanceHistory,
		isPrivate: true,
		exact: true,
		isHome: true,
		allowRole: [TECHNICIAN],
	},
	{
		path: '/tecnico/relatorios',
		title: <Trans id="route.c.reports">Relatórios</Trans>,
		component: Reports,
		isPrivate: true,
		exact: true,
		allowRole: [TECHNICIAN],
	},
	{
		path: '/tecnico/relatorio/importar',
		title: <Trans id="route.c.importReport">Relatório</Trans>,
		component: ImportReport,
		isPrivate: true,
		exact: true,
		allowRole: [TECHNICIAN],
	},
	{
		path: '/tecnico/minha-conta',
		title: <Trans id="route.c.myAccount">Conta</Trans>,
		component: MyAccount,
		isPrivate: true,
		exact: true,
		allowRole: [TECHNICIAN],
	},
	{
		path: '*',
		title: <Trans id="route.c.notFound">404</Trans>,
		component: NotFound,
		isPrivate: false,
	},
];

export default routes;
