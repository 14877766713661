import { object, string } from 'yup';
import { t } from '@lingui/macro';

const validationSchema = () => {
	const required = t({
		id: 'validation.required',
		message: 'Requirido',
	});
	const lockCodeLength = 5;
	const lockCodeLengthMsg = t({
		id: 'validation.minDigitsN',
		message: `Precisa ter ${lockCodeLength} dígitos`,
	});

	return object().shape({
		lockCode: string()
			.nullable()
			.test(
				'lockCodeLength',
				lockCodeLengthMsg,
				value => value.length === lockCodeLength
			)
			.required(required),
	});
};

export default validationSchema;
