import React from 'react';
import { Trans } from '@lingui/macro';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmationDialog = ({ isOpen, title, message, onConfirmation, onClose }) => (
	<Dialog
		open={isOpen}
		onClose={onClose}
		aria-labelledby="alert-dialog-title"
		aria-describedby="alert-dialog-description"
	>
		<DialogTitle id="alert-dialog-title">{title}</DialogTitle>
		<DialogContent>
			<DialogContentText id="alert-dialog-description">{message}</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={onClose}>
				<Trans id="ui.c.cancel">Cancelar</Trans>
			</Button>
			<Button onClick={onConfirmation} color="primary" autoFocus>
				<Trans id="ui.c.confirm">Confirmar</Trans>
			</Button>
		</DialogActions>
	</Dialog>
);

export default React.memo(ConfirmationDialog);
