import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

const LinkStyled = styled(Link)`
	text-decoration: none;
	color: ${p => p.theme.palette.primary.main};
`;

const TheLink = props => <LinkStyled {...props} />;

export default TheLink;
