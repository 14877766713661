import PropTypes from 'prop-types';

export const propTypes = {
	id: PropTypes.string,
	formName: PropTypes.string.isRequired,
	onSubmit: PropTypes.func.isRequired,
	transformValues: PropTypes.func,

	initialValues: PropTypes.object,
	mutators: PropTypes.object,
	subscription: PropTypes.object,
	decorators: PropTypes.object,

	validateOnBlur: PropTypes.bool,
	keepDirtyOnReinitialize: PropTypes.bool,
	resetOnSuccess: PropTypes.bool,

	validate: PropTypes.func,
	validationSchema: PropTypes.object,
	showValidate: PropTypes.bool,
	showSuccess: PropTypes.bool,
	debug: PropTypes.func,
	excludeOnSubmit: PropTypes.array,

	onSubmitSuccess: PropTypes.func,
	render: PropTypes.func.isRequired,
};

export const defaultProps = {
	id: undefined,
	validate: undefined,
	validationSchema: undefined,
	validateOnBlur: true,
	mutators: undefined,
	subscription: {},
	decorators: undefined,
	keepDirtyOnReinitialize: true,
	resetOnSuccess: true,

	debug: undefined,
	initialValues: {},
	showValidate: true,
	showSuccess: true,
	onSubmitSuccess: () => {},
	transformValues: data => data,
};
