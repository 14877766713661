import { object, string } from 'yup';
import { t } from '@lingui/macro';

const validationSchema = () => {
	const required = t({
		id: 'validation.required',
		message: 'Requirido',
	});

	return object().shape({
		name: string().nullable().required(required),
		description: string().nullable(),
	});
};

export default validationSchema;
