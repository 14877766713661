import React from 'react';

import { millissecondsToDateAndTime } from 'utils/date';
import { Trans } from '@lingui/macro';

import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';

import wrenchIcon from '../../../assets/images/wrench-icon.svg';

import { useLazyQuery } from '@apollo/client';
import { QUERY_MAINTENANCE_BY_SERIALNUMBER } from 'components/maintenance/graphql';

const Card = styled.div`
	display: flex;
	position: relative;
	flex-direction: row;
	max-width: 552px;
	border: solid 1px ${({ theme }) => theme.palette.primary.main};
	padding: 1rem 1.4rem;
	border-radius: ${p => p.theme.shape.borderRadius}px;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	gap: 25px;
`;

const Icon = styled.div`
	width: 58px;
	height: 58px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-color: ${({ theme }) => theme.palette.primary.main};
	margin-right: 1rem;
`;

const MainContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

const MainTitle = styled(Typography)`
	font-size: 0.9rem;
	font-weight: 700;
	color: #717171;
	line-height: 100%;
	margin-bottom: 2.5px;
`;

const MainComplement = styled(Typography)`
	font-size: 0.7625rem;
	font-weight: 300;
	color: #717171;
	line-height: 100%;
`;

const MaintenanceCode = styled(Typography)`
	font-size: 1.525rem;
	font-weight: 700;
	color: #000000;
	line-height: 100%;
	margin-top: 5px;
`;

const SecondaryTitle = styled(Typography)`
	font-size: 0.625rem;
	font-weight: 700;
	color: #717171;
	line-height: 100%;
	width: 118px !important;
	margin-bottom: 2px;
`;

const SecondaryComplement = styled(Typography)`
	font-size: 0.7625rem;
	font-weight: 400;
	color: #000000;
	line-height: 100%;
	width: 118px !important;
`;

const SecondaryContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	min-width: 118px;
	max-width: 265px;
	gap: 10px;
	justify-content: space-between;
`;

export default function MaintenanceInfo({ serialNumber }) {
	const [autoclaveBySerialNumber, { data }] = useLazyQuery(
		QUERY_MAINTENANCE_BY_SERIALNUMBER,
		{
			fetchPolicy: 'network-only',
		}
	);

	React.useEffect(() => {
		checkSerialNumber();
	}, []);

	const codeGeneratedAt = React.useMemo(() => {
		if (data?.maintenanceBySerialNumber?.codeGeneratedAt) {
			return millissecondsToDateAndTime(
				data.maintenanceBySerialNumber.codeGeneratedAt
			);
		}
		return null;
	}, [data]);

	const confirmedAt = React.useMemo(() => {
		if (data?.maintenanceBySerialNumber?.confirmedAt) {
			return millissecondsToDateAndTime(data.maintenanceBySerialNumber.confirmedAt);
		}
		return null;
	}, [data]);

	const checkSerialNumber = React.useCallback(async () => {
		try {
			await autoclaveBySerialNumber({
				variables: {
					serialNumber: serialNumber,
				},
			});
		} catch (error) {
			console.log('checkSerialNumber error', error);
		}
	}, [autoclaveBySerialNumber, serialNumber]);

	if (!data || data?.maintenanceBySerialNumber?.done === false) {
		return (
			<Box mt={2}>
				<Alert severity="info">
					<Trans id="ui.maintenanceInfo.noMaintenance">
						Esta autoclave ainda não recebeu a manutenção de 600 ciclos.
					</Trans>
				</Alert>
			</Box>
		);
	}

	if (
		data?.maintenanceBySerialNumber?.done === true &&
		data?.maintenanceBySerialNumber?.codeGeneratedAt === null
	) {
		return (
			<Box mt={2}>
				<Alert severity="error">
					<Trans id="ui.maintenanceInfo.inconsistencyData">
						Inconsistência no histórico de manutenção, por favor, entre em
						contato com a equipe de suporte.
					</Trans>
				</Alert>
			</Box>
		);
	}

	if (
		data?.maintenanceBySerialNumber?.done === true &&
		data?.maintenanceBySerialNumber?.codeGeneratedAt != null &&
		data?.maintenanceBySerialNumber?.confirmedAt === null
	) {
		return (
			<Box mt={2}>
				<Alert severity="warning">
					<Trans id="ui.maintenanceInfo.needToUploadFiles">
						A manutenção de 600 ciclos foi realizada, porém não foi
						confirmada. É necessário realizar o upload dos últimos relatórios
						da autoclave.
					</Trans>
				</Alert>
			</Box>
		);
	}

	if (
		data?.maintenanceBySerialNumber?.done === true &&
		data?.maintenanceBySerialNumber?.codeGeneratedAt != null &&
		data?.maintenanceBySerialNumber?.confirmedAt != null
	) {
		return (
			<Box mt={2}>
				<Paper elevation={6}>
					<Box p={3}>
						<Box
							display="flex"
							flexDirection="column"
							justifyContent="center"
							height="100%"
						>
							<Card>
								<MainContainer>
									<Icon>
										<img src={wrenchIcon} alt="wrench" />
									</Icon>
									<Box display="flex" flexDirection="column">
										<MainTitle>
											<Trans id="ui.maintenanceInfo.maintenance">
												MANUTENÇÃO
											</Trans>
										</MainTitle>
										<MainComplement>
											<Trans id="ui.maintenanceInfo.600Cyles">
												600 CICLOS
											</Trans>
										</MainComplement>
										<MaintenanceCode>
											{data?.maintenanceBySerialNumber?.code}
										</MaintenanceCode>
									</Box>
								</MainContainer>
								<SecondaryContainer>
									<Box>
										<SecondaryTitle>
											<Trans id="ui.maintenanceInfo.cycles">
												CICLOS
											</Trans>
										</SecondaryTitle>
										<SecondaryComplement>
											{data?.maintenanceBySerialNumber?.totalCycles}
										</SecondaryComplement>
									</Box>
									<Box>
										<SecondaryTitle>
											<Trans id="ui.maintenanceInfo.technician">
												TÉCNICO
											</Trans>
										</SecondaryTitle>
										<SecondaryComplement>
											{`${data?.maintenanceBySerialNumber?.codeGeneratedBy?.firstName} ${data?.maintenanceBySerialNumber?.codeGeneratedBy?.lastName}`}
										</SecondaryComplement>
									</Box>
									<Box>
										<SecondaryTitle>
											<Trans id="ui.maintenanceInfo.codeDate">
												DATA DO CÓDIGO
											</Trans>
										</SecondaryTitle>
										<SecondaryComplement>
											{codeGeneratedAt}
										</SecondaryComplement>
									</Box>
									<Box>
										<SecondaryTitle>
											<Trans id="ui.maintenanceInfo.confirmationDate">
												DATA DE CONFIRMAÇÃO
											</Trans>
										</SecondaryTitle>
										<SecondaryComplement>
											{confirmedAt}
										</SecondaryComplement>
									</Box>
								</SecondaryContainer>
							</Card>
						</Box>
					</Box>
				</Paper>
			</Box>
		);
	}
}
