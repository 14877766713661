import React from 'react';
import { useMutation } from '@apollo/client';

import AutoclaveForm from 'components/autoclaves/AutoclaveForm';
import { useNavigation } from 'components/navigation/hooks';
import { MUTATION_CREATE_AUTOCLAVE } from 'components/autoclaves/graphql';

import validationSchema from './validationSchema';

const EXCLUDE_ON_SUBMIT = [];

const INITIAL_VALUES = {
	model: '',
	serialNumber: '',
};

const CreateAutoclaveForm = () => {
	const { navigate } = useNavigation();

	const [createAutoclave, { loading }] = useMutation(MUTATION_CREATE_AUTOCLAVE);

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await createAutoclave({
					variables: {
						input,
					},
				});
				navigate('/admin/autoclaves');
			} catch (err) {
				console.log('handleOnSubmit error', err);
				throw err;
			}
		},
		[createAutoclave, navigate]
	);

	return (
		<AutoclaveForm
			loading={loading}
			initialValues={INITIAL_VALUES}
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			excludeOnSubmit={EXCLUDE_ON_SUBMIT}
		/>
	);
};

export default React.memo(CreateAutoclaveForm);
