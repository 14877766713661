import React from 'react';
import { Trans } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import AutoclaveMaintenance from 'components/maintenance/AutoclaveMaintenance';

const MaintenancePage = () => {
	const { serialNumber } = useParams();

	return (
		<Container disableGutters>
			<Grid container component="main">
				<Grid item xs={12} sm={8} md={9}>
					<Typography paragraph>
						<Trans id="maintenance.addSerialGetDetails">
							Informe o número de série para ter acesso aos detalhes da
							Autoclave.
						</Trans>
					</Typography>
				</Grid>
			</Grid>

			<Box pt={4}>
				<AutoclaveMaintenance serialNumber={serialNumber} />
			</Box>
		</Container>
	);
};

export default React.memo(MaintenancePage);
