import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useMutation } from '@apollo/client';
import { Field } from 'react-final-form';

import FinalForm from 'components/form/Form';
import { useNavigation } from 'components/navigation/hooks';
import { MUTATION_PASSWORD_RESET_REQUEST } from 'components/auth/graphql';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';
import config from 'root/config';

import validationSchema from './validationSchema';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Submit = styled(SubmitButton)`
	margin: ${props => props.theme.spacing(3, 0, 2)};
`;

const INITIAL_VALUES = {
	email: '',
};

const PasswordResetRequestForm = () => {
	const { navigate } = useNavigation();
	const [passwordResetRequest, { loading }] = useMutation(
		MUTATION_PASSWORD_RESET_REQUEST
	);

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await passwordResetRequest({
					variables: {
						input: {
							email: input.email,
							appUrl: `${config.appUrl}/nova-senha`,
						},
					},
				});
				navigate('/login');
			} catch (error) {
				throw error;
			}
		},
		[navigate, passwordResetRequest]
	);

	return (
		<FinalForm
			formName="passwordResetRequest"
			onSubmit={handleOnSubmit}
			initialValues={INITIAL_VALUES}
			validationSchema={validationSchema()}
			validateOnBlur={false}
			subscription={{ values: true, errors: true }}
			render={({ handleSubmit, submitting, pristine }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<Field
							type="email"
							name="email"
							component="input"
							variant="outlined"
							margin="normal"
							required
							fullWidth
							label={t({
								id: 'ui.email',
								message: 'Email',
							})}
							autoComplete="email"
							autoFocus
						>
							{props => <TextField {...props} />}
						</Field>
						<Submit
							type="submit"
							fullWidth
							size="large"
							variant="contained"
							loadingColor="accent"
							color="primary"
							disabled={submitting || pristine}
							isLoading={submitting || loading}
						>
							<Trans id="auth.sendLink">Enviar link</Trans>
						</Submit>
					</Form>
				);
			}}
		/>
	);
};

export default React.memo(PasswordResetRequestForm);
