import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import SignUpForm from 'components/users/SignUpForm';
import Logo from 'components/layout/LogoEvoxx';

const SignUpPage = () => (
	<Container maxWidth="sm">
		<Box py={6} px={3} display="flex" alignItems="center" flexDirection="column">
			<Box pb={6} display="flex" alignItems="center" flexDirection="column">
				<Logo size={120} textColor="#000000" />
			</Box>

			<Box p={1} display="flex" alignItems="left" flexDirection="column">
				<Typography variant="h3" paragraph>
					<Trans id="auth.createAccount">Criar conta</Trans>
				</Typography>

				<Typography paragraph>
					<Trans id="auth.createAccountServices">
						Cliente, crie sua conta para ter acesso a serviços exclusivos.
					</Trans>
				</Typography>

				<SignUpForm />
			</Box>
		</Box>
	</Container>
);

export default React.memo(SignUpPage);
