import React from 'react';
import { t } from '@lingui/macro';
import BuildIcon from '@material-ui/icons/Build';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HistoryIcon from '@material-ui/icons/History';
import DescriptionIcon from '@material-ui/icons/Description';

import MenuList from 'components/layout/MenuList';

export const MainMenu = () => {
	const MENU_PRIMARY = [
		{
			to: '/tecnico/manutencao',
			label: t({
				id: 'ui.maintenance',
				message: 'Manutenção',
			}),
			icon: <BuildIcon />,
			showDivider: true,
		},
		{
			to: '/tecnico/manutencao/historico',
			label: t({
				id: 'ui.maintenanceHistory',
				message: 'Histórico de Manutenção',
			}),
			icon: <HistoryIcon />,
			showDivider: true,
		},
		{
			to: '/tecnico/relatorios',
			label: t({
				id: 'ui.reports',
				message: 'Relatórios',
			}),
			icon: <DescriptionIcon />,
		},
	];

	const MENU_LOGOUT = [
		{
			to: '/logout',
			label: t({
				id: 'ui.logout',
				message: 'Sair',
			}),
			icon: <ArrowBackIcon />,
		},
	];

	return (
		<>
			<MenuList items={MENU_PRIMARY} showDivider />
			<MenuList items={MENU_LOGOUT} />
		</>
	);
};

export default React.memo(MainMenu);
