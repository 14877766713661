import PropTypes from 'prop-types';

export const propTypes = {
	autoclave: PropTypes.object.isRequired,
	ofCycles: PropTypes.number,
	code: PropTypes.string,
	onSuccess: PropTypes.func.isRequired,
};

export const defaultProps = {
	ofCycles: 600,
	code: undefined,
};
