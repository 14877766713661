import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';
import { useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';

import FinalForm from 'components/form/Form';
import UploadField from 'components/form/UploadField';
import SubmitButton from 'components/form/SubmitButton';
// import { useNavigation } from 'components/navigation/hooks';
import { MUTATION_IMPORT_MULTIPLE_REPORTS } from 'components/reports/graphql';

import Grid from 'UI/Grid';
import Loading from 'UI/Loading';

import validationSchema from './validationSchema';
import { propTypes, defaultProps } from './propTypes';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Submit = styled(SubmitButton)`
	// margin: ${props => props.theme.spacing(3, 0, 2)};
`;

const INITIAL_VALUES = {
	csvFile: '',
};

const ImportReportForm = ({ serialNumber, onSuccess }) => {
	// const { goBack } = useNavigation();
	const [importReports, { loading, data }] = useMutation(
		MUTATION_IMPORT_MULTIPLE_REPORTS
	);

	const handleOnSubmit = React.useCallback(
		async ({ csvFile }) => {
			await importReports({
				variables: {
					input: {
						csvFile: csvFile,
						serialNumber,
					},
				},
			});

			if (onSuccess) {
				onSuccess();
			}
			// else {
			// goBack();
			// }
		},
		[importReports, onSuccess, serialNumber]
	);

	const res = data?.importReports;

	return (
		<>
			{loading && <Loading fullScreen />}
			<FinalForm
				formName="importReportForm"
				validateOnBlur={false}
				onSubmit={handleOnSubmit}
				initialValues={INITIAL_VALUES}
				validationSchema={validationSchema()}
				subscription={{ values: true, errors: true, pristine: true }}
				render={({ handleSubmit, submitting, pristine, values }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Grid container spacing={1}>
								<Grid item xs={12} sm="auto">
									<Field
										required
										multiple
										maxFiles={30}
										fullWidth
										type="file"
										name="csvFile"
										component="input"
										variant="outlined"
										label={t({
											id: 'ui.selectFile',
											message: 'Selecionar arquivo',
										})}
									>
										{props => (
											<UploadField {...props} accept="text/plain" />
										)}
									</Field>
								</Grid>
								{values.csvFile && (
									<Grid item xs={12} sm="auto">
										<Submit
											type="submit"
											variant="contained"
											loadingColor="accent"
											color="primary"
											disabled={submitting}
											isFormPristine={pristine}
											isLoading={submitting || loading}
										>
											<Trans id="ui.import">Importar</Trans>
										</Submit>
									</Grid>
								)}
							</Grid>
						</Form>
					);
				}}
			/>

			{data && (
				<Box pt={3} pb={0}>
					<Paper elevation={1}>
						<List
							dense
							subheader={
								<ListSubheader>
									<Trans id="ui.import.result">
										Resultado da importação
									</Trans>
								</ListSubheader>
							}
						>
							<ListItem>
								<ListItemText>
									<ListItemIcon>{res.success}</ListItemIcon>
									<Trans id="ui.import.success">
										Importado com sucesso
									</Trans>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<ListItemIcon>{res.alreadyExist}</ListItemIcon>
									<Trans id="ui.import.alreadyExist">Já existiam</Trans>
								</ListItemText>
							</ListItem>
							<ListItem>
								<ListItemText>
									<ListItemIcon>{res.errors}</ListItemIcon>
									<Trans id="ui.import.invalidOrErrorItems">
										Itens com erro ou inválidos
									</Trans>
								</ListItemText>
							</ListItem>
						</List>

						<Divider />

						<List dense>
							<ListItem>
								<ListItemText>
									<ListItemIcon>{res.total}</ListItemIcon>
									<Trans id="ui.import.total">Total</Trans>
								</ListItemText>
							</ListItem>
						</List>
					</Paper>
				</Box>
			)}
		</>
	);
};

ImportReportForm.propTypes = propTypes;

ImportReportForm.defaultProps = defaultProps;

export default React.memo(ImportReportForm);
