import React from 'react';
import { Trans, t } from '@lingui/macro';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useNavigation } from 'components/navigation/hooks';
import { MUTATION_EMAIL_VALIDATION } from 'components/auth/graphql';
import useStatus from 'components/status/hooks';

import { LINK_EXPIRED } from 'utils/errors';
import Loading from 'UI/Loading';
import Button from 'UI/Button';

const ValidateEmail = () => {
	const { token } = useParams();
	const { addError } = useStatus();
	const { replace } = useNavigation();
	const [emailValidation, { loading }] = useMutation(MUTATION_EMAIL_VALIDATION);

	const [isExpired, setIsExpired] = React.useState(false);

	const validateEmail = React.useCallback(async () => {
		try {
			await emailValidation({
				variables: {
					input: {
						token,
					},
				},
			});
		} catch (error) {
			const isTokenExpiried = error.graphQLErrors.find(
				item => item.type === LINK_EXPIRED
			);
			if (isTokenExpiried) {
				setIsExpired(true);
			} else {
				addError(
					t({
						id: 'auth.invalidLink',
						message: 'Link inválido, tente fazer o login',
					})
				);
			}
		}
	}, [addError, emailValidation, token]);

	React.useEffect(() => {
		validateEmail();
	}, [validateEmail]);

	return (
		<>
			{loading && <Loading fullScreen />}
			<Box pt={3}>
				{isExpired && !loading && (
					<>
						<Typography>
							<Trans id="auth.linkExpired">
								Esse link expirou, tente fazer o login novamente.
							</Trans>
						</Typography>

						<Box pt={3}>
							<Button
								fullWidth
								size="large"
								variant="contained"
								color="primary"
								onClick={() => replace('/login')}
							>
								<Trans id="ui.doLogin">Fazer login</Trans>
							</Button>
						</Box>
					</>
				)}
				{!isExpired && !loading && (
					<>
						<Typography>
							<Trans id="auth.emailValidationSuccess">
								Email validado com sucesso, agora você pode fazer login na
								sua conta EVOXX.
							</Trans>
						</Typography>

						<Box pt={3}>
							<Button
								fullWidth
								size="large"
								variant="contained"
								color="primary"
								onClick={() => replace('/login')}
							>
								<Trans id="ui.doLogin">Fazer login</Trans>
							</Button>
						</Box>
					</>
				)}
			</Box>
		</>
	);
};

export default ValidateEmail;
