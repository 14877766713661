import gql from 'graphql-tag';

import { AUTOCLAVE_FRAGMENT } from 'components/autoclaves/graphql';
import { USER_FRAGMENT } from 'components/users/graphql';

export const MAINTENANCE_FRAGMENT = gql`
	fragment maintenanceFragment on Maintenance {
		id
		autoclave {
			...autoclaveFragment
		}
		ofCycles
		totalCycles
		done
		code
		codeGeneratedBy {
			...userFragment
		}
		codeGeneratedAt
		confirmedAt
	}
	${USER_FRAGMENT}
	${AUTOCLAVE_FRAGMENT}
`;

export const MAINTENANCES_FRAGMENT = gql`
	fragment maintenancesFragment on MaintenanceListResult {
		edges {
			...maintenanceFragment
		}
		totalCount
		pageInfo {
			hasNextPage
		}
	}
	${MAINTENANCE_FRAGMENT}
`;

export const CHECK_MAINTENANCE_FRAGMENT = gql`
	fragment checkMaintenance on CheckMaintenance {
		needUpdate
		unlocked
		maintenance {
			...maintenanceFragment
		}
		autoclave {
			...autoclaveFragment
		}
	}
	${MAINTENANCE_FRAGMENT}
	${AUTOCLAVE_FRAGMENT}
`;

export const QUERY_MAINTENANCES = gql`
	query Maintenances(
		$limit: Int = 5
		$page: Int = 0
		$autoclaveId: ID
		$searchText: String
		$alertType: AlertType
	) {
		maintenances(
			limit: $limit
			page: $page
			autoclaveId: $autoclaveId
			searchText: $searchText
			alertType: $alertType
		) {
			...maintenancesFragment
		}
	}
	${MAINTENANCES_FRAGMENT}
`;

export const QUERY_MAINTENANCE = gql`
	query Maintenance($id: ID!) {
		maintenance(id: $id) {
			...maintenanceFragment
		}
	}
	${MAINTENANCE_FRAGMENT}
`;

export const QUERY_MAINTENANCE_BY_SERIALNUMBER = gql`
	query MaintenanceBySerialNumber($serialNumber: String!) {
		maintenanceBySerialNumber(serialNumber: $serialNumber) {
			...maintenanceFragment
		}
	}
	${MAINTENANCE_FRAGMENT}
`;

export const QUERY_CHECK_MAINTENANCE = gql`
	query checkMaintenance($serialNumber: String!) {
		checkMaintenance(serialNumber: $serialNumber) {
			...checkMaintenance
		}
	}
	${CHECK_MAINTENANCE_FRAGMENT}
`;

export const MUTATION_GENERATE_MAINTENANCE_CODE = gql`
	mutation GenerateMaintenanceCode($input: GenerateMaintenanceCodeInput!) {
		generateMaintenanceCode(input: $input) {
			...maintenanceFragment
		}
	}
	${MAINTENANCE_FRAGMENT}
`;
