import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { Field } from 'react-final-form';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import RoomIcon from '@material-ui/icons/Room';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import FinalForm from 'components/form/Form';
import { QUERY_ME } from 'components/users/graphql';
import { QUERY_USERS, MUTATION_REMOVE_USER } from 'components/users/graphql';
import { ROLES, ROLES_ARRAY } from 'components/users/config';
import StandardTable from 'components/table/StandardTable';
import { useNavigation } from 'components/navigation/hooks';
import useApolloCache from 'components/apollo/hooks';
import useStatus from 'components/status/hooks';
import StateSelect from 'components/states/StateSelect';
import CitySelect from 'components/cities/CitySelect';

import processError from 'utils/errors';

import Grid from 'UI/Grid';
import Button from 'UI/Button';
import Loading from 'UI/Loading';
import ConfirmationDialog from 'UI/ConfirmationDialog';

const Form = styled.form`
	width: 100%;
`;

const INITIAL_VARIABLES = {
	page: 0,
	limit: 5,
};

const LocationFilter = ({ initialValues, onChange, isActive }) => {
	const [showFilter, setShowFilter] = React.useState(false);

	const handleOnSubmit = React.useCallback(
		values => {
			onChange(values);
			setShowFilter(false);
		},
		[onChange]
	);

	return (
		<>
			<IconButton
				color={isActive ? 'primary' : undefined}
				onClick={() => setShowFilter(true)}
			>
				<RoomIcon />
			</IconButton>
			<Dialog open={showFilter}>
				<DialogTitle>
					<Trans id="ui.filterByLocation">Filtrar por localização</Trans>
				</DialogTitle>
				<FinalForm
					formName="locationForm"
					showSuccess={false}
					validateOnBlur={false}
					onSubmit={handleOnSubmit}
					initialValues={initialValues}
					subscription={{
						values: true,
						errors: true,
						pristine: true,
					}}
					render={({ handleSubmit, form, values }) => {
						return (
							<Form onSubmit={handleSubmit}>
								<DialogContent>
									<DialogContentText>
										<Trans id="ui.selectStateToFilter">
											Selecione um estado ou estado e cidade para
											filtrar a lista.
										</Trans>
									</DialogContentText>
									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Field
												fullWidth
												type="select"
												name="state"
												component="input"
												variant="outlined"
												label={t({
													id: 'ui.state',
													message: 'UF',
												})}
												autoComplete="off"
											>
												{props => <StateSelect {...props} />}
											</Field>
										</Grid>
										<Grid item xs={12}>
											<Field
												fullWidth
												type="select"
												name="city"
												component="input"
												variant="outlined"
												label={t({
													id: 'ui.city',
													message: 'Cidade',
												})}
												autoComplete="off"
											>
												{props => (
													<CitySelect
														stateId={
															values?.state?.length
																? values.state
																: undefined
														}
														{...props}
													/>
												)}
											</Field>
										</Grid>
									</Grid>
								</DialogContent>
								<DialogActions>
									<Button onClick={() => setShowFilter(false)}>
										<Trans id="ui.c.cancel">Cancelar</Trans>
									</Button>
									<Button
										onClick={() => {
											form.change('state', null);
											form.change('city', null);
										}}
									>
										<Trans id="ui.c.clean">Limpar</Trans>
									</Button>
									<Button color="primary" type="submit">
										<Trans id="ui.c.confirm">Confirmar</Trans>
									</Button>
								</DialogActions>
							</Form>
						);
					}}
				/>
			</Dialog>
		</>
	);
};

const UsersTable = () => {
	const { role } = useParams();
	const { navigate } = useNavigation();
	const { addSuccess, addError } = useStatus();
	const { removeItemFromCache } = useApolloCache();
	const [itemToRemove, setItemToRemove] = React.useState(null);
	const [customFilterVariables, setCustomFilterVariables] = React.useState(null);

	const { data: dataMe } = useQuery(QUERY_ME);
	const [removeItem, { loading }] = useMutation(MUTATION_REMOVE_USER);

	const showRemoveDialog = React.useCallback((event, user) => {
		setItemToRemove(user);
		event.stopPropagation();
	}, []);

	const handleOnRowClick = React.useCallback(
		id => {
			navigate(`/admin/usuario/${id}`);
		},
		[navigate]
	);

	const handleOnDismiss = React.useCallback(event => {
		setItemToRemove(null);
		event.stopPropagation();
	}, []);

	const handleRemove = React.useCallback(
		async (event, refetch) => {
			try {
				event.stopPropagation();

				await removeItem({
					variables: {
						id: itemToRemove.id,
					},
				});

				removeItemFromCache('User', itemToRemove.id);

				addSuccess(
					t({
						id: 'user.removeSuccess',
						message: `Usuário ${itemToRemove.firstName} removido com sucesso`,
					})
				);
				setItemToRemove(null);
			} catch (error) {
				event.stopPropagation();

				setItemToRemove(null);
				addError(processError(error));
				console.log('handleRemove error', error);
			}
		},
		[addError, addSuccess, itemToRemove, removeItem, removeItemFromCache]
	);

	const removeItsMe = dataMe.id === itemToRemove?.id;
	const removeMessage = removeItsMe
		? t({
				id: 'user.removeSelfError',
				message:
					'Você não pode remover seu acesso, solicite a outro administrador',
		  })
		: !itemToRemove
		? '...'
		: t({
				id: 'user.removeAskConfirmation',
				message: `Deseja realmente remover o usuário ${itemToRemove?.firstName}`,
		  });

	const theRole = role
		? ROLES_ARRAY.find(item => item.route.param === role)?.value
		: undefined;

	const theRoleLabel = theRole
		? ROLES_ARRAY.find(item => item.route.param === role)?.route?.label
		: null;

	const DATA_MAP = [
		{
			title: t({
				id: 'ui.name',
				message: 'Nome',
			}),
			fields: ['firstName', 'lastName'],
			formatter: ({ firstName, lastName }) => `${firstName} ${lastName}`,
		},
		{
			title: t({
				id: 'ui.profile',
				message: 'Perfil',
			}),
			fields: ['role'],
			formatter: ({ role }) => ROLES[role],
		},
		{
			title: t({
				id: 'ui.email',
				message: 'Email',
			}),
			fields: ['email'],
		},
		{
			title: t({
				id: 'ui.city',
				message: 'Cidade',
			}),
			fields: ['state', 'city'],
			formatter: ({ city, state }) =>
				city?.name ? `${city.name} - ${state.code}` : '-',
		},
	];

	return (
		<>
			{loading && <Loading fullScreen />}
			<ConfirmationDialog
				isOpen={!!itemToRemove}
				title={t({
					id: 'user.removeUser',
					message: 'Remover usuário',
				})}
				message={removeMessage}
				onConfirmation={!removeItsMe && handleRemove}
				onClose={handleOnDismiss}
			/>
			<StandardTable
				fieldKey="users"
				query={QUERY_USERS}
				dataMap={DATA_MAP}
				title={theRoleLabel}
				initialRowsPerPage={5}
				initialVariables={{
					...INITIAL_VARIABLES,
					role: theRole,
				}}
				customFilterComponent={
					<LocationFilter
						initialValues={customFilterVariables}
						isActive={!!customFilterVariables?.state}
						onChange={setCustomFilterVariables}
					/>
				}
				customFilterVariables={customFilterVariables}
				fetchPolicy="cache-and-network"
				onRowClick={handleOnRowClick}
				addRoute="/admin/usuario/adicionar"
				actions={({ item }) => (
					<IconButton
						size="small"
						onClick={event => showRemoveDialog(event, item)}
					>
						<DeleteIcon />
					</IconButton>
				)}
			/>
		</>
	);
};

export default React.memo(UsersTable);
