import config from 'root/config';

export const getItem = key =>
	new Promise(resolve => {
		resolve(localStorage.getItem(`${config.storagekey}${key}`));
	});

export const setItem = (key, value) =>
	new Promise(resolve =>
		resolve(localStorage.setItem(`${config.storagekey}${key}`, value))
	);

export const removeItem = key =>
	new Promise(resolve =>
		resolve(localStorage.removeItem(`${config.storagekey}${key}`))
	);

export const clear = () => new Promise(resolve => resolve(localStorage.clear()));
