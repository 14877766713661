import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useMutation } from '@apollo/client';
import { Field } from 'react-final-form';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

import FinalForm from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';
import { MUTATION_GENERATE_MAINTENANCE_CODE } from 'components/maintenance/graphql';
import { onlyNumbers } from 'utils/formatters';

import validationSchema from './validationSchema';
import { propTypes, defaultProps } from './propTypes';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Card = styled.div`
	display: flex;
	position: relative;
	flex-direction: row;
	width: 100%;
	border: solid 1px ${({ theme }) => theme.palette.primary.main};
	padding: 1rem 0.4rem;
	border-radius: ${p => p.theme.shape.borderRadius}px;
`;

const Icon = styled.div`
	width: 58px;
	height: 58px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background-color: ${({ theme }) => theme.palette.primary.main};
`;

const Title = styled(Typography)`
	font-weight: bolder;
	font-size: 0.9rem;
	color: #717171;
`;

const MaintenanceCodeText = styled(Typography)`
	font-weight: bolder;
	color: #000;
`;

const H6 = styled(Typography)`
	color: #adadad;
	font-size: 1rem;
	margin-bottom: 0.5rem;
`;

const RefreshContainer = styled.div`
	position: absolute;
	top: 0rem;
	right: 0rem;
`;

const INITIAL_VALUES = {
	id: '',
	lockCode: '',
};

const MaintenanceCode = ({ autoclave, ofCycles, code, onSuccess }) => {
	const [maintenanceCode, setMaintenanceCode] = React.useState(code);
	const [generateMaintenanceCode, { data, loading }] = useMutation(
		MUTATION_GENERATE_MAINTENANCE_CODE
	);

	React.useEffect(() => {
		let dataCode = data?.generateMaintenanceCode?.code;
		if (dataCode && !maintenanceCode) {
			setMaintenanceCode(dataCode);
			if (onSuccess) {
				onSuccess(dataCode);
			}
		}
	}, [data, maintenanceCode, onSuccess]);

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await generateMaintenanceCode({
					variables: {
						input: {
							...input,
							id: autoclave.id,
							ofCycles,
						},
					},
				});
			} catch (err) {
				console.log('handleOnSubmit error', err);
				throw err;
			}
		},
		[generateMaintenanceCode, autoclave.id, ofCycles]
	);

	return (
		<>
			{!maintenanceCode && (
				<Card>
					<Box px={2} py={1} flexDirection="column">
						<H6 variant="h6">
							<Trans id="maintenance.u.ofCycles">
								MANUTENÇÃO {ofCycles} CICLOS
							</Trans>
						</H6>
						<Typography variant="body2">
							<Trans id="maintenance.addCodeToProceed">
								Insira abaixo o código informado na Autoclave para gerar o
								código de desbloqueio de manutenção de {ofCycles} ciclos
								do equipamento.
							</Trans>
						</Typography>
						<FinalForm
							formName="maintenanceCode"
							validateOnBlur={false}
							onSubmit={handleOnSubmit}
							initialValues={INITIAL_VALUES}
							validationSchema={validationSchema()}
							subscription={{
								values: true,
								errors: true,
								pristine: true,
							}}
							render={({ handleSubmit, submitting, pristine, values }) => {
								return (
									<Form onSubmit={handleSubmit}>
										<Field
											type="text"
											name="lockCode"
											component="input"
											variant="outlined"
											required
											fullWidth
											label={t({
												id: 'ui.code',
												message: 'Código',
											})}
											autoComplete="off"
											maxLength={5}
											parse={onlyNumbers}
										>
											{props => <TextField {...props} />}
										</Field>
										<Box pt={2} display="flex">
											<Box pr={2} display="inline-flex">
												<SubmitButton
													type="submit"
													variant="contained"
													loadingColor="accent"
													color="primary"
													disabled={submitting}
													isFormPristine={pristine}
													isLoading={submitting || loading}
												>
													<Trans id="maintenance.generateCode">
														Gerar código
													</Trans>
												</SubmitButton>
											</Box>
										</Box>
									</Form>
								);
							}}
						/>
					</Box>
				</Card>
			)}
			{maintenanceCode && (
				<Card>
					<RefreshContainer>
						<IconButton onClick={() => setMaintenanceCode(null)}>
							<RefreshIcon color="primary" />
						</IconButton>
					</RefreshContainer>
					<Box px={2}>
						<Icon>
							<PlayCircleOutlineIcon />
						</Icon>
					</Box>
					<Box display="flex" flexDirection="column" justifyContent="center">
						<Title>
							<Trans id="maintenance.u.maintenanceCode">
								CÓDIGO DE MANUTENÇÃO
							</Trans>
						</Title>
						<MaintenanceCodeText variant="h4">
							{maintenanceCode}
						</MaintenanceCodeText>
					</Box>
				</Card>
			)}
		</>
	);
};

MaintenanceCode.propTypes = propTypes;

MaintenanceCode.defaultProps = defaultProps;

export default React.memo(MaintenanceCode);
