import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Logo from 'components/layout/LogoEvoxx';

const NotFoundPage = () => (
	<Container maxWidth="xs">
		<Box
			py={6}
			display="flex"
			flexGrow={1}
			flexDirection="column"
			alignItems="center"
		>
			<Logo size={120} textColor="#000" />
		</Box>

		<Box m={1} display="flex" flexDirection="column" alignItems="center">
			<Typography variant="h3" paragraph>
				<Trans id="ui.404">404 / Não encontrado</Trans>
			</Typography>
		</Box>
	</Container>
);

export default React.memo(NotFoundPage);
