import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useQuery, useMutation } from '@apollo/client';
import { Field } from 'react-final-form';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import FinalForm from 'components/form/Form';
import { useNavigation } from 'components/navigation/hooks';
import { MUTATION_SIGN_UP } from 'components/users/graphql';
import { QUERY_COUNTRIES } from 'components/countries/graphql';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';
import CountrySelect from 'components/countries/CountrySelect';
import StateSelect from 'components/states/StateSelect';
import CitySelect from 'components/cities/CitySelect';
import { mobilePhoneMaskBR, mobilePhoneMaskWorld, CPFMask, CNPJMask } from 'utils/masks';

import config from 'root/config';

import Grid from 'UI/Grid';

import validationSchema from './validationSchema';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Submit = styled(SubmitButton)`
	margin: ${props => props.theme.spacing(3, 0, 2)};
`;

const INITIAL_VALUES = {
	firstName: '',
	lastName: '',
	email: '',
	country: '',
	state: '',
	city: '',
	password: '',
	passwordConfirmation: '',
};

export const SignUpForm = () => {
	const { replace } = useNavigation();
	const { data: dataCountries } = useQuery(QUERY_COUNTRIES);
	const [signUp, { data, error, loading }] = useMutation(MUTATION_SIGN_UP);

	React.useEffect(() => {
		const res = data?.signUp;
		if (res && !error) {
			replace('/login');
		}
	}, [data, error, replace]);

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await signUp({
					variables: {
						input: {
							...input,
							appUrl: `${config.appUrl}/confirmar-email`,
						},
					},
				});
			} catch (error) {
				throw error;
			}
		},
		[signUp]
	);

	return (
		<FinalForm
			formName="signUp"
			onSubmit={handleOnSubmit}
			initialValues={INITIAL_VALUES}
			validationSchema={validationSchema()}
			validateOnBlur={false}
			mutators={{
				setCountryCode: (args, state, utils) => {
					let theCountry = dataCountries?.countries?.find(
						item => item.id === args[0]
					)?.code;
					utils.changeValue(state, 'countryCode', () => theCountry);
				},
			}}
			excludeOnSubmit={['passwordConfirmation', 'countryCode']}
			subscription={{ values: true, errors: true }}
			render={({ form, handleSubmit, submitting, pristine, values }) => {
				const selectedCountry = dataCountries?.countries?.find(
					item => item.id === values.country
				)?.code;

				return (
					<Form onSubmit={handleSubmit}>
						<Grid container spacing={1}>
							<Grid item xs={12} md={6}>
								<Field
									type="text"
									name="firstName"
									component="input"
									variant="outlined"
									required
									fullWidth
									label={t({
										id: 'ui.name',
										message: 'Nome',
									})}
									autoComplete="off"
								>
									{props => <TextField {...props} />}
								</Field>
							</Grid>
							<Grid item xs={12} md={6}>
								<Field
									type="text"
									name="lastName"
									component="input"
									variant="outlined"
									required
									fullWidth
									label={t({
										id: 'ui.lastName',
										message: 'Sobrenome',
									})}
									autoComplete="off"
								>
									{props => <TextField {...props} />}
								</Field>
							</Grid>
							<Grid item xs={12}>
								<Field
									type="email"
									name="email"
									component="input"
									variant="outlined"
									required
									fullWidth
									label={t({
										id: 'ui.email',
										message: 'Email',
									})}
									autoComplete="off"
									helperText={t({
										id: 'ui.email.usedLogin',
										message: 'O email é utilizado para fazer login',
									})}
								>
									{props => <TextField {...props} />}
								</Field>
							</Grid>
							{selectedCountry === 'BR' && (
								<Grid item xs={12} sm={6}>
									<Field
										required
										fullWidth
										type="text"
										name="phone"
										component="input"
										variant="outlined"
										margin="normal"
										label={t({
											id: 'ui.cellphone',
											message: 'Celular',
										})}
										placeholder="(00) 00000-0000"
										format={mobilePhoneMaskBR}
										autoComplete="off"
									>
										{props => <TextField {...props} />}
									</Field>
								</Grid>
							)}
							{selectedCountry !== 'BR' && (
								<Grid item xs={12}>
									<Field
										required
										fullWidth
										type="text"
										name="phone"
										component="input"
										variant="outlined"
										margin="normal"
										label={t({
											id: 'ui.cellphone',
											message: 'Celular',
										})}
										placeholder="000000"
										format={mobilePhoneMaskWorld}
										autoComplete="off"
									>
										{props => <TextField {...props} />}
									</Field>
								</Grid>
							)}
							{selectedCountry === 'BR' && (
								<Grid item xs={12} sm={6}>
									<Field
										required
										fullWidth
										type="text"
										name="cpf"
										component="input"
										variant="outlined"
										margin="normal"
										label={t({
											id: 'ui.cpf',
											message: 'CPF',
										})}
										placeholder={t({
											id: 'ui.cpf.placeholder',
											message: '000.000.000-00',
										})}
										format={CPFMask}
										autoComplete="off"
									>
										{props => <TextField {...props} />}
									</Field>
								</Grid>
							)}
							<Grid item xs={12}>
								<Field
									required
									fullWidth
									type="select"
									name="country"
									component="input"
									variant="outlined"
									label={t({
										id: 'ui.country',
										message: 'País',
									})}
									autoComplete="off"
									onChange={form.mutators.setCountryCode}
								>
									{props => <CountrySelect {...props} />}
								</Field>
							</Grid>
							{selectedCountry === 'BR' && (
								<>
									<Grid item xs={12} md={3}>
										<Field
											required
											fullWidth
											type="select"
											name="state"
											component="input"
											variant="outlined"
											label={t({
												id: 'ui.state',
												message: 'UF',
											})}
											autoComplete="off"
										>
											{props => <StateSelect {...props} />}
										</Field>
									</Grid>

									<Grid item xs={12} md={9}>
										<Field
											required
											fullWidth
											type="select"
											name="city"
											component="input"
											variant="outlined"
											label={t({
												id: 'ui.city',
												message: 'Cidade',
											})}
											autoComplete="off"
										>
											{props => (
												<CitySelect
													stateId={
														values.state?.length
															? values.state
															: undefined
													}
													{...props}
												/>
											)}
										</Field>
									</Grid>
									<Grid item xs={12} sm={6}>
										<Field
											fullWidth
											type="text"
											name="cnpj"
											component="input"
											variant="outlined"
											margin="normal"
											label={t({
												id: 'ui.cnpj',
												message: 'CNPJ',
											})}
											placeholder={t({
												id: 'ui.cnpj.placeholder',
												message: '00.000.000/0000-00',
											})}
											format={CNPJMask}
											autoComplete="off"
										>
											{props => <TextField {...props} />}
										</Field>
									</Grid>
								</>
							)}
							<Grid item xs={12} sm={selectedCountry === 'BR' ? 6 : 12}>
								<Field
									fullWidth
									type="text"
									name="companyName"
									component="input"
									variant="outlined"
									margin="normal"
									label={t({
										id: 'ui.companyName',
										message: 'Razão Social',
									})}
									autoComplete="off"
									disabled={!values.cnpj && selectedCountry === 'BR'}
								>
									{props => <TextField {...props} />}
								</Field>
							</Grid>
							<Grid item xs={12}>
								<Box pt={2}>
									<Typography variant="h6">
										<Trans id="ui.password">Senha</Trans>
									</Typography>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									type="password"
									name="password"
									component="input"
									variant="outlined"
									required
									fullWidth
									label={t({
										id: 'ui.password',
										message: 'Senha',
									})}
									autoComplete="new-password"
								>
									{props => <TextField {...props} />}
								</Field>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Field
									type="password"
									name="passwordConfirmation"
									component="input"
									variant="outlined"
									required
									fullWidth
									label={t({
										id: 'ui.passwordConfirmation',
										message: 'Confirmar senha',
									})}
									autoComplete="off"
									options={[{ label: '' }]}
								>
									{props => <TextField {...props} />}
								</Field>
							</Grid>
							<Grid item xs={12} sm="auto">
								<Submit
									type="submit"
									size="large"
									variant="contained"
									loadingColor="accent"
									color="primary"
									disabled={submitting}
									isFormPristine={pristine}
									isLoading={submitting || loading}
								>
									<Trans id="ui.save">Salvar</Trans>
								</Submit>
							</Grid>
						</Grid>
					</Form>
				);
			}}
		/>
	);
};

export default React.memo(SignUpForm);
