import React from 'react';
import { useTheme } from 'styled-components';
import { Trans } from '@lingui/macro';
import { useQuery, useMutation } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import config from 'root/config';
import { QUERY_ME } from 'components/users/graphql';
import { MUTATION_EMAIL_VALIDATION_REQUEST } from 'components/auth/graphql';

import Loading from 'UI/Loading';
import Button from 'UI/Button';

const ValidateEmail = () => {
	const theme = useTheme();
	const { data: dataMe } = useQuery(QUERY_ME);
	const [emailValidationRequest, { loading }] = useMutation(
		MUTATION_EMAIL_VALIDATION_REQUEST
	);
	const [emailSent, setEmailSent] = React.useState(false);

	const handleOnPress = React.useCallback(
		async () => {
			try {
				await emailValidationRequest({
					variables: {
						input: {
							email: dataMe?.me?.email,
							appUrl: `${config.appUrl}/confirmar-email`,
						},
					},
				});
				setEmailSent(true);
			} catch (error) {
				console.log(error);
			} finally {
				setEmailSent(true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[emailValidationRequest, dataMe]
	);

	if (dataMe?.me?.emailVerified) {
		return null;
	}

	return (
		<>
			<Box
				pt={1}
				pb={1}
				display="flex"
				flexDirection="row"
				alignItems="center"
				justifyContent="center"
				bgcolor="#c5c5c5"
			>
				<Typography variant="body2">
					<strong>
						<Trans id="auth.emailNotVerifiedAlert">
							O seu email não foi verificado! Clique no botão ao lado para
							receber um link de validação no seu email.
						</Trans>
					</strong>
				</Typography>

				<Box px={1}>
					{emailSent && (
						<Typography variant="body2">
							<strong>
								<Trans id="auth.emailSent">Email enviado!</Trans>
							</strong>
						</Typography>
					)}

					{!emailSent && (
						<Button
							fullWidth
							startIcon={
								loading && (
									<Loading color="white" fullScreen={false} size={12} />
								)
							}
							size="small"
							variant="contained"
							color="secondary"
							onClick={handleOnPress}
						>
							<Trans id="ui.resendEmail">Reenviar email</Trans>
						</Button>
					)}
				</Box>
			</Box>
		</>
	);
};

export default ValidateEmail;
