import { DateTime } from 'luxon';
import validator from 'validator';

export const PHONE_REGEX =
	/^(?:(?:\+|00)?(55)\s?)?(?:(?:\(?[1-9][0-9]\)?)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;

const LOCALES_OVER18 = {
	'pt-BR': {
		minAge: 18,
	},
};

const LOCALES_MOBILE_PHONE = {
	'pt-BR': PHONE_REGEX,
};

/**
 * isEmail
 * @export
 * @param {string} email
 * @returns {boolean}
 */
export function isEmail(email) {
	return validator.isEmail(email);
}

/**
 * isOver18
 * @export
 * @param {string} birthdate
 * @param {string} [locale='pt-BR']
 * @returns {boolean}
 */
export function isOver18(birthdate, locale = 'pt-BR') {
	try {
		if (!LOCALES_OVER18.hasOwnProperty(locale)) {
			throw new Error(`Locale not avaiable`);
		}

		const now = DateTime.local();
		const birth = DateTime.fromFormat(birthdate, LOCALES_OVER18[locale].format);

		const diff = now.diff(birth, ['years']);

		return diff.values.years >= LOCALES_OVER18[locale].minAge;
	} catch (error) {
		throw error;
	}
}

/**
 * isMobilePhone
 * @export
 * @param {(number | string)} phone
 * @param {string} [locale='pt-BR']
 * @returns {boolean}
 */
export function isMobilePhone(phone, locale = 'pt-BR') {
	if (!LOCALES_MOBILE_PHONE.hasOwnProperty(locale)) {
		throw new Error(`Locale not avaiable`);
	}

	const regx = LOCALES_MOBILE_PHONE[locale];

	return !!phone.toString().match(regx) || false;
}

/**
 * checkCPF
 * @export
 * @param {string} cpf
 * @returns {boolean}
 */
export function isCPF(cpf) {
	if (!cpf) {
		return false;
	}

	let _sum = 0;
	let _rest;

	let exp = /\.|\-/g;
	cpf = cpf.toString().replace(exp, '');

	if (cpf === '00000000000' || !cpf) {
		return false;
	}

	for (var i = 1; i <= 9; i++) {
		_sum = _sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
	}

	_rest = (_sum * 10) % 11;

	if (_rest === 10 || _rest === 11) {
		_rest = 0;
	}

	if (_rest !== parseInt(cpf.substring(9, 10))) {
		return false;
	}

	_sum = 0;

	for (var ii = 1; ii <= 10; ii++) {
		_sum = _sum + parseInt(cpf.substring(ii - 1, ii)) * (12 - ii);
	}

	_rest = (_sum * 10) % 11;

	if (_rest === 10 || _rest === 11) {
		_rest = 0;
	}

	if (_rest !== parseInt(cpf.substring(10, 11))) {
		return false;
	}

	return true;
}

/**
 * isCNPJ
 * @export
 * @param {string} cnpj
 * @return {boolean}
 */
export function isCNPJ(value) {
	if (!value) return false;

	// Aceita receber o valor como string, número ou array com todos os dígitos
	const isString = typeof value === 'string';
	const validTypes = isString || Number.isInteger(value) || Array.isArray(value);

	// Elimina valor em formato inválido
	if (!validTypes) return false;

	// Filtro inicial para entradas do tipo string
	if (isString) {
		// Limita ao máximo de 18 caracteres, para CNPJ formatado
		if (value.length > 18) return false;

		// Teste Regex para veificar se é uma string apenas dígitos válida
		const digitsOnly = /^\d{14}$/.test(value);
		// Teste Regex para verificar se é uma string formatada válida
		const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value);

		// Se o formato é válido, usa um truque para seguir o fluxo da validação
		if (digitsOnly || validFormat) return true;
		// Se não, retorna inválido
		else return false;
	}

	// Guarda um array com todos os dígitos do valor
	const match = value.toString().match(/\d/g);
	const numbers = Array.isArray(match) ? match.map(Number) : [];

	// Valida a quantidade de dígitos
	if (numbers.length !== 14) return false;

	// Elimina inválidos com todos os dígitos iguais
	const items = [...new Set(numbers)];
	if (items.length === 1) return false;

	// Cálculo validador
	const calc = x => {
		const slice = numbers.slice(0, x);
		let factor = x - 7;
		let sum = 0;

		for (let i = x; i >= 1; i--) {
			const n = slice[x - i];
			sum += n * factor--;
			if (factor < 2) factor = 9;
		}

		const result = 11 - (sum % 11);

		return result > 9 ? 0 : result;
	};

	// Separa os 2 últimos dígitos de verificadores
	const digits = numbers.slice(12);

	// Valida 1o. dígito verificador
	const digit0 = calc(12);
	if (digit0 !== digits[0]) return false;

	// Valida 2o. dígito verificador
	const digit1 = calc(13);
	return digit1 === digits[1];
}
