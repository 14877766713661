import React from 'react';
import { t } from '@lingui/macro';
import CategoryIcon from '@material-ui/icons/Category';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import DescriptionIcon from '@material-ui/icons/Description';

import MenuList from 'components/layout/MenuList';

export const MainMenu = () => {
	const MENU_PRIMARY = [
		{
			to: '/cliente/minhas-autoclaves',
			label: t({
				id: 'ui.myAutoclaves',
				message: 'Minhas Autoclaves',
			}),
			icon: <CategoryIcon />,
			showDivider: true,
		},
		{
			to: '/cliente/relatorios',
			label: t({
				id: 'ui.reports',
				message: 'Relatórios',
			}),
			icon: <DescriptionIcon />,
			showDivider: true,
		},
	];

	const MENU_LOGOUT = [
		{
			to: '/logout',
			label: t({
				id: 'ui.logout',
				message: 'Sair',
			}),
			icon: <ArrowBackIcon />,
		},
	];

	return (
		<>
			<MenuList items={MENU_PRIMARY} showDivider />
			<MenuList items={MENU_LOGOUT} />
		</>
	);
};

export default React.memo(MainMenu);
