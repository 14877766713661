import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ModelTable from 'components/models/ModelTable';

const ModelsPage = () => {
	return (
		<>
			<Container disableGutters>
				<Typography>
					<Trans id="models.added">Modelos cadastrados de Autoclaves.</Trans>
				</Typography>
				<Box pt={4}>
					<ModelTable />
				</Box>
			</Container>
		</>
	);
};

export default React.memo(ModelsPage);
