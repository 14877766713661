import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import MyAccountForm from 'components/users/MyAccountForm';

const MyAccountPage = () => (
	<>
		<Container disableGutters>
			<Typography>
				<Trans id="account.editYourData">Edite os dados da sua conta.</Trans>
			</Typography>
		</Container>
		<Container maxWidth="sm" disableGutters>
			<Box pt={4} display="flex" flexDirection="column">
				<MyAccountForm />
			</Box>
		</Container>
	</>
);

export default React.memo(MyAccountPage);
