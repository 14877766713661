import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { QUERY_REPORTS } from 'components/reports/graphql';

import Button from 'UI/Button';
import Loading from 'UI/Loading';

import Item from './Item';

const ReportsList = ({ autoclaveId, listType }) => {
	const [currentPage, setCurrentPage] = React.useState(0);

	const { loading, data, refetch } = useQuery(QUERY_REPORTS, {
		variables: {
			listType,
			autoclaveId,
			page: currentPage,
		},
		fetchPolicy: 'cache-and-network',
	});

	const handleLoadmore = React.useCallback(
		event => {
			const toPage = parseInt(currentPage + 1);

			refetch({
				listType,
				autoclaveId,
				page: currentPage,
			});

			setCurrentPage(toPage);
		},
		[currentPage, refetch, autoclaveId, listType]
	);

	const reports = data?.reports?.edges || [];
	const hasNextPage = data?.reports?.pageInfo?.hasNextPage;

	return (
		<>
			{loading && <Loading fullScreen />}
			{!loading && !reports.length && (
				<Typography>
					<Trans id="reports.noItemsFound">
						Não há relatórios registrados.
					</Trans>
				</Typography>
			)}
			{reports.map(item => (
				<Item key={item.id} {...item}>
					list
				</Item>
			))}
			{hasNextPage && (
				<Box display="flex" pt={6} justifyContent="center">
					<Button
						size="large"
						variant="outlined"
						color="primary"
						onClick={handleLoadmore}
					>
						<Trans id="ui.loadMore">Carregar mais</Trans>
					</Button>
				</Box>
			)}
		</>
	);
};

ReportsList.propTypes = {
	autoclaveId: PropTypes.string,
	listType: PropTypes.oneOf(['ALL', 'USER', undefined]),
};

ReportsList.defaultProps = {
	autoclaveId: undefined,
	listType: undefined,
};

export default ReportsList;
