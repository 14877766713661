import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import AutoclaveMaintenance from 'components/maintenance/AutoclaveMaintenance';

const AutoclavePage = () => (
	<Container disableGutters>
		<Grid container component="main">
			<Grid item xs={12} sm={8} md={9}>
				<Typography paragraph>
					<Trans id="maintenance.enterSerialToStart">
						Informe o número de série para ter acesso aos detalhes da
						Autoclave EVOXX e iniciar a manutenção.
					</Trans>
				</Typography>
			</Grid>
		</Grid>

		<Box pt={4}>
			<AutoclaveMaintenance />
		</Box>
	</Container>
);

export default React.memo(AutoclavePage);
