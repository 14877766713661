import * as storage from 'components/localStorage';
import { apolloClient } from 'components/apollo/ApolloProvider';
// import { QUERY_REFRESH_TOKEN } from 'components/auth/graphql';

export const setToken = async token => {
	try {
		await storage.setItem('token', token);
	} catch (error) {
		console.log('setToken error', error);
	}
};

export const logout = async () => {
	try {
		await storage.removeItem('token');
		await apolloClient.resetStore();
	} catch (error) {
		console.log('logout error', error);
	}
};

// export const getRefreshToken = () => {
// 	return apolloClient.query({ query: QUERY_REFRESH_TOKEN })
// 		.then((response) => {
// 			const { accessToken } = response.data
// 			setToken(accessToken)
// 			return accessToken
// 		})
// }
