import { gql, InMemoryCache } from '@apollo/client';
import pageLimitPagination from 'components/apollo/pageLimitPagination';

import statusGraphql from 'components/status/graphql';

const QUERY_ROOT = gql`
	query {
		${statusGraphql.ROOT}
	}
`;

const store = () => ({
	...statusGraphql.defaultReducer,
});

const resolvers = () => ({
	Query: {
		...statusGraphql.resolvers.Query,
	},
	Mutation: {
		...statusGraphql.resolvers.Mutation,
	},
});

const typeDefs = () => gql`
	${statusGraphql.typeDefs}
`;

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				users: pageLimitPagination(['limit', 'searchText', 'state', 'city'], {
					concat: false,
				}),
				autoclaves: pageLimitPagination(['limit', 'searchText'], {
					concat: false,
				}),
				models: pageLimitPagination(['limit', 'searchText'], { concat: false }),
				reports: pageLimitPagination(
					['autoclaveId', 'listType', 'searchText', 'startDate', 'endDate'],
					{
						concat: false,
					}
				),
				maintenances: pageLimitPagination(['limit', 'searchText', 'alertType'], {
					concat: false,
				}),
			},
		},
	},
});

const resetCache = async client => {
	try {
		await client.cache.reset();
		client.writeQuery({
			query: QUERY_ROOT,
			data: store(),
		});
	} catch (err) {
		throw err;
	}
};

export { store, resolvers, typeDefs, cache, resetCache };
