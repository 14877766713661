import { t } from '@lingui/macro';

export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const BLOCKED = 'BLOCKED';
export const NOT_FOUND = 'NOT_FOUND';
export const VALIDATION_ERROR = 'VALIDATION_ERROR';
export const IN_USE = 'IN_USE';
export const EMAIL_IN_USE = 'EMAIL_IN_USE';
export const CPF_IN_USE = 'CPF_IN_USE';
export const UNVERIFIED_EMAIL = 'UNVERIFIED_EMAIL';
export const LINK_EXPIRED = 'LINK_EXPIRED';
export const ALREADY_EXISTS = 'ALREADY_EXISTS';
export const GENERAL_ERROR = 'GENERAL_ERROR';
export const NOT_ACTIVATED = 'NOT_ACTIVATED';
export const ALREADY_ACTIVATED_OWNER = 'ALREADY_ACTIVATED_OWNER';
export const INVALID_FILE = 'INVALID_FILE';

export const errorType = {
	UNAUTHENTICATED: {
		type: UNAUTHENTICATED,
		message: t({
			id: 'error.c.unauthenticated',
			message: 'Não autenticado',
		}),
		statusCode: 401,
	},
	UNAUTHORIZED: {
		type: UNAUTHORIZED,
		message: t({
			id: 'error.c.notAuthorized',
			message: 'Não autorizado',
		}),
		statusCode: 403,
	},
	BLOCKED: {
		type: BLOCKED,
		message: t({
			id: 'error.c.blocked',
			message: 'Bloqueado',
		}),
		statusCode: 403,
	},
	NOT_FOUND: {
		type: NOT_FOUND,
		message: t({
			id: 'error.c.notFound',
			message: 'Não encontrado',
		}),
		statusCode: 404,
	},
	VALIDATION_ERROR: {
		type: VALIDATION_ERROR,
		message: t({
			id: 'error.c.validationErrors',
			message: 'Erros de validação',
		}),
		statusCode: 422,
	},
	LINK_EXPIRED: {
		type: LINK_EXPIRED,
		message: t({
			id: 'error.c.linkExpired',
			message: 'Link expirou',
		}),
		statusCode: 422,
	},
	EMAIL_IN_USE: {
		type: EMAIL_IN_USE,
		message: t({
			id: 'error.c.emailInUse',
			message: 'Email já em uso',
		}),
		statusCode: 422,
	},
	CPF_IN_USE: {
		type: CPF_IN_USE,
		message: t({
			id: 'error.c.cpfInUse',
			message: 'CPF já em uso',
		}),
		statusCode: 422,
	},
	IN_USE: {
		type: IN_USE,
		message: t({
			id: 'error.c.inUse',
			message: 'Em uso',
		}),
		statusCode: 422,
	},
	ALREADY_EXISTS: {
		type: ALREADY_EXISTS,
		message: t({
			id: 'error.c.alreadyExists',
			message: 'Este item já existe',
		}),
		statusCode: 422,
	},
	NOT_ACTIVATED: {
		type: NOT_ACTIVATED,
		message: t({
			id: 'error.c.notActivated',
			message: 'Esta autoclave não foi ativada',
		}),
		statusCode: 422,
	},
	ALREADY_ACTIVATED_OWNER: {
		type: ALREADY_ACTIVATED_OWNER,
		message: t({
			id: 'error.c.alreadyActivatedOwner',
			message: 'Autoclave ativa ou possui um proprietário',
		}),
		statusCode: 422,
	},
	UNVERIFIED_EMAIL: {
		type: UNVERIFIED_EMAIL,
		message: t({
			id: 'error.c.unverifiedEmail',
			message: 'Email não validado, siga os passos enviados por email',
		}),
		statusCode: 422,
	},
	INVALID_FILE: {
		type: INVALID_FILE,
		message: t({
			id: 'error.c.invalidFile',
			message: 'Arquivo inválido',
		}),
		statusCode: 422,
	},
	GENERAL_ERROR: {
		type: GENERAL_ERROR,
		message: t({
			id: 'error.c.generalError',
			message: 'Algo deu errado',
		}),
		statusCode: 500,
	},
};

const processError = errors => {
	const { graphQLErrors } = errors;
	const firstError = graphQLErrors?.[0]?.type || 'GENERAL_ERROR';

	const error = errorType[firstError].message;
	console.log('error', error);
	return error;
};

export default processError;
