import React from 'react';
import { Trans, t } from '@lingui/macro';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { useQuery } from '@apollo/client';
import { useDebouncedCallback } from 'use-debounce';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TablePagination from '@material-ui/core/TablePagination';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import TodayIcon from '@material-ui/icons/Today';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';

import { useNavigation } from 'components/navigation/hooks';
import TableHeader from 'components/table/TableHeader';
import TableBody from 'components/table/TableBody';

import { luxonToISODate } from 'utils/date';
import usePrevious from 'hooks/usePrevious';

import Grid from 'UI/Grid';
import Button from 'UI/Button';
import Loading from 'UI/Loading';
import TextField from 'UI/TextField';
import DatePicker from 'UI/DatePicker';

const PaperStyled = styled(Paper)`
	width: 100%;
`;

const StandardTable = ({
	query,
	title,
	fieldKey,
	dataMap,
	initialRowsPerPage,
	initialVariables,
	fetchPolicy,
	onRowClick,
	addRoute,
	actions: Actions,
	showDateFilter,
	customFilterComponent,
	customFilterVariables,
}) => {
	const { navigate } = useNavigation();
	const prevCustomFilterVariables = usePrevious(JSON.stringify(customFilterVariables));

	const [searchText, setSearchText] = React.useState('');
	const [currentPage, setCurrentPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
	const [showDate, setShowDate] = React.useState(false);
	const [startDate, setStartDate] = React.useState(null);
	const [endDate, setEndDate] = React.useState(null);

	const theStartDate = startDate ? luxonToISODate(startDate) : undefined;
	const theEndDate = endDate ? luxonToISODate(endDate) : undefined;

	const { loading, data, refetch } = useQuery(query, {
		variables: {
			...initialVariables,
		},
		fetchPolicy,
	});

	const handleLabelDisplayRows = React.useCallback(
		({ from, to, count }) =>
			`${from}-${to} ${
				count !== -1
					? t({
							id: 'ui.ofN',
							message: `de ${count}`,
					  })
					: t({
							id: 'ui.moreThanN',
							message: `mais de ${to}`,
					  })
			}`,
		[]
	);

	const handleChangePage = React.useCallback(
		(event, page) => {
			const toPage = parseInt(page, 10);

			refetch({
				page: toPage,
				limit: rowsPerPage,
				searchText: searchText.length ? searchText : undefined,
				startDate: theStartDate,
				endDate: theEndDate,
				...(customFilterVariables && Object.keys(customFilterVariables).length
					? customFilterVariables
					: {}),
			});

			setCurrentPage(toPage);
		},
		[
			customFilterVariables,
			refetch,
			rowsPerPage,
			searchText,
			theEndDate,
			theStartDate,
		]
	);

	const handleChangeRowsPerPage = React.useCallback(
		event => {
			const limit = parseInt(event.target.value, 10);

			refetch({
				limit,
				page: 0,
				searchText: searchText.length ? searchText : undefined,
				startDate: theStartDate,
				endDate: theEndDate,
				...(customFilterVariables && Object.keys(customFilterVariables).length
					? customFilterVariables
					: {}),
			});

			setRowsPerPage(limit);
		},
		[customFilterVariables, refetch, searchText, theEndDate, theStartDate]
	);

	const handleCustomFilter = React.useCallback(
		event => {
			refetch({
				limit: rowsPerPage,
				page: 0,
				searchText: searchText.length ? searchText : undefined,
				startDate: theStartDate,
				endDate: theEndDate,
				...(customFilterVariables && Object.keys(customFilterVariables).length
					? customFilterVariables
					: {}),
			});
		},
		[
			customFilterVariables,
			refetch,
			rowsPerPage,
			searchText,
			theEndDate,
			theStartDate,
		]
	);

	const debounceSearch = useDebouncedCallback(value => {
		refetch({
			limit: rowsPerPage,
			page: 0,
			searchText: value.length ? value : undefined,
			startDate: theStartDate,
			endDate: theEndDate,
			...(customFilterVariables && Object.keys(customFilterVariables).length
				? customFilterVariables
				: {}),
		});

		setSearchText(value);
	}, 1000);

	const handleCleanSearchText = React.useCallback(() => {
		setSearchText('');
		debounceSearch.callback('');
	}, [debounceSearch]);

	const handleCleanStartDate = React.useCallback(e => {
		e.stopPropagation();
		setStartDate(null);
		setEndDate(null);
	}, []);

	const handleCleanEndDate = React.useCallback(e => {
		e.stopPropagation();
		setEndDate(null);
	}, []);

	const handleSearch = React.useCallback(
		event => {
			const { value } = event.target;
			setSearchText(value);
			debounceSearch.callback(value);
		},
		[debounceSearch]
	);

	const handleDateFilter = React.useCallback(() => {
		setShowDate(false);
		debounceSearch.callback(searchText);
	}, [debounceSearch, searchText]);

	const handleStartDateChange = React.useCallback(selectDate => {
		if (selectDate) {
			setStartDate(selectDate);
		} else {
			setStartDate(null);
			setEndDate(null);
		}
	}, []);

	const handleEndDateChange = React.useCallback(selectDate => {
		if (selectDate) {
			setEndDate(selectDate);
		} else {
			setEndDate(null);
		}
	}, []);

	const handleOnClick = React.useCallback(
		(id, item) => {
			onRowClick(id, item);
		},
		[onRowClick]
	);

	React.useEffect(() => {
		if (
			prevCustomFilterVariables &&
			prevCustomFilterVariables !== JSON.stringify(customFilterVariables)
		) {
			handleCustomFilter();
		}
	}, [customFilterVariables, handleCustomFilter, prevCustomFilterVariables]);

	const edges = data?.[fieldKey]?.edges || [];
	const count = data?.[fieldKey]?.totalCount || 0;

	const dateRangeActive = startDate || endDate ? 'primary' : undefined;

	return (
		<>
			{loading && <Loading fullScreen />}
			<Dialog
				open={showDate}
				// onClose={handleClose}
			>
				<DialogTitle>
					<Trans id="ui.filterDate">Filtrar por data</Trans>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Trans id="ui.filterDate.selectDate">
							Selecione uma data para filtrar os itens.
						</Trans>
					</DialogContentText>
					<Box pt={2} maxWidth={320}>
						<Grid
							container
							spacing={1}
							justify="space-between"
							alignItems="center"
						>
							<Grid item xs={12}>
								<Box py={1}>
									<DatePicker
										fullWidth
										clearable
										disableFuture
										name="startDate"
										maxDate={endDate || undefined}
										inputVariant="outlined"
										label={t({
											id: 'ui.date',
											message: 'Data',
										})}
										value={startDate}
										format="dd/MM/yyyy"
										onChange={handleStartDateChange}
										cancelLabel={t({
											id: 'ui.cancel',
											message: 'Cancelar',
										})}
										clearLabel={t({
											id: 'ui.clean',
											message: 'Limpar',
										})}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<TodayIcon />
												</InputAdornment>
											),
											endAdornment: startDate && (
												<InputAdornment position="end">
													<IconButton
														size="small"
														onClick={handleCleanStartDate}
													>
														<CloseIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</Box>
							</Grid>
							<Grid item xs={12}>
								<Box py={1}>
									<DatePicker
										fullWidth
										clearable
										disableFuture
										name="endDate"
										minDate={startDate || undefined}
										inputVariant="outlined"
										label={t({
											id: 'ui.endDate',
											message: 'Data Final',
										})}
										value={endDate}
										format="dd/MM/yyyy"
										onChange={handleEndDateChange}
										cancelLabel={t({
											id: 'ui.cancel',
											message: 'Cancelar',
										})}
										clearLabel={t({
											id: 'ui.clean',
											message: 'Limpar',
										})}
										disabled={!startDate}
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													<InsertInvitationIcon />
												</InputAdornment>
											),
											endAdornment: endDate && (
												<InputAdornment position="end">
													<IconButton
														size="small"
														onClick={handleCleanEndDate}
													>
														<CloseIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									<DialogContentText>
										<small>
											<Trans id="ui.endDate.isOptional">
												A data final é opcional.
											</Trans>
										</small>
									</DialogContentText>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowDate(false)}>
						<Trans id="ui.cancel">Cancelar</Trans>
					</Button>
					<Button onClick={handleDateFilter} color="primary">
						<Trans id="ui.c.confirm">Confirmar</Trans>
					</Button>
				</DialogActions>
			</Dialog>

			<Box m={1} display="flex" flexDirection="column">
				<Box px={1} py={3} justifyContent="flex-end" display="flex">
					<Grid
						container
						spacing={1}
						justify="space-between"
						alignItems="center"
					>
						<Grid item xs={12} sm="auto">
							{addRoute && (
								<Box py={2}>
									<Button
										size="large"
										color="primary"
										variant="outlined"
										onClick={() => navigate(addRoute)}
									>
										<Trans id="ui.add">Adicionar</Trans>
									</Button>
								</Box>
							)}
						</Grid>
						<Grid item xs={12} sm="auto">
							<Box flexDirection="row" width="100%" display="flex">
								<TextField
									name="searchText"
									label={t({
										id: 'ui.search',
										message: 'Buscar',
									})}
									type="text"
									value={searchText}
									variant="outlined"
									onChange={event => handleSearch(event)}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<SearchIcon />
											</InputAdornment>
										),
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													size="small"
													onClick={handleCleanSearchText}
													disabled={!searchText}
												>
													<CloseIcon />
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
								{showDateFilter && (
									<IconButton onClick={() => setShowDate(!showDate)}>
										<DateRangeIcon color={dateRangeActive} />
									</IconButton>
								)}
								{customFilterComponent}
							</Box>
						</Grid>
					</Grid>
				</Box>

				<PaperStyled>
					<Table aria-label="table">
						<TableHeader
							title={title}
							dataMap={dataMap}
							hasActions={!!Actions}
						/>
						<TableBody
							data={edges}
							dataMap={dataMap}
							actions={Actions}
							onRowClick={handleOnClick}
						/>
					</Table>

					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 50, 100]}
						component="div"
						count={count}
						page={currentPage}
						rowsPerPage={rowsPerPage}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
						labelRowsPerPage={t({
							id: 'ui.itemPerPage',
							message: 'Itens por página:',
						})}
						nextIconButtonText={t({
							id: 'ui.nextPage',
							message: 'Próxima página',
						})}
						backIconButtonText={t({
							id: 'ui.previousPage',
							message: 'Página anterior',
						})}
						labelDisplayedRows={handleLabelDisplayRows}
					/>
				</PaperStyled>
			</Box>
		</>
	);
};

StandardTable.propTypes = {
	query: PropTypes.any.isRequired,
	fieldKey: PropTypes.string.isRequired,
	dataMap: PropTypes.array.isRequired,
	initialRowsPerPage: PropTypes.number,
	initialVariables: PropTypes.object,
	customFilterComponent: PropTypes.element,
	customFilterVariables: PropTypes.object,
	fetchPolicy: PropTypes.oneOf([
		'cache-limit',
		'network-only',
		'cache-only',
		'no-cache',
		'standby',
		'cache-and-network',
	]),
	onRowClick: PropTypes.func,
	title: PropTypes.string,
	showDateFilter: PropTypes.bool,
};

StandardTable.defaultProps = {
	initialRowsPerPage: 5,
	initialVariables: null,
	customFilterComponent: null,
	customFilterVariables: {},
	fetchPolicy: 'cache-and-network',
	onRowClick: () => null,
	title: null,
	showDateFilter: false,
};

export default React.memo(StandardTable);
