import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'UI/TextField';

export const TextInputField = ({
	input,
	meta: { touched, error, submitError, submitFailed },
	placeholder,
	autoComplete,
	required,
	helperText,
	disabled,
	readOnly,
	autoFocus,
	preferOnBlur,
	displayError,
	onBlur,
	onChange,
	id,
	type = 'text',
	secureTextEntry,
	className,
	maxLength,
	...rest
}) => {
	const [localValue, setValue] = React.useState(input.value);

	const handleChange = React.useCallback(
		e => {
			const val = typeof e === 'string' ? e : e.currentTarget.value;
			if (preferOnBlur) {
				setValue(val);
			} else {
				input.onChange(val);
				onChange(val);
			}
		},
		[setValue, preferOnBlur, input, onChange]
	);

	const handleBlur = React.useCallback(
		e => {
			onBlur(e);
			input.onBlur(e);
			if (preferOnBlur) {
				input.onChange(localValue);
				onChange(localValue);
			}
		},
		[onBlur, input, preferOnBlur, localValue, onChange]
	);

	const theError = error || submitError;
	const { onChange: ignore, ...restInput } = input;
	const theValue = preferOnBlur ? localValue : input.value;

	return (
		<TextField
			{...rest}
			value={theValue}
			id={id}
			inputProps={{
				...restInput,
				maxLength,
			}}
			required={required}
			placeholder={placeholder}
			onBlur={handleBlur}
			onChange={handleChange}
			type={type}
			autoFocus={autoFocus}
			autoComplete={autoComplete}
			disabled={disabled}
			fullWidth
			margin="normal"
			readOnly={readOnly}
			error={!!((touched || submitFailed || displayError) && theError)}
			helperText={((touched || submitFailed) && theError) || helperText}
			className={className}
		/>
	);
};

TextInputField.propTypes = {
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	required: PropTypes.bool,
	autoFocus: PropTypes.bool,
	type: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
};

TextInputField.defaultProps = {
	disabled: false,
	readOnly: false,
	required: false,
	autoFocus: false,
	type: 'text',
	onChange: () => undefined,
	onBlur: () => undefined,
};

export default React.memo(TextInputField);
