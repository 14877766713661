import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CyclesAlertTable from 'components/maintenance/CyclesAlertTable';

const CyclesAlertPage = props => (
	<Container disableGutters>
		<Grid container component="main">
			<Grid item xs={12} sm={8} md={9}>
				<Typography paragraph>
					<Trans id="alert.autoclavesReaching">
						Autoclaves com alerta de proximidade de 300 e 600 ciclos, ou +600
						ciclos sem ter feito manutenção.
					</Trans>
				</Typography>
			</Grid>
		</Grid>

		<Box pt={4}>
			<CyclesAlertTable />
		</Box>
	</Container>
);

export default React.memo(CyclesAlertPage);
