import React from 'react';
import { Route } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';

import { QUERY_ME } from 'components/users/graphql';
import * as storage from 'components/localStorage';
import { useNavigation } from 'components/navigation/hooks';
import { dynamicActivate } from 'components/intl/i18n';

import Loading from 'UI/Loading';

const PrivateRoute = ({ allowRole, children, ...rest }) => {
	const { i18n } = useLingui();
	const { replace } = useNavigation();
	const [me, { loading, error, data }] = useLazyQuery(QUERY_ME);

	const getMe = React.useCallback(async () => {
		try {
			const hasToken = await storage.getItem('token');
			if (!hasToken) {
				replace('/login');
			} else {
				me();
			}
		} catch (err) {
			console.log('getMe err', err);
		}
	}, [me, replace]);

	const checkLanguage = React.useCallback(
		async userLanguage => {
			try {
				const localLanguage = await storage.getItem('language');

				if (!localLanguage || localLanguage !== userLanguage) {
					await storage.setItem('language', userLanguage);
				}

				if (i18n.locale !== userLanguage) {
					dynamicActivate(userLanguage);
				}
			} catch (err) {
				console.log('checkLanguage err', err);
			}
		},
		[i18n.locale]
	);

	React.useEffect(() => {
		getMe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		if (!loading && (error || data?.me)) {
			const userRole = data?.me?.role;
			const userLanguage = data?.me?.preferences?.language;

			if (userRole && allowRole && !allowRole.includes(userRole)) {
				replace('/404');
			}

			checkLanguage(userLanguage);
		}
	}, [allowRole, checkLanguage, data, error, loading, replace]);

	if (loading || error || !data?.me) {
		return (
			<Route {...rest}>
				<Loading fullScreen />
			</Route>
		);
	}

	return <Route {...rest}>{children}</Route>;
};

export default React.memo(PrivateRoute);
