import { useCallback } from 'react';
import { parse } from 'query-string';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { buildUrl } from 'components/navigation/functions';

// Helper functions

const checkedTo = to => (!to.startsWith('/') ? `/${to}` : to);

// Hooks

const useNavigation = () => {
	const { push, replace: historyReplace, goBack } = useHistory();

	const navigate = useCallback(
		(to, qsParams = {}) => {
			const path = buildUrl(checkedTo(to), qsParams);
			push(path);
		},
		[push]
	);

	const replace = useCallback(
		(to, qsParams = {}) => {
			const path = buildUrl(checkedTo(to), qsParams);
			historyReplace(path);
		},
		[historyReplace]
	);

	const setOptions = useCallback(() => {}, []);

	return {
		navigate,
		replace,
		goBack,
		setOptions,
	};
};

const useRoute = () => {
	const { pathname, search, state } = useLocation();
	const searchParams = parse(search);
	const routeParams = useParams();
	const params = {
		...searchParams,
		...routeParams,
	};
	const route = {
		search,
		path: pathname,
		params: params || {},
		state,
	};

	return route;
};

const useIsFocused = () => {
	const { pathname } = useLocation();
	return !!useRouteMatch(pathname);
};

const useNavigationState = () => {
	const { pathname, search } = useLocation();
	const searchParams = parse(search);
	const routeParams = useParams();
	const params = {
		...searchParams,
		...routeParams,
	};
	const { push, replace: historyReplace, goBack } = useHistory();

	const navigate = useCallback(
		(to, qsParams = {}) => {
			const path = buildUrl(checkedTo(to), qsParams);
			push(path);
			window.scrollTo(0, 0);
		},
		[push]
	);

	const replace = useCallback(
		(to, qsParams = {}) => {
			const path = buildUrl(checkedTo(to), qsParams);
			historyReplace(path);
			window.scrollTo(0, 0);
		},
		[historyReplace]
	);

	return {
		navigate,
		replace,
		goBack,
		path: pathname,
		params: params || {},
		focused: !!useRouteMatch(pathname),
	};
};

export { useNavigation, useRoute, useIsFocused, useNavigationState };
