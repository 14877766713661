import React from 'react';

import SelectField from 'components/form/SelectField';
import { LOCALES } from 'components/intl/i18n';

const LanguageField = ({ disabled, onChange, ...rest }) => {
	const [options, setOptions] = React.useState([]);

	React.useEffect(() => {
		let theOptions = [];

		Object.entries(LOCALES).forEach(([key, value]) =>
			theOptions.push({
				label: value,
				value: key,
			})
		);

		setOptions(theOptions);
	}, []);

	const handleOnChange = value => {
		if (onChange) {
			onChange(value);
		}
	};

	return (
		<SelectField
			{...rest}
			options={options}
			disabled={disabled}
			onChange={handleOnChange}
		/>
	);
};

// LanguageField.propTypes = propTypes;

// LanguageField.defaultProps = defaultProps;

export default React.memo(LanguageField);
