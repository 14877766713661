import React from 'react';
import { useMutation } from '@apollo/client';

import { MUTATION_SET_STATUS, MUTATION_RESET_STATUS } from 'components/status/graphql';
import {
	INFO,
	ERROR,
	SUCCESS,
	WARNING,
	VALIDATION_ERROR,
} from 'components/status/config';

export const statusTypes = {
	INFO,
	ERROR,
	SUCCESS,
	WARNING,
	VALIDATION_ERROR,
};

const useStatus = () => {
	const [setStatus] = useMutation(MUTATION_SET_STATUS);
	const [resetStatus] = useMutation(MUTATION_RESET_STATUS);

	const addStatus = React.useCallback(
		item => {
			const variables = {
				input: {
					type: item.type,
					message: item.message || null,
					// action: item.action || null,
					// autoHideDuration: item.autoHideDuration || null,
				},
			};
			return setStatus({
				variables,
			});
		},
		[setStatus]
	);

	const addInfo = React.useCallback(
		message => {
			addStatus({
				type: INFO,
				message,
				// action: item.action,
				// autoHideDuration: item.autoHideDuration,
			});
		},
		[addStatus]
	);

	const addWarning = React.useCallback(
		message => {
			addStatus({
				type: WARNING,
				message,
				// action: item.action,
				// autoHideDuration: item.autoHideDuration,
			});
		},
		[addStatus]
	);

	const addError = React.useCallback(
		message => {
			addStatus({
				type: ERROR,
				message,
				// action: item.action,
				// autoHideDuration: item.autoHideDuration,
			});
		},
		[addStatus]
	);

	const addSuccess = React.useCallback(
		message => {
			addStatus({
				type: SUCCESS,
				message,
				// action: item && item.action,
				// autoHideDuration: item && item.autoHideDuration,
			});
		},
		[addStatus]
	);

	const clearStatus = React.useCallback(
		types => resetStatus({ variables: { input: { types } } }),
		[resetStatus]
	);

	return {
		addInfo,
		addError,
		addSuccess,
		addWarning,
		clearStatus,
	};
};

export default useStatus;
