import gql from 'graphql-tag';

export const MODEL_FRAGMENT = gql`
	fragment modelFragment on Model {
		id
		name
		description
	}
`;

export const QUERY_MODEL = gql`
	query Model($id: ID!) {
		model(id: $id) {
			...modelFragment
		}
	}
	${MODEL_FRAGMENT}
`;

export const QUERY_MODELS = gql`
	query Models($limit: Int = 5, $page: Int = 0, $searchText: String) {
		models(limit: $limit, page: $page, searchText: $searchText) {
			edges {
				id
				name
				description
			}
			totalCount
		}
	}
`;

export const MUTATION_CREATE_MODEL = gql`
	mutation CreateModel($input: ModelInput!) {
		createModel(input: $input) {
			...modelFragment
		}
	}
	${MODEL_FRAGMENT}
`;

export const MUTATION_UPDATE_MODEL = gql`
	mutation UpdateModel($input: ModelInput!) {
		updateModel(input: $input) {
			...modelFragment
		}
	}
	${MODEL_FRAGMENT}
`;

export const MUTATION_REMOVE_MODEL = gql`
	mutation RemoveModel($id: ID!) {
		removeModel(id: $id) {
			...modelFragment
		}
	}
	${MODEL_FRAGMENT}
`;
