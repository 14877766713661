import { object, string, ref } from 'yup';
import { t } from '@lingui/macro';

import { PHONE_REGEX, isCPF, isCNPJ } from 'utils/validators';

const validationSchema = () => {
	const required = t({
		id: 'validation.required',
		message: 'Requirido',
	});

	const invalidNumber = t({
		id: 'validation.invalidNumber',
		message: 'Número inválido',
	});

	const cpfInvalid = t({
		id: 'validation.cpfInvalid',
		message: 'CPF inválido',
	});

	const cnpjInvalid = t({
		id: 'validation.cnpjInvalid',
		message: 'CNPJ inválido',
	});

	const informCompanyName = t({
		id: 'validation.informCompanyName',
		message: 'Informe a Razão Social',
	});

	const passwordMustMatch = t({
		id: 'validation.passwordMustMatch',
		message: 'As senhas devem corresponder',
	});

	return object().shape({
		firstName: string().nullable().required(required),
		lastName: string().nullable().required(required),
		email: string().nullable().required(required),
		country: string().nullable().required(required),
		countryCode: string().nullable(),
		city: string()
			.nullable()
			.when('countryCode', {
				is: 'BR',
				then: string().required(required),
			}),
		state: string()
			.nullable()
			.when('countryCode', {
				is: 'BR',
				then: string().required(required),
			}),
		phone: string()
			.nullable()
			.when('countryCode', {
				is: 'BR',
				then: string().matches(PHONE_REGEX, invalidNumber).required(required),
			}),
		cpf: string()
			.nullable()
			.when('countryCode', {
				is: 'BR',
				then: string()
					.test('CPF Test', cpfInvalid, value => isCPF(value))
					.required(required),
			}),
		cnpj: string()
			.nullable()
			.test('CNPJ Test', cnpjInvalid, value =>
				value?.length > 0 ? isCNPJ(value) : true
			),
		companyName: string()
			.nullable()
			.when('cnpj', {
				is: cnpj => cnpj?.length > 0,
				then: string().required(informCompanyName),
			}),
		password: string().oneOf([ref('passwordConfirmation'), null], passwordMustMatch),
		passwordConfirmation: string().oneOf([ref('password'), null], passwordMustMatch),
	});
};

export default validationSchema;
