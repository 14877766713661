import React from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useNavigation } from 'components/navigation/hooks';

import autoclaveImage from 'assets/images/autoclave.png';

const PaperStyled = styled(Paper)`
	cursor: pointer;
`;

const Image = styled.img`
	width: 100%;
	height: auto;
`;

const SerialNumber = styled(Typography)`
	color: #adadad;
	font-size: 1.2rem;
	strong {
		color: ${p => p.theme.palette.primary.main};
	}
`;

const Model = styled(Typography)`
	font-size: 1rem;
`;

const AutoclaveItem = ({ autoclave }) => {
	const { serialNumber } = autoclave;

	const { navigate } = useNavigation();

	const modelName = autoclave?.model?.name;

	return (
		<PaperStyled
			onClick={() => navigate(`/cliente/autoclave/${autoclave.id}`)}
			elevation={6}
		>
			<Box p={4}>
				<Box
					pb={4}
					display="flex"
					alignItems="center"
					justifyContent="center"
					height="100%"
				>
					<Image src={autoclaveImage} alt="Autoclave EVOXX" />
				</Box>

				<Box pb={0}>
					<SerialNumber>
						<Trans id="ui.abbr.serialNumber">S/N</Trans>{' '}
						<strong>{serialNumber}</strong>
					</SerialNumber>
					<Model>
						<Trans id="ui.model">Modelo</Trans> <strong>{modelName}</strong>
					</Model>
				</Box>
			</Box>
		</PaperStyled>
	);
};

export default React.memo(AutoclaveItem);
