import React from 'react';
import { useApolloClient } from '@apollo/client';

const useApolloCache = () => {
	const client = useApolloClient();

	const removeItemFromCache = React.useCallback(
		(typename, id) => {
			client.cache.evict(`${typename}:${id}`);
			client.cache.gc();

			return;
		},
		[client.cache]
	);

	return {
		removeItemFromCache,
	};
};

export default useApolloCache;
