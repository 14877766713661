import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider as StyleThemeProvider } from 'styled-components';

import theme from 'styles/theme';

const TheThemeProvider = ({ children }) => (
	<ThemeProvider theme={theme}>
		<StyleThemeProvider theme={theme}>
			<CssBaseline />
			{children}
		</StyleThemeProvider>
	</ThemeProvider>
);

export default TheThemeProvider;
