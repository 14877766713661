import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { Field } from 'react-final-form';

import FinalForm from 'components/form/Form';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import Grid from 'UI/Grid';
import Loading from 'UI/Loading';

import { propTypes, defaultProps } from './propTypes';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Submit = styled(SubmitButton)`
	margin: ${props => props.theme.spacing(3, 0, 2)};
`;

const ModelForm = ({
	excludeOnSubmit,
	onSubmit,
	validationSchema,
	initialValues,
	loading,
}) => {
	return (
		<>
			{loading && <Loading fullScreen />}
			<FinalForm
				formName="model"
				validateOnBlur={false}
				onSubmit={onSubmit}
				initialValues={initialValues}
				excludeOnSubmit={excludeOnSubmit}
				validationSchema={validationSchema()}
				subscription={{ values: true, errors: true, pristine: true }}
				render={({ handleSubmit, submitting, pristine }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Field
										type="text"
										name="name"
										component="input"
										variant="outlined"
										required
										fullWidth
										label={t({
											id: 'ui.modelName',
											message: 'Nome do modelo',
										})}
										autoComplete="off"
									>
										{props => <TextField {...props} />}
									</Field>
								</Grid>
								<Grid item xs={12}>
									<Field
										type="text"
										name="description"
										component="input"
										variant="outlined"
										fullWidth
										label={t({
											id: 'ui.description',
											message: 'Descrição',
										})}
										autoComplete="off"
									>
										{props => <TextField {...props} />}
									</Field>
								</Grid>
								<Grid item xs={12} sm="auto">
									<Submit
										type="submit"
										size="large"
										variant="contained"
										loadingColor="accent"
										color="primary"
										disabled={submitting}
										isFormPristine={pristine}
										isLoading={submitting || loading}
									>
										<Trans id="ui.save">Salvar</Trans>
									</Submit>
								</Grid>
							</Grid>
						</Form>
					);
				}}
			/>
		</>
	);
};

ModelForm.propTypes = propTypes;

ModelForm.defaultProps = defaultProps;

export default React.memo(ModelForm);
