import React from 'react';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { useQuery, useMutation } from '@apollo/client';

import MaintenanceInfo from 'root/components/maintenance/MaintenanceInfo';
import GenerateUnlockCode from 'components/autoclaves/GenerateUnlockCode';
import AutoclaveForm from 'components/autoclaves/AutoclaveForm';
import AutoclaveOwner from 'components/autoclaves/AutoclaveOwner';
import { useNavigation } from 'components/navigation/hooks';
import {
	QUERY_AUTOCLAVE,
	MUTATION_UPDATE_AUTOCLAVE,
} from 'components/autoclaves/graphql';

import Loading from 'UI/Loading';

import validationSchema from './validationSchema';

const EXCLUDE_ON_SUBMIT = ['__typename', 'unlock', 'totalCycles'];

const UpdateAutoclaveForm = ({ autoclaveId }) => {
	const { navigate } = useNavigation();
	const [updateAutoclave, { loading }] = useMutation(MUTATION_UPDATE_AUTOCLAVE);
	const { loading: loadingAutoclave, data: dataAutoclave } = useQuery(QUERY_AUTOCLAVE, {
		variables: {
			id: autoclaveId,
		},
	});

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				const theInput = {
					...input,
					userId: input.userId || null,
				};

				await updateAutoclave({
					variables: {
						input: theInput,
					},
				});
				navigate('/admin/autoclaves');
			} catch (err) {
				console.log('handleOnSubmit error', err);
				throw err;
			}
		},
		[updateAutoclave, navigate]
	);

	if (!dataAutoclave || loadingAutoclave) {
		return <Loading fullScreen />;
	}

	const initialValues = {
		...dataAutoclave.autoclave,
		model: dataAutoclave.autoclave.model.id,
		userId: dataAutoclave.autoclave.userId?.id,
	};

	const ownerLabel = dataAutoclave.autoclave.userId
		? `${dataAutoclave.autoclave.userId.firstName} ${dataAutoclave.autoclave.userId.lastName}`
		: '';

	return (
		<>
			<Box mb={2}>
				<AutoclaveOwner
					user={dataAutoclave?.autoclave?.userId}
					modelName={dataAutoclave?.autoclave?.model?.name}
					serialNumber={dataAutoclave?.autoclave?.serialNumber}
				/>
			</Box>

			{dataAutoclave?.autoclave?.id && (
				<Box mt={1} mb={4} px={0.5} width="100%">
					<Paper elevation={6}>
						<Box p={3}>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="center"
								height="100%"
							>
								<GenerateUnlockCode
									autoclave={dataAutoclave?.autoclave}
								/>
							</Box>
						</Box>
					</Paper>
				</Box>
			)}

			<AutoclaveForm
				isUpdate
				loading={loading}
				ownerLabel={ownerLabel}
				initialValues={initialValues}
				onSubmit={handleOnSubmit}
				validationSchema={validationSchema}
				excludeOnSubmit={EXCLUDE_ON_SUBMIT}
			/>

			{dataAutoclave?.autoclave?.serialNumber && (
				<MaintenanceInfo serialNumber={dataAutoclave?.autoclave.serialNumber} />
			)}
		</>
	);
};

export default React.memo(UpdateAutoclaveForm);
