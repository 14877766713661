import gql from 'graphql-tag';

export const AUTOCLAVE_FRAGMENT = gql`
	fragment autoclaveFragment on Autoclave {
		id
		serialNumber
		model {
			id
			name
		}
		unlock {
			code
			unlockedAt
		}
		userId {
			id
			firstName
			lastName
			email
		}
		totalCycles
	}
`;

export const QUERY_AUTOCLAVE = gql`
	query Autoclave($id: ID!) {
		autoclave(id: $id) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;

export const QUERY_AUTOCLAVE_BY_SERIALNUMBER = gql`
	query autoclaveBySerialNumber($serialNumber: String!) {
		autoclaveBySerialNumber(serialNumber: $serialNumber) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;

export const QUERY_AUTOCLAVES = gql`
	query Autoclaves($limit: Int = 5, $page: Int = 0, $searchText: String) {
		autoclaves(limit: $limit, page: $page, searchText: $searchText) {
			edges {
				id
				serialNumber
				totalCycles
				userId {
					firstName
					lastName
					email
				}
				model {
					id
					name
				}
			}
			totalCount
		}
	}
`;

export const MUTATION_CREATE_AUTOCLAVE = gql`
	mutation CreateAutoclave($input: AutoclaveInput!) {
		createAutoclave(input: $input) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;

export const MUTATION_UPDATE_AUTOCLAVE = gql`
	mutation UpdateAutoclave($input: AutoclaveInput!) {
		updateAutoclave(input: $input) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;

export const MUTATION_REMOVE_AUTOCLAVE = gql`
	mutation RemoveAutoclave($id: ID!) {
		removeAutoclave(id: $id) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;

export const MUTATION_IMPORT_AUTOCLAVE = gql`
	mutation ImportAutoclave($input: AutoclaveImportInput!) {
		importAutoclave(input: $input) {
			created
			alreadyExisted
			total
			itemsTotal
			invalidTotal
			duplicatedTotal
		}
	}
`;

export const MUTATION_ADD_MY_AUTOCLAVE = gql`
	mutation AddMyAutoclave($serialNumber: String!) {
		addMyAutoclave(serialNumber: $serialNumber) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;

export const MUTATION_REMOVE_MY_AUTOCLAVE = gql`
	mutation RemoveMyAutoclave($id: ID!) {
		removeMyAutoclave(id: $id) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;

export const MUTATION_GENERATE_UNLOCK_CODE = gql`
	mutation GenerateUnlockCode($input: GenerateUnlockCodeInput!) {
		generateUnlockCode(input: $input) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;

export const QUERY_MY_AUTOCLAVES = gql`
	query MyAutoclaves {
		myAutoclaves {
			edges {
				id
				serialNumber
				model {
					id
					name
				}
			}
			totalCount
		}
	}
`;

export const QUERY_MY_AUTOCLAVE = gql`
	query MyAutoclave($id: ID!) {
		myAutoclave(id: $id) {
			...autoclaveFragment
		}
	}
	${AUTOCLAVE_FRAGMENT}
`;
