import React from 'react';
import { t } from '@lingui/macro';
import { useMutation } from '@apollo/client';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import {
	QUERY_AUTOCLAVES,
	MUTATION_REMOVE_AUTOCLAVE,
} from 'components/autoclaves/graphql';

import StandardTable from 'components/table/StandardTable';
import { useNavigation } from 'components/navigation/hooks';
import useApolloCache from 'components/apollo/hooks';
import useStatus from 'components/status/hooks';

import processError from 'utils/errors';

import Loading from 'UI/Loading';
import ConfirmationDialog from 'UI/ConfirmationDialog';

const INITIAL_VARIABLES = {
	page: 0,
	limit: 5,
};

const AutoclavesTable = () => {
	const { navigate } = useNavigation();
	const { addSuccess, addError } = useStatus();
	const { removeItemFromCache } = useApolloCache();
	const [itemToRemove, setItemToRemove] = React.useState(null);

	const [removeItem, { loading }] = useMutation(MUTATION_REMOVE_AUTOCLAVE);

	const showRemoveDialog = React.useCallback((event, autoclave) => {
		setItemToRemove(autoclave);
		event.stopPropagation();
	}, []);

	const handleOnRowClick = React.useCallback(
		id => {
			navigate(`/admin/autoclave/${id}`);
		},
		[navigate]
	);

	const handleOnDismiss = React.useCallback(event => {
		setItemToRemove(null);
		event.stopPropagation();
	}, []);

	const handleRemove = React.useCallback(
		async (event, refetch) => {
			try {
				event.stopPropagation();

				await removeItem({
					variables: {
						id: itemToRemove.id,
					},
				});

				removeItemFromCache('Autoclave', itemToRemove.id);

				addSuccess(
					t({
						id: 'autoclave.removedSuccess',
						message: `Autoclave ${itemToRemove.serialNumber} removido com sucesso`,
					})
				);
				setItemToRemove(null);
			} catch (error) {
				event.stopPropagation();

				console.log('handleRemove error', error);
				setItemToRemove(null);
				addError(processError(error));
			}
		},
		[addError, addSuccess, itemToRemove, removeItem, removeItemFromCache]
	);

	const DATA_MAP = [
		{
			title: t({
				id: 'ui.serialNumber',
				message: 'Número de série',
			}),
			fields: ['serialNumber'],
		},
		{
			title: t({
				id: 'ui.model',
				message: 'Modelo',
			}),
			fields: ['model.name'],
		},
		{
			title: t({
				id: 'ui.cycles',
				message: 'Ciclos',
			}),
			fields: ['totalCycles'],
		},
		{
			title: t({
				id: 'ui.owners',
				message: 'Proprietário',
			}),
			fields: ['userId'],
			formatter: ({ userId }) => {
				if (!userId?.email) return '-';

				const handleClick = e => e.stopPropagation();

				return (
					<a href={`mailto:${userId.email}`} onClick={handleClick}>
						{userId.firstName} {userId.lastName}
					</a>
				);
			},
		},
	];

	return (
		<>
			{loading && <Loading fullScreen />}
			<ConfirmationDialog
				isOpen={!!itemToRemove}
				title={t({
					id: 'autoclave.remove',
					message: 'Remover Autoclave',
				})}
				message={t({
					id: 'autoclave.remove.askTo',
					message: `Deseja realmente remover a Autoclave ${itemToRemove?.serialNumber}`,
				})}
				onConfirmation={handleRemove}
				onClose={handleOnDismiss}
			/>
			<StandardTable
				fieldKey="autoclaves"
				query={QUERY_AUTOCLAVES}
				dataMap={DATA_MAP}
				initialRowsPerPage={5}
				initialVariables={INITIAL_VARIABLES}
				fetchPolicy="cache-and-network"
				onRowClick={handleOnRowClick}
				addRoute="/admin/autoclaves/adicionar"
				actions={({ item }) => {
					return (
						<>
							<IconButton
								size="small"
								onClick={event => showRemoveDialog(event, item)}
							>
								<DeleteIcon />
							</IconButton>
						</>
					);
				}}
			/>
		</>
	);
};

export default React.memo(AutoclavesTable);
