import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components/macro';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const FormControlStyled = styled(FormControl)`
	margin-top: 1rem;
	margin-bottom: 0.5rem;
`;

const useStyles = makeStyles(theme => ({
	menuPaper: {
		maxHeight: 310,
	},
}));

const TheSelect = props => {
	const {
		id,
		label,
		helperText,
		options,
		variant,
		required,
		fullWidth,
		disabled,
		error,
		...rest
	} = props;

	const classes = useStyles();

	return (
		<FormControlStyled
			error={error}
			variant={variant}
			disabled={disabled}
			required={required}
			fullWidth={fullWidth}
		>
			<InputLabel htmlFor={id}>{label}</InputLabel>
			<Select
				id={id}
				label={label}
				variant={variant}
				MenuProps={{ classes: { paper: classes.menuPaper } }}
				{...rest}
			>
				{options.map((item, idx) => (
					<MenuItem key={idx} value={item.value}>
						{item.label}
					</MenuItem>
				))}
			</Select>
			{helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControlStyled>
	);
};

export default React.memo(TheSelect);
