import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ImportReportForm from 'components/autoclaves/ImportReportForm';

const ImportAutoclavesPage = () => (
	<>
		<Container disableGutters>
			<Typography align="left" paragraph>
				<Trans id="report.selectFile.your">
					Selecione o arquivo .txt do cartão de memória da sua Autoclave EVOXX
					para importar e criar o relatório de manutenção.
				</Trans>
			</Typography>
			<Typography align="left" paragraph>
				<Trans id="report.selectFile.canMaxFiles">
					Você pode enviar 20 arquivos por vez.
				</Trans>
			</Typography>
		</Container>
		<Container maxWidth="sm" disableGutters>
			<Box pt={4}>
				<ImportReportForm />
			</Box>
		</Container>
	</>
);

export default React.memo(ImportAutoclavesPage);
