import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = props => {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const TheSnackbar = ({
	type,
	message,
	forwardedRef,
	anchorOrigin,
	autoHideDuration,
	...rest
}) => (
	<Snackbar
		{...rest}
		ref={forwardedRef}
		anchorOrigin={anchorOrigin}
		autoHideDuration={autoHideDuration}
	>
		<Alert {...rest} severity={type}>
			{message}
		</Alert>
	</Snackbar>
);

export default React.forwardRef((props, ref) => (
	<TheSnackbar {...props} forwardedRef={ref} />
));
