import React from 'react';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';

import SelectField from 'components/form/SelectField';

import { QUERY_COUNTRIES } from 'components/countries/graphql';

import Loading from 'UI/Loading';

const LoadingSmall = () => (
	<Box pr={1}>
		<Loading size={18} />
	</Box>
);

const addItemFirst = (array, key, value) => {
	const item = array.find(item => item[key] === value);

	return [item, ...array];
};

const CountrySelect = ({ disabled, language, ...rest }) => {
	const { loading, data } = useQuery(QUERY_COUNTRIES);
	const [options, setOptions] = React.useState([]);

	React.useEffect(() => {
		if (!loading && data && !options.length) {
			const theCountries = addItemFirst(data.countries, 'code', 'BR');

			setOptions(
				theCountries.map(item => {
					const userLanguage = language || 'pt';
					const theName =
						item.translations.find(item => item.language === userLanguage)
							?.name || '--';

					return {
						label: `${item.code} - ${theName} `,
						value: item.id,
					};
				})
			);
		}
	}, [loading, data, options, language]);

	return (
		<SelectField
			{...rest}
			options={options}
			disabled={loading || disabled}
			IconComponent={loading ? LoadingSmall : undefined}
		/>
	);
};

// CountrySelect.propTypes = propTypes;

// CountrySelect.defaultProps = defaultProps;

export default React.memo(CountrySelect);
