import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import ReportTable from 'components/reports/ReportTable';

const ReportsPage = () => (
	<Container disableGutters>
		<Typography>
			<Trans id="report.sentByYou">Relatórios enviados por você.</Trans>
		</Typography>
		<Box pt={4}>
			<ReportTable addRoute="/tecnico/relatorio/importar" />
		</Box>
	</Container>
);

export default React.memo(ReportsPage);
