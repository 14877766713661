import React from 'react';
import { t } from '@lingui/macro';
import { useParams } from 'react-router-dom';

import { QUERY_MAINTENANCES } from 'components/maintenance/graphql';

import StandardTable from 'components/table/StandardTable';
import { useNavigation } from 'components/navigation/hooks';

const optAlertTypes = {
	300: 'THREE_HUNDRED',
	600: 'SIX_HUNDRED',
};

const INITIAL_VARIABLES = {
	page: 0,
	limit: 5,
	alertType: 'ALL',
};

const CyclesAlertTable = () => {
	const { alertType } = useParams();
	const { navigate } = useNavigation();

	const handleOnRowClick = React.useCallback(
		(id, item) => {
			navigate(`/admin/manutencao/${item.autoclave.serialNumber}`);
		},
		[navigate]
	);

	const DATA_MAP = [
		{
			title: t({
				id: 'ui.type',
				message: 'Tipo',
			}),
			fields: ['ofCycles', 'totalCycles'],
			formatter: ({ ofCycles, totalCycles }) => {
				if (totalCycles < 600) {
					return 300;
				}

				return ofCycles;
			},
		},
		{
			title: t({
				id: 'ui.autoclave',
				message: 'Autoclave',
			}),
			fields: ['autoclave.serialNumber'],
		},
		{
			title: t({
				id: 'ui.cycles',
				message: 'Ciclos',
			}),
			fields: ['totalCycles'],
		},
		{
			title: t({
				id: 'ui.owner',
				message: 'Proprietário',
			}),
			fields: ['autoclave'],
			formatter: ({ autoclave }) => {
				if (!autoclave?.userId?.email) return '-';

				const handleClick = e => e.stopPropagation();

				return (
					<a href={`mailto:${autoclave.userId.email}`} onClick={handleClick}>
						{autoclave.userId.firstName} {autoclave.userId.lastName}
					</a>
				);
			},
		},
	];

	return (
		<StandardTable
			fieldKey="maintenances"
			query={QUERY_MAINTENANCES}
			dataMap={DATA_MAP}
			initialRowsPerPage={5}
			initialVariables={{
				...INITIAL_VARIABLES,
				alertType: optAlertTypes[alertType] || 'ALL',
			}}
			fetchPolicy="cache-and-network"
			onRowClick={handleOnRowClick}
		/>
	);
};

export default React.memo(CyclesAlertTable);
