import React from 'react';
import { Trans } from '@lingui/macro';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { QUERY_MY_AUTOCLAVE } from 'components/autoclaves/graphql';

import AutoclaveItem from 'components/autoclaves/MyAutoclave/Item';
import ReportsList from 'components/reports/ReportsList';

import Loading from 'UI/Loading';

const MyAutoclave = ({ id }) => {
	const { loading, data } = useQuery(QUERY_MY_AUTOCLAVE, {
		variables: { id },
	});

	const myAutoclave = data?.myAutoclave;

	return (
		<>
			{loading && <Loading fullScreen />}
			{myAutoclave && (
				<>
					<AutoclaveItem autoclave={myAutoclave} />
					<Box pt={8}>
						<Box pb={4}>
							<Typography variant="h6">
								<Trans id="ui.reports">Relatórios</Trans>
							</Typography>
						</Box>
						<ReportsList autoclaveId={id} />
					</Box>
				</>
			)}
		</>
	);
};

export default React.memo(MyAutoclave);
