import React from 'react';
import PropTypes from 'prop-types';

import Button from 'UI/Button';
import Loading from 'UI/Loading';

const SubmitButton = ({
	isLoading,
	loadingColor,
	children,
	isFormPristine,
	onClick,
	...rest
}) => {
	const handleOnClick = e => {
		if (isLoading) {
			e.preventDefault();
			return;
		}
		// onClick()
	};

	return (
		<Button
			type="submit"
			startIcon={
				isLoading && <Loading color="secondary" fullScreen={false} size={20} />
			}
			onClick={handleOnClick}
			{...rest}
		>
			{children}
		</Button>
	);
};

SubmitButton.propTypes = {
	isLoading: PropTypes.bool,
	loadingColor: PropTypes.string,
	isFormPristine: PropTypes.bool,
	onClick: PropTypes.func,
};

SubmitButton.defaultProps = {
	isLoading: false,
	loadingColor: 'accent',
	isFormPristine: false,
	onClick: () => null,
};

export default React.memo(SubmitButton);
