import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useMutation, useQuery } from '@apollo/client';

import {
	QUERY_MY_AUTOCLAVES,
	MUTATION_REMOVE_MY_AUTOCLAVE,
} from 'components/autoclaves/graphql';

import { useNavigation } from 'components/navigation/hooks';
import useApolloCache from 'components/apollo/hooks';
import useStatus from 'components/status/hooks';
import AutoclaveItem from 'components/autoclaves/MyAutoclavesList/Item';

import processError from 'utils/errors';

import Grid from 'UI/Grid';
import Loading from 'UI/Loading';
import ConfirmationDialog from 'UI/ConfirmationDialog';

import plusIcon from 'UI/Icons/plusIcon.svg';

const AddItem = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	padding: 3rem;
	height: 100%;
`;

const AddItemButton = styled.div`
	cursor: pointer;
	width: 80%;
	height: 0;
	padding-bottom: 80%;
	border: solid 1px #96a5c3;
	border-radius: ${p => p.theme.shape.borderRadius}px;
	background: url(${plusIcon}) center center no-repeat;

	&:hover {
		opacity: 0.6;
	}
`;

const AutoclavesList = () => {
	const { navigate } = useNavigation();
	const { addSuccess, addError } = useStatus();
	const { removeItemFromCache } = useApolloCache();
	const [itemToRemove, setItemToRemove] = React.useState(null);

	const [removeItem, { loading: loadingRemove }] = useMutation(
		MUTATION_REMOVE_MY_AUTOCLAVE
	);
	const { loading, data } = useQuery(QUERY_MY_AUTOCLAVES, {
		fetchPolicy: 'cache-and-network',
	});

	const handleOnDismiss = React.useCallback(event => {
		setItemToRemove(null);
		event.stopPropagation();
	}, []);

	const handleRemove = React.useCallback(
		async (event, refetch) => {
			try {
				event.stopPropagation();

				await removeItem({
					variables: {
						id: itemToRemove.id,
					},
				});

				removeItemFromCache('Autoclave', itemToRemove.id);

				addSuccess(
					t({
						id: 'autoclave.removedSuccess',
						message: `Autoclave ${itemToRemove.serialNumber} removido com sucesso`,
					})
				);
				setItemToRemove(null);
			} catch (error) {
				event.stopPropagation();

				setItemToRemove(null);
				addError(processError(error));
			}
		},
		[addError, addSuccess, itemToRemove, removeItem, removeItemFromCache]
	);

	const myAutoclaves = data?.myAutoclaves?.edges || [];

	return (
		<>
			{(loading || loadingRemove) && <Loading fullScreen />}
			<ConfirmationDialog
				isOpen={!!itemToRemove}
				title={t({
					id: 'autoclave.remove',
					message: 'Remover Autoclave',
				})}
				message={t({
					id: 'autoclave.remove.askTo',
					message: `Deseja realmente remover a Autoclave ${itemToRemove?.serialNumber}`,
				})}
				onConfirmation={handleRemove}
				onClose={handleOnDismiss}
			/>
			<Grid container spacing={1}>
				{myAutoclaves.map(item => (
					<Grid key={item.id} item xs={12} sm={6} md={4} lg={4}>
						<AutoclaveItem autoclave={item} />
					</Grid>
				))}
				<Grid item xs={12} sm={6} md={4} lg={4}>
					<AddItem>
						<AddItemButton
							onClick={() => navigate('/cliente/adicionar-autoclave')}
						/>
					</AddItem>
				</Grid>
			</Grid>
		</>
	);
};

export default React.memo(AutoclavesList);
