/* eslint-disable no-loop-func */
import React from 'react';
import {
	ApolloProvider,
	ApolloClient,
	// HttpLink,
} from '@apollo/client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

import { resolvers, typeDefs, cache } from 'components/apollo/store';
// import { logout } from 'components/auth/actions'
import { UNAUTHENTICATED } from 'utils/errors';
import browserHistory from 'router/browserHistory';

import config from 'root/config';

let apolloClient;

/**
 * Error link
 */
const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
	return new Observable(async observer => {
		try {
			if (graphQLErrors) {
				for (let err of graphQLErrors) {
					// eslint-disable-next-line no-unused-vars
					const { message, locations, path, extensions, statusCode, type } =
						err;

					console.log('err', extensions);

					if (type === UNAUTHENTICATED) {
						// await logout();
						browserHistory.replace('/logout');
						break;
					}
				}

				graphQLErrors.map(({ message, locations, path, type }) =>
					console.log(
						`[GraphQL error]: type: ${type}, Message: ${message}, Location: ${JSON.stringify(
							locations
						)}, Path: ${path}`
					)
				);
			}

			if (networkError) {
				console.log(`[Network error]: ${networkError}`);
			}

			const subscriber = {
				next: observer.next.bind(observer),
				error: observer.error.bind(observer),
				complete: observer.complete.bind(observer),
			};

			return forward(operation).subscribe(subscriber);
		} catch (error) {
			console.log(error);
		}
	});
});

/**
 * Auth link
 */
const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = localStorage.getItem(`${config.storagekey}token`);
	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	};
});

/**
 * Upload link
 */
const uploadLink = new createUploadLink({
	uri: config.api,
	credentials: 'same-origin', // same-origin / include / omit
});

/**
 * Client
 */
apolloClient = new ApolloClient({
	typeDefs: typeDefs(),
	resolvers: resolvers(),
	link: ApolloLink.from([errorLink, authLink, uploadLink]),
	cache,
	connectToDevTools: process.env.NODE_ENV !== 'production',
});

/**
 * Provider
 */
const Provider = ({ children }) => {
	return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export { Provider as default, apolloClient };
