import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useMutation, useLazyQuery } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { useNavigation } from 'components/navigation/hooks';
import {
	MUTATION_REMOVE_MY_AUTOCLAVE,
	QUERY_MY_AUTOCLAVES,
} from 'components/autoclaves/graphql';

import useApolloCache from 'components/apollo/hooks';
import useStatus from 'components/status/hooks';
import GenerateUnlockCode from 'components/autoclaves/GenerateUnlockCode';

import processError from 'utils/errors';

import Grid from 'UI/Grid';
import Loading from 'UI/Loading';
import ConfirmationDialog from 'UI/ConfirmationDialog';

import autoclaveImage from 'assets/images/autoclave.png';

const Image = styled.img`
	width: 100%;
	max-width: 360px;
	height: auto;
`;

const SerialNumber = styled(Typography)`
	color: #adadad;
	font-size: 1.2rem;
	strong {
		color: ${p => p.theme.palette.primary.main};
	}
`;

const Model = styled(Typography)`
	font-size: 1rem;
`;

const ButtonStyled = styled(Button)`
	color: ${p => p.theme.palette.error.main};
	border-color: ${p => p.theme.palette.error.main};
`;

const AutoclaveItem = ({ autoclave }) => {
	const { serialNumber } = autoclave;

	const { navigate } = useNavigation();
	const { addSuccess, addError } = useStatus();
	const { removeItemFromCache } = useApolloCache();
	const [itemToRemove, setItemToRemove] = React.useState(null);

	const [removeItem, { loading: loadingRemove }] = useMutation(
		MUTATION_REMOVE_MY_AUTOCLAVE
	);
	const [myAutoclaves] = useLazyQuery(QUERY_MY_AUTOCLAVES);

	const handleOnDismiss = React.useCallback(event => {
		setItemToRemove(null);
		event.stopPropagation();
	}, []);

	const handleRemove = React.useCallback(
		async (event, refetch) => {
			try {
				event.stopPropagation();

				await removeItem({
					variables: {
						id: itemToRemove.id,
					},
				});

				removeItemFromCache('Autoclave', itemToRemove.id);

				addSuccess(
					t({
						id: 'autoclave.removedSuccess',
						message: `Autoclave ${itemToRemove.serialNumber} removido com sucesso`,
					})
				);
				setItemToRemove(null);

				await myAutoclaves();

				navigate('/cliente/minhas-autoclaves');
			} catch (error) {
				event.stopPropagation();

				console.log('handleRemove error', error);
				setItemToRemove(null);
				addError(processError(error));
			}
		},
		[
			addError,
			addSuccess,
			itemToRemove,
			myAutoclaves,
			navigate,
			removeItem,
			removeItemFromCache,
		]
	);

	const modelName = autoclave?.model?.name;

	return (
		<>
			{loadingRemove && <Loading fullScreen />}
			<ConfirmationDialog
				isOpen={!!itemToRemove}
				title={t({
					id: 'autoclave.remove',
					message: 'Remover Autoclave',
				})}
				message={t({
					id: 'autoclave.remove.askTo',
					message: `Deseja realmente remover a Autoclave ${itemToRemove?.serialNumber}`,
				})}
				onConfirmation={handleRemove}
				onClose={handleOnDismiss}
			/>
			<Paper elevation={6}>
				<Box p={3}>
					<Grid container>
						<Grid item xs={12} sm={6}>
							<Box
								py={3}
								pr={4}
								display="flex"
								alignItems="center"
								justifyContent="center"
								height="100%"
							>
								<Image src={autoclaveImage} alt="Autoclave EVOXX" />
							</Box>
						</Grid>

						<Grid item xs={12} sm={6}>
							<Box
								display="flex"
								flexDirection="column"
								justifyContent="center"
								height="100%"
							>
								<Box>
									<SerialNumber>
										<Trans id="ui.abbr.serialNumber">S/N</Trans>{' '}
										<strong>{serialNumber}</strong>
									</SerialNumber>
									<Model>
										<Trans id="ui.model">Modelo</Trans>{' '}
										<strong>{modelName}</strong>
									</Model>
								</Box>

								<Box pt={3}>
									<GenerateUnlockCode autoclave={autoclave} />
								</Box>

								<Box pt={3}>
									<ButtonStyled
										variant="outlined"
										onClick={() => setItemToRemove(autoclave)}
									>
										<Trans id="ui.delete">Remover</Trans>
									</ButtonStyled>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Paper>
		</>
	);
};

export default React.memo(AutoclaveItem);
