import 'typeface-roboto';

import React from 'react';
import ReactDOM from 'react-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import Navigator from 'router/Navigator';
import * as serviceWorker from 'root/serviceWorker';
import ApolloProvider from 'components/apollo/ApolloProvider';
import GeneralSnackbars from 'components/status/components/GeneralSnackbars';
import { getLanguage } from 'utils/intl';
// import { messages as enMessages } from 'root/locales/en/messages';
// import { messages as ptMessages } from 'root/locales/pt/messages';
// import { messages as esMessages } from 'root/locales/es/messages';

import ThemeProvider from 'styles/ThemeProvider';

import { dynamicActivate } from 'components/intl/i18n';

const locale = getLanguage();

const App = () => {
	React.useEffect(() => {
		// With this method we dynamically load the catalogs
		dynamicActivate();
	}, []);

	return (
		<ThemeProvider>
			<ApolloProvider>
				<I18nProvider i18n={i18n}>
					<MuiPickersUtilsProvider utils={LuxonUtils} locale={locale}>
						<GeneralSnackbars />
						<Navigator />
					</MuiPickersUtilsProvider>
				</I18nProvider>
			</ApolloProvider>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
