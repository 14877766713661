import React from 'react';
import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Grid from 'UI/Grid';

const Signature = styled(Typography)`
	a {
		color: #949494;
		font-weight: 400;
		text-decoration: none;

		&:hover {
			color: #73d518;
		}
	}
`;

const Footer = () => (
	<Box mt="auto" pb={6} pt={4}>
		<Container maxWidth="md">
			<Grid container spacing={1} justify="space-between">
				<Grid item xs={12} sm="auto">
					<Typography variant="body2">
						&copy; 2020 EVOXX Equipamentos
					</Typography>
				</Grid>
				<Grid item xs={12} sm="auto">
					<Signature variant="body2" component="span">
						<a
							href="https://wanderlima.com"
							target="_blank"
							rel="noopener noreferrer"
						>
							wanderlima.com
						</a>
					</Signature>
				</Grid>
			</Grid>
		</Container>
	</Box>
);

export default React.memo(Footer);
