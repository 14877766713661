import React from 'react';
import styled from 'styled-components/macro';
import { ResponsivePie } from '@nivo/pie';

const Container = styled.div`
	width: 100%;
	padding-top: 100%; /* 1:1 Aspect Ratio */
	position: relative; /* If you want text inside of it */
`;

const Content = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
`;

const PieChart = ({ data, colors }) => (
	<Container>
		<Content>
			<ResponsivePie
				data={data}
				margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
				innerRadius={0.5}
				padAngle={0.7}
				cornerRadius={3}
				colors={colors}
				borderColor={{ from: 'color', modifiers: [['darker', '0.3']] }}
				radialLabelsSkipAngle={8}
				radialLabelsTextColor="#333333"
				radialLabelsLinkDiagonalLength={8}
				radialLabelsLinkHorizontalLength={14}
				radialLabelsLinkColor={{ from: 'color' }}
				sliceLabelsSkipAngle={10}
				sliceLabelsTextColor="#333333"
				legends={[
					{
						anchor: 'bottom',
						direction: 'row',
						justify: false,
						translateX: 0,
						translateY: 56,
						itemsSpacing: 0,
						itemWidth: 90,
						itemHeight: 18,
						itemTextColor: '#999',
						itemDirection: 'left-to-right',
						itemOpacity: 1,
						symbolSize: 12,
						symbolShape: 'circle',
						effects: [
							{
								on: 'hover',
								style: {
									itemTextColor: '#000',
								},
							},
						],
					},
				]}
			/>
		</Content>
	</Container>
);

export default PieChart;
