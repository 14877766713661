import React from 'react';
import { Trans, t } from '@lingui/macro';
import Tooltip from '@material-ui/core/Tooltip';

import { QUERY_MAINTENANCES } from 'components/maintenance/graphql';

import StandardTable from 'components/table/StandardTable';

import { millissecondsToDateAndTime } from 'utils/date';

const INITIAL_VARIABLES = {
	page: 0,
	limit: 5,
};



const MaintenancesTable = () => {
	const handleOnRowClick = React.useCallback(id => {
		// ...
	}, []);

	const DATA_MAP = [
		{
			title: t({
				id: 'ui.autoclave',
				message: 'Autoclave',
			}),
			fields: ['autoclave.serialNumber'],
		},
		{
			title: t({
				id: 'ui.type',
				message: 'Tipo',
			}),
			fields: ['ofCycles'],
		},
		{
			title: t({
				id: 'ui.cycles',
				message: 'Ciclos',
			}),
			fields: ['totalCycles'],
		},
		{
			title: t({
				id: 'ui.code',
				message: 'Código',
			}),
			fields: ['code'],
			formatter: ({ code }) => code || '-',
		},
		{
			title: t({
				id: 'ui.codeDate',
				message: 'Data Código',
			}),
			fields: ['codeGeneratedAt'],
			formatter: ({ codeGeneratedAt }) =>
				codeGeneratedAt ? millissecondsToDateAndTime(codeGeneratedAt) : '-',
		},
		{
			title: t({
				id: 'role.technician',
				message: 'Técnico',
			}),
			fields: ['codeGeneratedBy'],
			formatter: ({ codeGeneratedBy: user }) => {
				if (user) {
					return `${user.firstName} ${user.lastName}`;
				}
				return '-';
			},
		},
		{
			title: (
				<Tooltip
					title={t({
						id: 'maintenance.isConfirmedByReport',
						message: 'A Manutenção é confirmada através de um relatório .txt',
					})}
					aria-label="add"
				>
					<span>
						<Trans id="ui.confirmed">Confirmado</Trans>
					</span>
				</Tooltip>
			),
			fields: ['confirmedAt'],
			formatter: ({ confirmedAt }) =>
				confirmedAt ? millissecondsToDateAndTime(confirmedAt) : 'Não',
		},
	];

	return (
		<StandardTable
			fieldKey="maintenances"
			query={QUERY_MAINTENANCES}
			dataMap={DATA_MAP}
			initialRowsPerPage={5}
			initialVariables={INITIAL_VARIABLES}
			fetchPolicy="cache-and-network"
			onRowClick={handleOnRowClick}
		/>
	);
};

export default React.memo(MaintenancesTable);
