import { object, string } from 'yup';
import { t } from '@lingui/macro';

const validationSchema = () => {
	const required = t({
		id: 'validation.required',
		message: 'Requirido',
	});
	const serialNumberLength = 8;
	const serialNumberLengthMsg = t({
		id: 'validation.minDigitsN',
		message: `Precisa ter ${serialNumberLength} dígitos`,
	});

	return object().shape({
		serialNumber: string()
			.nullable()
			.test(
				'serialNumberLength',
				serialNumberLengthMsg,
				value => value.length === serialNumberLength
			)
			.required(required),
		model: string().nullable().required(required),
	});
};

export default validationSchema;
