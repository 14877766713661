import gql from 'graphql-tag';

export const USER_FRAGMENT = gql`
	fragment userFragment on User {
		id
		firstName
		lastName
		email
		emailVerified
		cpf
		cnpj
		companyName
		phone
		city {
			id
			name
		}
		state {
			id
			name
			code
		}
		country {
			id
			name
			code
		}
		role
		preferences {
			language
		}
	}
`;

export const QUERY_ME = gql`
	query Me {
		me {
			...userFragment
		}
	}
	${USER_FRAGMENT}
`;

export const QUERY_USER = gql`
	query User($id: ID!) {
		user(id: $id) {
			...userFragment
		}
	}
	${USER_FRAGMENT}
`;

export const QUERY_USERS = gql`
	query Users(
		$limit: Int = 5
		$page: Int = 0
		$role: Role
		$searchText: String
		$country: String
		$state: String
		$city: String
	) {
		users(
			limit: $limit
			page: $page
			role: $role
			searchText: $searchText
			country: $country
			state: $state
			city: $city
		) {
			edges {
				...userFragment
			}
			totalCount
		}
	}
	${USER_FRAGMENT}
`;

export const MUTATION_UPDATE_ME = gql`
	mutation UpdateMe($input: UpdateMeInput!) {
		updateMe(input: $input) {
			...userFragment
		}
	}
	${USER_FRAGMENT}
`;

export const MUTATION_CREATE_USER = gql`
	mutation CreateUser($input: UserInput!) {
		createUser(input: $input) {
			...userFragment
		}
	}
	${USER_FRAGMENT}
`;

export const MUTATION_UPDATE_USER = gql`
	mutation UpdateUser($input: UserInput!) {
		updateUser(input: $input) {
			...userFragment
		}
	}
	${USER_FRAGMENT}
`;

export const MUTATION_REMOVE_USER = gql`
	mutation RemoveUser($id: ID!) {
		removeUser(id: $id) {
			...userFragment
		}
	}
	${USER_FRAGMENT}
`;

export const MUTATION_SIGN_UP = gql`
	mutation SignUp($input: SignUpInput!) {
		signUp(input: $input) {
			success
		}
	}
`;
