import React from 'react';
import { Switch } from 'react-router-dom';

import PublicRoute from 'router/PublicRoute';
import Container from 'components/layout/Container';
import HtmlHeader from 'components/layout/HtmlHeader';

import routes from './routes';

const PublicRoutes = () => (
	<Container>
		<Switch>
			{routes.map(route => {
				if (!route) {
					return null;
				}

				const Component = route.component;

				return (
					<PublicRoute
						key={route.path}
						path={route.path}
						exact={route.exact}
						component={route.component}
					>
						<HtmlHeader {...route} />
						<Component {...route} />
					</PublicRoute>
				);
			})}
		</Switch>
	</Container>
);

export default PublicRoutes;
