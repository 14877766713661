import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useMutation } from '@apollo/client';
import { Field } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import { ADMIN, CUSTOMER, TECHNICIAN } from 'components/users/config';
import FinalForm from 'components/form/Form';
import { useNavigation } from 'components/navigation/hooks';
import {
	MUTATION_LOGIN,
	MUTATION_EMAIL_VALIDATION_REQUEST,
} from 'components/auth/graphql';
import { setToken } from 'components/auth/actions';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';

import config from 'root/config';
import { UNVERIFIED_EMAIL } from 'utils/errors';

import Link from 'UI/Link';
import Loading from 'UI/Loading';

import validationSchema from './validationSchema';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Submit = styled(SubmitButton)`
	margin: ${props => props.theme.spacing(3, 0, 2)};
`;

const INITIAL_VALUES = {
	email: '',
	password: '',
};

export default function LoginForm() {
	const { replace } = useNavigation();
	const [login, { data: loginData, error, loading }] = useMutation(MUTATION_LOGIN);
	const [emailValidationRequest, { loading: emailValidationLoading }] = useMutation(
		MUTATION_EMAIL_VALIDATION_REQUEST
	);

	React.useEffect(() => {
		const res = loginData?.login;

		if (res && !error) {
			const userRole = res?.user?.role;

			setToken(res.token)
				.then(() => {
					if (userRole === ADMIN) {
						replace('/admin/dashboard');
					} else if (userRole === CUSTOMER) {
						replace('/cliente/minhas-autoclaves');
					} else if (userRole === TECHNICIAN) {
						replace('/tecnico/manutencao');
					}
				})
				.catch(error => console.log(error));
		}
	}, [loginData, error, replace]);

	const handleEmailValidationRequest = React.useCallback(
		async ({ email }) => {
			try {
				await emailValidationRequest({
					variables: {
						input: {
							email,
							appUrl: `${config.appUrl}/confirmar-email`,
						},
					},
				});
			} catch (error) {
				throw error;
			}
		},
		[emailValidationRequest]
	);

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await login({
					variables: {
						input,
					},
				});
			} catch (error) {
				const isEmailUnverified = error.graphQLErrors.find(
					item => item.type === UNVERIFIED_EMAIL
				);
				if (isEmailUnverified) {
					handleEmailValidationRequest(input);
				}
				throw error;
			}
		},
		[handleEmailValidationRequest, login]
	);

	return (
		<>
			{emailValidationLoading && <Loading fullScreen />}
			<FinalForm
				formName="login"
				onSubmit={handleOnSubmit}
				initialValues={INITIAL_VALUES}
				validationSchema={validationSchema()}
				validateOnBlur={false}
				subscription={{ values: true, errors: true }}
				render={({ handleSubmit, submitting, pristine }) => {
					return (
						<Form onSubmit={handleSubmit}>
							<Field
								type="email"
								name="email"
								component="input"
								variant="outlined"
								margin="normal"
								required
								fullWidth
								label={t({
									id: 'ui.email',
									message: 'Email',
								})}
								autoComplete="email"
								autoFocus
							>
								{props => <TextField {...props} />}
							</Field>
							<Field
								type="password"
								name="password"
								component="input"
								variant="outlined"
								margin="normal"
								required
								fullWidth
								label={t({
									id: 'ui.password',
									message: 'Senha',
								})}
								autoComplete="current-password"
							>
								{props => <TextField {...props} />}
							</Field>
							<Box pt={2}>
								<Typography align="right">
									<Link to="/redefinir-senha" variant="body2">
										<Trans id="auth.resetPassword">
											Redefinir Senha
										</Trans>
									</Link>
								</Typography>
							</Box>
							<Submit
								type="submit"
								fullWidth
								size="large"
								variant="contained"
								loadingColor="accent"
								color="primary"
								disabled={submitting || pristine}
								isLoading={submitting || loading}
							>
								<Trans id="ui.enter">Entrar</Trans>
							</Submit>
							<Box pt={2}>
								<Typography>
									<Link to="/criar-conta" variant="body2">
										<Trans id="ui.noAccount.create">
											Não tem conta? Clique aqui para se inscrever.
										</Trans>
									</Link>
								</Typography>
							</Box>
						</Form>
					);
				}}
			/>
		</>
	);
}
