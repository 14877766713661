import React from 'react';
import { t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useLazyQuery } from '@apollo/client';
import { Field } from 'react-final-form';
import Box from '@material-ui/core/Box';

import processError from 'utils/errors';
import { onlyNumbers } from 'utils/formatters';
import FinalForm from 'components/form/Form';
import TextField from 'components/form/TextField';
import { QUERY_CHECK_MAINTENANCE } from 'components/maintenance/graphql';

import usePrevious from 'hooks/usePrevious';

import Loading from 'UI/Loading';

import validationSchema from './validationSchema';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const SearchAutoclaveForm = ({ onChange, serialNumber }) => {
	const prevSerialNumber = usePrevious(serialNumber);

	const [checkMaintenance, { data, loading, error }] = useLazyQuery(
		QUERY_CHECK_MAINTENANCE,
		{
			fetchPolicy: 'network-only',
		}
	);

	const handleOnSubmit = React.useCallback(
		async values => {
			try {
				await checkMaintenance({
					variables: {
						serialNumber: values.serialNumber,
					},
				});
			} catch (error) {
				console.log('handleOnSubmit error', error);
			}
		},
		[checkMaintenance]
	);

	const handleSerialNumber = React.useCallback(
		(serialNumber, _form) => {
			if (loading) {
				return;
			}

			if (serialNumber.length < 8) {
				onChange(null);
				return;
			}

			// form.submit();
			const formEl = document.getElementById('searchAutoclaveForm');
			if (formEl) {
				handleOnSubmit({ serialNumber });

				// formEl.dispatchEvent(
				// 	new Event('submit', { cancelable: true, bubbles: true })
				// );
			}
		},
		[loading, handleOnSubmit, onChange]
	);

	React.useEffect(() => {
		if (serialNumber?.length === 8 && prevSerialNumber !== serialNumber) {
			handleSerialNumber(serialNumber);
		}
	}, [handleSerialNumber, prevSerialNumber, serialNumber]);

	React.useEffect(() => {
		const res = data?.checkMaintenance;

		if (!res) {
			return;
		}

		onChange(res);
	}, [data, loading, onChange]);

	React.useEffect(() => {
		if (error?.message) {
			onChange(null);
		}
	}, [error, onChange]);

	return (
		<>
			{loading && <Loading fullScreen />}
			<FinalForm
				formName="searchAutoclave"
				showSuccess={false}
				validateOnBlur={false}
				onSubmit={handleOnSubmit}
				initialValues={{
					serialNumber,
				}}
				validationSchema={validationSchema()}
				subscription={{ values: true, errors: true, pristine: true }}
				render={({ handleSubmit, values, form }) => {
					return (
						<Form id="searchAutoclaveForm" onSubmit={handleSubmit}>
							<Box
								display="flex"
								flexDirection="column"
								justify="center"
								alignSelf="center"
							>
								<Field
									required
									fullWidth
									type="text"
									name="serialNumber"
									component="input"
									variant="outlined"
									label={t({
										id: 'ui.serialNumber',
										message: 'Número de série',
									})}
									autoComplete="off"
									maxLength={8}
									parse={onlyNumbers}
									onChange={props => handleSerialNumber(props, form)}
								>
									{props => <TextField {...props} />}
								</Field>
								{!!error?.message &&
									values.serialNumber?.length === 8 && (
										<Box>{processError(error)}</Box>
									)}
							</Box>
						</Form>
					);
				}}
			/>
		</>
	);
};

export default React.memo(SearchAutoclaveForm);
