import { DateTime } from 'luxon';

export const isoDateToDateAndTime = date => {
	return DateTime.fromISO(date).toFormat('dd/MM/yyyy HH:mm:ss');
};

export const millissecondsToDate = milliseconds => {
	const date = new Date(parseInt(milliseconds)).toISOString();
	return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
};

export const millissecondsToDateAndTime = milliseconds => {
	if (!milliseconds) {
		return false;
	}

	const date = new Date(parseInt(milliseconds)).toISOString();
	return DateTime.fromISO(date).toFormat('dd/MM/yyyy HH:mm:ss');
};

export const luxonToISODate = dateObj => {
	return dateObj.startOf('day').toFormat('yyyy-MM-dd');
};
