import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

const TheTableHeader = ({ title, dataMap, hasActions }) => (
	<TableHead>
		{title && (
			<Box pt={3} p={2}>
				<Typography>{title.toUpperCase()}</Typography>
			</Box>
		)}
		<TableRow>
			{dataMap.map((item, idx) => (
				<TableCell key={idx}>{item.title}</TableCell>
			))}
			{hasActions && <TableCell align="right" />}
		</TableRow>
	</TableHead>
);

export default React.memo(TheTableHeader);
