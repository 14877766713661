import VMasker from 'vanilla-masker';

export function mobilePhoneMaskBR(phone) {
	if (!phone) return;

	return VMasker.toPattern(phone, '(99) 99999-9999');
}

export function mobilePhoneMaskWorld(phone) {
	if (!phone) return;

	return VMasker.toPattern(phone, '999999999999');
}

export function zipCodeMask(zipCode) {
	if (!zipCode) return;
	return VMasker.toPattern(zipCode, '99999-999');
}

export function CPFMask(cpf) {
	if (!cpf) return;
	return VMasker.toPattern(cpf, '999.999.999-99');
}

export function CNPJMask(cnpj) {
	if (!cnpj) return;
	return VMasker.toPattern(cnpj, '99.999.999/9999-99');
}

export function birthdateMask(birthdate) {
	if (!birthdate) return;
	return VMasker.toPattern(birthdate, '99/99/9999');
}
