import React from 'react';
import { Trans } from '@lingui/macro';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import MyAutoclave from 'components/autoclaves/MyAutoclave';

const MyAutoclavePage = () => {
	const { id } = useParams();

	return (
		<>
			<Container disableGutters>
				<Grid container component="main">
					<Grid item xs={12} sm={8} md={9}>
						<Typography paragraph>
							<Trans id="autoclave.activateIfNeeded">
								Sua Autoclave EVOXX, faça a ativação se necessário
								informando o código exibido no equipamento.
							</Trans>
						</Typography>
					</Grid>
				</Grid>

				<Box pt={4}>
					<MyAutoclave id={id} />
				</Box>
			</Container>
		</>
	);
};

export default React.memo(MyAutoclavePage);
