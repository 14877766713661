import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import CreateAutoclaveForm from 'components/autoclaves/CreateAutoclaveForm';

const CreateAutoclavePage = props => {
	return (
		<>
			<Container disableGutters>
				<Typography>
					<Trans id="autoclaves.useFormToAdd">
						Utilize o formulário abaixo para adicionar uma Autoclave.
					</Trans>
				</Typography>
			</Container>
			<Container maxWidth="sm" disableGutters>
				<Box pt={4} display="flex" flexDirection="column">
					<CreateAutoclaveForm />
				</Box>
			</Container>
		</>
	);
};

export default React.memo(CreateAutoclavePage);
