import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import AutoclaveTable from 'components/autoclaves/AutoclaveTable';

const AutoclavesPage = () => (
	<Container disableGutters>
		<Typography>
			<Trans id="autoclaves.allAdded">Lista de Autoclaves EVOXX cadastradas.</Trans>
		</Typography>
		<Box pt={4}>
			<AutoclaveTable />
		</Box>
	</Container>
);

export default React.memo(AutoclavesPage);
