import gql from 'graphql-tag';

export const QUERY_DASHBOARD = gql`
	query Dashboard {
		dashboard {
			users {
				admin
				customer
				technician
				total
			}
			models {
				edges {
					name
					total
				}
				total
			}
			alerts {
				threeHundred
				sixHundred
			}
			reports {
				total
			}
			autoclaves {
				total
				unlocked
			}
		}
	}
`;
