import React from 'react';
import styled, { css } from 'styled-components';
import { useLocation, Link } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const activeColor = css`
	color: white;
`;

const ListItemStyled = styled(ListItem)`
	color: ${({ theme }) => theme.palette.secondary.light};

	&:hover {
		${activeColor}
	}

	${({ $isActive }) => $isActive && activeColor}

	${({ $drawerIsOpen, $hasChildrens }) =>
		$drawerIsOpen === false &&
		$hasChildrens &&
		css`
			margin-bottom: 1.8rem;
		`}
`;

const ListItemIconStyled = styled(ListItemIcon)`
	min-width: unset;
	padding-right: 1em;
	color: ${({ theme }) => theme.palette.secondary.light};

	${({ $isActive }) => $isActive && activeColor}
`;

const ListItemSecondaryActionStyled = styled(ListItemSecondaryAction)`
	${({ $drawerIsOpen }) =>
		$drawerIsOpen === false &&
		css`
			right: 12px;
			top: 60px;
			z-index: 1000;
		`}
`;

const SubDivider = styled(Divider)`
	margin: 8px 0;
`;

export const MenuList = ({ items, showDivider, drawerIsOpen }) => {
	const { pathname } = useLocation();
	const [open, setOpen] = React.useState([]);

	const handleOpen = React.useCallback(
		index => {
			const _open = [...open];

			if (_open.includes(index)) {
				const itemIndex = _open.indexOf(index);
				if (itemIndex > -1) {
					_open.splice(itemIndex, 1);
				}
				setOpen(_open);
			} else {
				_open.push(index);
				setOpen(_open);
			}
		},
		[open]
	);

	const checkIfOpen = React.useCallback(
		index => {
			return open.includes(index);
		},
		[open]
	);

	const ExpandIndicator = ({ index }) => {
		return open.includes(index) ? (
			<IconButton size="small" onClick={() => handleOpen(index)}>
				<ExpandMore color="primary" />
			</IconButton>
		) : (
			<IconButton size="small" onClick={() => handleOpen(index)}>
				<ExpandLess color="primary" />
			</IconButton>
		);
	};

	return (
		<>
			<List>
				{items.map((item, idx) => (
					<React.Fragment key={idx}>
						<ListItemStyled
							button
							key={idx}
							to={item.to}
							component={item.to ? Link : undefined}
							$isActive={pathname === item.to}
							$drawerIsOpen={drawerIsOpen}
							$hasChildrens={!!item?.items}
						>
							<ListItemIconStyled $isActive={pathname === item.to}>
								{item.icon}
							</ListItemIconStyled>

							<ListItemText>{item.label}</ListItemText>

							{!!item.items && (
								<ListItemSecondaryActionStyled
									$drawerIsOpen={drawerIsOpen}
								>
									<ExpandIndicator index={idx} />
								</ListItemSecondaryActionStyled>
							)}
						</ListItemStyled>

						{item.items && (
							<Collapse unmountOnExit timeout="auto" in={checkIfOpen(idx)}>
								<List component="div" disablePadding>
									{item.items.map((subItem, idx) => (
										<ListItemStyled
											button
											key={`s${idx}`}
											to={subItem.to}
											component={Link}
											$isActive={pathname === subItem.to}
										>
											<ListItemIconStyled
												$isActive={pathname === subItem.to}
											>
												{subItem.icon}
											</ListItemIconStyled>

											<ListItemText>
												<Typography variant="body1">
													{subItem.label}
												</Typography>
											</ListItemText>
										</ListItemStyled>
									))}
								</List>

								{item.showDivider && <SubDivider />}
							</Collapse>
						)}
					</React.Fragment>
				))}
			</List>

			{showDivider && <SubDivider />}
		</>
	);
};

export default React.memo(MenuList);
