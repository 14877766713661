export default {
	htmlFontSize: 16,
	fontSize: 14,
	fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
	fontWeightLight: 300,
	fontWeightRegular: 400,
	fontWeightMedium: 500,
	fontWeightBold: 700,
	h1: {
		fontWeight: 500,
		fontSize: '2.625rem',
		lineHeight: 1.167,
		letterSpacing: '-0.01562em',
	},
	h2: {
		fontWeight: 500,
		fontSize: '2.4rem',
		lineHeight: 1.167,
		letterSpacing: '-0.01562em',
	},
	h3: {
		fontWeight: 500,
		fontSize: '2rem',
		lineHeight: 1.167,
		letterSpacing: '-0.01562em',
	},
	h4: {
		fontWeight: 500,
		fontSize: '1.8rem',
		lineHeight: 1.167,
		letterSpacing: '-0.01562em',
	},
	h5: {
		fontWeight: 500,
		fontSize: '1.4rem',
		lineHeight: 1.167,
		letterSpacing: '-0.01562em',
	},
	h6: {
		fontWeight: 500,
		fontSize: '1.2rem',
		lineHeight: 1.167,
		letterSpacing: '-0.01562em',
	},
};
