import React from 'react';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import LoginForm from 'components/auth/forms/LoginForm';
import Logo from 'components/layout/LogoEvoxx';

import autoclave from 'assets/images/autoclaveHome.png';

const GridRoot = styled(Grid)`
	min-height: 100vh;
	background-image: url(${autoclave});
	background-repeat: no-repeat;
	background-color: ${props => props.theme.palette.secondary.main};
	background-size: cover;
	background-position: center;

	button {
		color: #fff;
		padding: 10px 30px;
	}
`;

const PaperStyled = styled.div`
	background: #fff;
	box-shadow: 0px 0px 34px 0px rgba(2, 25, 94, 1);

	${props => props.theme.breakpoints.up('sm')} {
		min-height: 100vh;
	}
`;

const Intro = styled.div`
	${props => props.theme.breakpoints.up('sm')} {
		padding: 10px 0 60px;
	}

	${props => props.theme.breakpoints.up('md')} {
		max-width: 700px;
		padding: 40px 0 40px;
	}
`;

const BackWebsite = styled(Typography)`
	text-transform: uppercase;

	a {
		color: ${props => props.theme.palette.primary.main};
		text-decoration: none;
	}
`;

const HomePage = () => {
	const { i18n } = useLingui();

	const lang = i18n.locale !== 'pt' ? i18n.locale : '';

	return (
		<GridRoot container component="main">
			<Grid item xs={false} sm={7} md={8} lg={9} xl={9}>
				<Box
					color="white"
					display="flex"
					flexGrow={1}
					flexDirection="column"
					maxWidth={1280}
					mx="auto"
					minHeight="100vh"
					pb={6}
					pt={6}
					px={6}
				>
					<Box display="flex" flexGrow={1} flexDirection="column">
						<Logo size={120} />
					</Box>
					<Box display="flex" flexGrow={1} flexDirection="column">
						<Box pt={10} pb={5}>
							<Typography variant="h1">
								<Trans id="unlocking.autoclaves">
									Desbloqueio de Autoclaves
								</Trans>
							</Typography>
						</Box>

						<Intro>
							<Typography variant="body1">
								<Trans id="unlocking.autoclaves.info">
									Visando segurança e rastreabilidade as autoclaves
									EVOXX são fornecidas bloqueadas eletronicamente de
									fábrica e uma senha numérica de 5 dígitos será
									solicitada na primeira vez que a autoclave for ligada.
									Para utilizar sua autoclave pela primeira vez, efetue
									o desbloqueio e ative automaticamente a garantia de 2
									anos válida à partir da emissão da Nota Fiscal de
									compra.
								</Trans>
							</Typography>
						</Intro>
					</Box>
					<Box display="flex" flexGrow={1} flexDirection="column">
						<Grid container>
							<Grid item xs={12} lg={5}>
								<Box py={3}>
									<Typography variant="h4" paragraph>
										<Trans id="cycle.record">
											Registro de ciclos
										</Trans>
									</Typography>
									<Typography variant="body1">
										<Trans id="cycle.record.info">
											Faça o upload dos arquivos que são gravados no
											cartão Micro SD e gere os relatórios de ciclo
											no formato PDF. Todos os ciclos da sua
											Autoclave ficarão salvos na EVOXX Cloud.
										</Trans>
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Box>
				</Box>
			</Grid>

			<Grid item xs={12} sm={5} md={4} lg={3} xl={3}>
				<PaperStyled>
					<Box p={4}>
						<Box
							pt={1}
							display="flex"
							alignItems="center"
							flexDirection="row"
						>
							<Box pr={1} display="flex" alignItems="center">
								<KeyboardBackspaceIcon color="primary" />
							</Box>
							<BackWebsite>
								<a href={`https://evoxx.com.br/${lang}`}>
									<Trans id="website.backTo">Voltar ao website</Trans>
								</a>
							</BackWebsite>
						</Box>

						<Box pt={8} pb={3}>
							<Typography variant="h3">
								<Trans id="login">Login</Trans>
							</Typography>
						</Box>

						<Box pt={3} pb={4}>
							<Typography>
								<Trans id="login.yourAccount">
									Faça login na sua conta EVOXX.
								</Trans>
							</Typography>
						</Box>

						<LoginForm />
					</Box>
				</PaperStyled>
			</Grid>
		</GridRoot>
	);
};

export default React.memo(HomePage);
