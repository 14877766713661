import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import browserHistory from 'router/browserHistory';
import NotFound from 'pages/public/NotFound';

// Routes
import PublicRoutes from 'router/routes/PublicRoutes';
import AdminRoutes from 'router/routes/AdminRoutes';
import ClientRoutes from 'router/routes/ClientRoutes';
import TechnicianRoutes from 'router/routes/TechnicianRoutes';

const Navigator = () => (
	<Router history={browserHistory}>
		<Switch>
			<Route path="/admin">
				<AdminRoutes />
			</Route>
			<Route path="/cliente">
				<ClientRoutes />
			</Route>
			<Route path="/tecnico">
				<TechnicianRoutes />
			</Route>
			<Route path="/">
				<PublicRoutes />
			</Route>
			<Route exact path="/404">
				<NotFound />
			</Route>
			<Route path="*">
				<NotFound />
			</Route>
		</Switch>
	</Router>
);

export default Navigator;
