import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { t } from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import { QUERY_ME } from 'components/users/graphql';
import {
	QUERY_REPORT,
	QUERY_REPORTS,
	MUTATION_REMOVE_REPORT,
} from 'components/reports/graphql';
import { ADMIN } from 'components/users/config';

import StandardTable from 'components/table/StandardTable';
import useApolloCache from 'components/apollo/hooks';
import useStatus from 'components/status/hooks';

import processError from 'utils/errors';
import { millissecondsToDateAndTime } from 'utils/date';

import Loading from 'UI/Loading';
import ConfirmationDialog from 'UI/ConfirmationDialog';

const INITIAL_VARIABLES = {
	page: 0,
	limit: 5,
};

const ReportTable = ({ addRoute }) => {
	const { addSuccess, addError } = useStatus();
	const { removeItemFromCache } = useApolloCache();
	const [isLoadingReportPDF, setIsLoadingReportPDF] = React.useState(false);
	const [downloadFile, setDownloadFile] = React.useState(false);
	const [itemToRemove, setItemToRemove] = React.useState(null);

	const { data: dataMe } = useQuery(QUERY_ME);
	const [removeItem, { loading }] = useMutation(MUTATION_REMOVE_REPORT);
	const [report, { data, loadingReport }] = useLazyQuery(QUERY_REPORT);

	const reportPath = data?.report?.path;

	const showRemoveDialog = React.useCallback((event, report) => {
		event.stopPropagation();
		setItemToRemove(report);
	}, []);

	const handleDownload = React.useCallback(
		async (event, item) => {
			try {
				event.stopPropagation();
				setIsLoadingReportPDF(true);
				await report({
					variables: {
						id: item.id,
					},
				});
				setDownloadFile(true);
			} catch (error) {
				console.log('handleReport', error);
			} finally {
				setIsLoadingReportPDF(false);
			}
		},
		[report]
	);

	React.useEffect(() => {
		if (reportPath && downloadFile) {
			setIsLoadingReportPDF(true);

			let timer;
			timer = setTimeout(() => {
				var link = document.createElement('a');
				link.setAttribute('id', 'tempReportLink');
				link.href = reportPath;
				link.download = `relatorio-${data.report.serialNumber}.pdf`;
				document.body.appendChild(link);
				link.dispatchEvent(new MouseEvent('click'));
				document.body.removeChild(link);

				setIsLoadingReportPDF(false);
				setDownloadFile(false);

				clearTimeout(timer);
			}, 5000);
		}
	}, [data, downloadFile, reportPath]);

	const handleOnDismiss = React.useCallback(event => {
		setItemToRemove(null);
		event.stopPropagation();
	}, []);

	const handleRemove = React.useCallback(
		async (event, refetch) => {
			try {
				event.stopPropagation();

				await removeItem({
					variables: {
						id: itemToRemove.id,
					},
				});

				removeItemFromCache('Report', itemToRemove.id);

				addSuccess(
					t({
						id: 'report.removeSuccess',
						message: `Relatório ${itemToRemove.serialNumber} removido com sucesso`,
					})
				);
				setItemToRemove(null);
			} catch (error) {
				event.stopPropagation();

				console.log('handleRemove error', error);
				setItemToRemove(null);
				addError(processError(error));
			}
		},
		[addError, addSuccess, itemToRemove, removeItem, removeItemFromCache]
	);

	const isLoading = loading || loadingReport || isLoadingReportPDF;

	const DATA_MAP = [
		{
			title: t({
				id: 'ui.abbr.serialNumber',
				message: 'S/N',
			}),
			fields: ['serialNumber'],
		},
		{
			title: t({
				id: 'ui.modelo',
				message: 'Modelo',
			}),
			fields: ['autoclave.model.name'],
		},
		{
			title: t({
				id: 'ui.cycles',
				message: 'Ciclos',
			}),
			fields: ['totalCycles'],
		},
		{
			title: t({
				id: 'ui.date',
				message: 'Data',
			}),
			fields: ['csvCreatedAt'],
			formatter: ({ csvCreatedAt }) => millissecondsToDateAndTime(csvCreatedAt),
		},
	];

	return (
		<>
			{isLoading && <Loading fullScreen />}
			<ConfirmationDialog
				isOpen={!!itemToRemove}
				title={t({
					id: 'report.remove',
					message: 'Remover relatório de Autoclave',
				})}
				message={t({
					id: 'report.remove.askConfirmation',
					message: `Deseja realmente remover o relatório ${itemToRemove?.serialNumber}`,
				})}
				onConfirmation={handleRemove}
				onClose={handleOnDismiss}
			/>
			<StandardTable
				showDateFilter
				fieldKey="reports"
				query={QUERY_REPORTS}
				dataMap={DATA_MAP}
				initialRowsPerPage={5}
				initialVariables={INITIAL_VARIABLES}
				fetchPolicy="cache-and-network"
				addRoute={addRoute}
				actions={({ item }) => {
					return (
						<>
							<IconButton
								size="small"
								onClick={event => handleDownload(event, item)}
							>
								<PictureAsPdfIcon />
							</IconButton>
							{dataMe?.me?.role === ADMIN && (
								<IconButton
									size="small"
									onClick={event => showRemoveDialog(event, item)}
								>
									<DeleteIcon />
								</IconButton>
							)}
						</>
					);
				}}
			/>
		</>
	);
};

ReportTable.propTypes = {
	addRoute: PropTypes.string,
};

ReportTable.defaultProps = {
	addRoute: '/admin/relatorio/importar',
};

export default React.memo(ReportTable);
