import React from 'react';
import { Trans } from '@lingui/macro';

import Dashboard from 'pages/admin/Dashboard';

import Users from 'pages/admin/users/Users';
import CreateUser from 'pages/admin/users/CreateUser';
import UpdateUser from 'pages/admin/users/UpdateUser';

import Models from 'pages/admin/models/Models';
import CreateModel from 'pages/admin/models/CreateModel';
import UpdateModel from 'pages/admin/models/UpdateModel';

import Autoclaves from 'pages/admin/autoclaves/Autoclaves';
import ImportAutoclaves from 'pages/admin/autoclaves/ImportAutoclaves';
import CreateAutoclave from 'pages/admin/autoclaves/CreateAutoclave';
import UpdateAutoclave from 'pages/admin/autoclaves/UpdateAutoclave';
import CyclesAlert from 'pages/admin/autoclaves/CyclesAlert';

import Maintenance from 'pages/admin/maintenance/Maintenance';
import MaintenanceHistory from 'pages/admin/maintenance/MaintenanceHistory';

import Reports from 'pages/admin/reports/Reports';
import ImportReport from 'pages/admin/reports/ImportReport';

import MyAccount from 'pages/admin/MyAccount';

import NotFound from 'pages/public/NotFound';

const routes = [
	{
		path: '/admin/dashboard',
		title: <Trans id="route.c.dashboard">Dashboard</Trans>,
		component: Dashboard,
		isPrivate: true,
		exact: true,
		isHome: true,
	},
	{
		path: '/admin/usuarios/:role?',
		title: <Trans id="route.c.users">Usuários</Trans>,
		component: Users,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/usuario/adicionar',
		title: <Trans id="route.c.addUser">Adicionar Usuário</Trans>,
		component: CreateUser,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/usuario/:id',
		title: <Trans id="route.c.editUser">Editar Usuário</Trans>,
		component: UpdateUser,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/autoclaves/modelos',
		title: <Trans id="route.c.autoclaveModels">Modelos de Autoclave</Trans>,
		component: Models,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/autoclaves/modelos/adicionar',
		title: (
			<Trans id="route.c.addAutoclaveModel">Adicionar Modelo de Autoclave</Trans>
		),
		component: CreateModel,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/autoclave/modelo/:id',
		title: <Trans id="route.c.editAutoclaveModel">Editar Modelo de Autoclave</Trans>,
		component: UpdateModel,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/autoclaves',
		title: <Trans id="route.c.autoclaves">Autoclaves</Trans>,
		component: Autoclaves,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/autoclaves/adicionar',
		title: <Trans id="route.c.addAutoclaves">Adicionar Autoclave</Trans>,
		component: CreateAutoclave,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/autoclave/:id',
		title: <Trans id="route.c.autoclave">Autoclave</Trans>,
		component: UpdateAutoclave,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/autoclaves/importar',
		title: <Trans id="route.c.importAutoclave">Importar Autoclaves</Trans>,
		component: ImportAutoclaves,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/manutencao/:serialNumber?',
		title: <Trans id="route.c.maintenance">Manutenção Autoclave</Trans>,
		component: Maintenance,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/historico-manutencao',
		title: <Trans id="route.c.maintenanceHistory">Histórico Manutenção</Trans>,
		component: MaintenanceHistory,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/relatorios',
		title: <Trans id="route.c.reports">Relatórios</Trans>,
		component: Reports,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/alerta-ciclos/:alertType?',
		title: <Trans id="route.c.cycleAlert">Alerta de Ciclos</Trans>,
		component: CyclesAlert,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/relatorio/importar',
		title: <Trans id="route.c.importReport">Importar Relatório</Trans>,
		component: ImportReport,
		isPrivate: true,
		exact: true,
	},
	{
		path: '/admin/minha-conta',
		title: <Trans id="route.c.myAccount">Minha Conta</Trans>,
		component: MyAccount,
		isPrivate: true,
		exact: true,
	},
	{
		path: '*',
		title: <Trans id="route.c.notFound">404</Trans>,
		component: NotFound,
		isPrivate: false,
	},
];

export default routes;
