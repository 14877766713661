import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import ReportsList from 'components/reports/ReportsList';
import MaintenanceStepper from 'components/maintenance/AutoclaveMaintenance/MaintenanceStepper';

const AutoclaveMaintenance = ({ serialNumber }) => {
	const [autoclaveId, setAutoclaveId] = React.useState(null);

	const handleAutoclave = React.useCallback(id => {
		setAutoclaveId(id);
	}, []);

	return (
		<>
			<MaintenanceStepper serialNumber={serialNumber} onChange={handleAutoclave} />
			{autoclaveId && (
				<Box pt={8}>
					<Box pb={4}>
						<Typography variant="h6">
							<Trans id="ui.reports">Relatórios</Trans>
						</Typography>
					</Box>
					<ReportsList autoclaveId={autoclaveId} listType="ALL" />
				</Box>
			)}
		</>
	);
};

export default AutoclaveMaintenance;
