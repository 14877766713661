import React from 'react';
import { Trans } from '@lingui/macro';
import styled from 'styled-components/macro';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PersonIcon from '@material-ui/icons/Person';

import Grid from 'UI/Grid';

import autoclaveImage from 'assets/images/autoclave.png';

const Image = styled.img`
	width: 100%;
	height: auto;
`;

const Title = styled(Typography)`
	color: #adadad;
	font-size: 1.2rem;
`;

const Subtitle = styled(Typography)`
	font-size: 1rem;
`;

const AutoclaveOwner = ({ user, serialNumber, modelName }) => {
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} md={3}>
				<Box
					pr={3}
					pl={2}
					display="flex"
					alignItems="center"
					justifyContent="center"
					height="100%"
				>
					<Image src={autoclaveImage} alt="Autoclave EVOXX" />
				</Box>
			</Grid>
			<Grid item container direction="column" justify="center" xs={12} md={4}>
				<Box my={2}>
					<Title>
						<Trans id="ui.abbr.serialNumber">S/N</Trans> {serialNumber}
					</Title>
					<Subtitle>
						<Trans id="ui.model">Modelo</Trans>{' '}
						<strong>{modelName || '_'}</strong>
					</Subtitle>
				</Box>
			</Grid>
			{user && (
				<Grid item container direction="row" alignItems="center" xs={12} md={5}>
					<Box my={1} display="flex" direction="row">
						<Box pr={1}>
							<PersonIcon />
						</Box>
						<Box>
							<Subtitle>
								{user?.firstName} {user?.lastName}
							</Subtitle>
							<Subtitle>
								<a href={`mailto:${user?.email}`}>{user?.email}</a>
							</Subtitle>
						</Box>
					</Box>
				</Grid>
			)}
		</Grid>
	);
};

export default React.memo(AutoclaveOwner);
