import React from 'react';
import { Trans, t } from '@lingui/macro';
import { useQuery } from '@apollo/client';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import CategoryIcon from '@material-ui/icons/Category';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import History from '@material-ui/icons/History';
import PeopleIcon from '@material-ui/icons/People';
import Typography from '@material-ui/core/Typography';

import { useNavigation } from 'components/navigation/hooks';
import { QUERY_DASHBOARD } from 'components/dashboard/graphql';
import CountCard from 'components/dashboard/CountCard';
import PieChart from 'components/dashboard/PieChart';
import Loading from 'UI/Loading';

const DashboardPage = () => {
	const { navigate } = useNavigation();
	const { loading, data } = useQuery(QUERY_DASHBOARD);

	if (loading || !data) {
		return <Loading fullScreen />;
	}

	const { users, models, alerts, reports, autoclaves } = data.dashboard;

	return (
		<Container disableGutters>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6} md={3}>
					<CountCard
						color="#97c7f9"
						label={t({
							id: 'ui.autoclaves',
							message: 'Autoclaves',
						})}
						count={autoclaves.total}
						onClick={() => navigate('/admin/autoclaves')}
						icon={<CategoryIcon color="secondary" />}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<CountCard
						color="#97c7f9"
						label={t({
							id: 'ui.reports',
							message: 'Relatórios',
						})}
						count={reports.total}
						onClick={() => navigate('/admin/relatorios')}
						icon={<History color="secondary" />}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<CountCard
						color="#97c7f9"
						label={t({
							id: 'ui.cyclesAlert',
							message: 'Alertas de ciclos',
						})}
						count={alerts.sixHundred + alerts.threeHundred}
						onClick={() => navigate('/admin/alerta-ciclos')}
						icon={<ErrorOutlineIcon color="secondary" />}
					/>
				</Grid>
				<Grid item xs={12} sm={6} md={3}>
					<CountCard
						color="#97c7f9"
						label={t({
							id: 'ui.users',
							message: 'Usuários',
						})}
						count={users.total}
						onClick={() => navigate('/admin/usuarios')}
						icon={<PeopleIcon color="secondary" />}
					/>
				</Grid>
			</Grid>

			<Box mt={6} />

			<Grid container spacing={3}>
				<Grid item xs={12} md={6}>
					<Paper elevation={0}>
						<Box pt={3} pl={3}>
							<Typography variant="h6">
								<Trans id="ui.autoclaves">Autoclaves</Trans>
							</Typography>
						</Box>
						<PieChart
							colors={['#2f8bfd', '#78d3ff']}
							data={[
								{
									id: t({
										id: 'ui.locked',
										message: 'Bloqueada',
									}),
									label: t({
										id: 'ui.locked',
										message: 'Bloqueada',
									}),
									value: autoclaves.total - autoclaves.unlocked,
								},
								{
									id: t({
										id: 'ui.unlocked',
										message: 'Desbloqueada',
									}),
									label: t({
										id: 'ui.unlocked',
										message: 'Desbloqueada',
									}),
									value: autoclaves.unlocked,
								},
							]}
						/>
					</Paper>
				</Grid>
				<Grid item xs={12} md={6}>
					<Paper elevation={0}>
						<Box pt={3} pl={3}>
							<Typography variant="h6">
								<Trans id="ui.models">Modelos</Trans>
							</Typography>
						</Box>
						<PieChart
							colors={[
								'#2f8bfd',
								'#78d3ff',
								'#6e8dfd',
								'#6ef0fd',
								'#9EBDF6',
							]}
							data={models.edges.map((item, _idx) => ({
								id: item.name,
								label: item.name,
								value: item.total,
							}))}
						/>
					</Paper>
				</Grid>
			</Grid>
		</Container>
	);
};

export default React.memo(DashboardPage);
