import React from 'react';
import { useLazyQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';

import SelectField from 'components/form/SelectField';
import { QUERY_CITIES } from 'components/cities/graphql';
import usePrevious from 'hooks/usePrevious';

import Loading from 'UI/Loading';

const LoadingSmall = () => (
	<Box pr={1}>
		<Loading size={18} />
	</Box>
);

const CityField = ({ stateId, disabled, ...rest }) => {
	const prevStateId = usePrevious(stateId);
	const [options, setOptions] = React.useState([]);

	const [cities, { data, loading, error }] = useLazyQuery(QUERY_CITIES);

	const handleState = React.useCallback(async () => {
		try {
			if (prevStateId && stateId !== prevStateId) {
				rest.input.onChange('');
			}

			await cities({
				variables: {
					stateId,
				},
			});
		} catch (error) {
			console.log('handleState error', error);
		}
	}, [cities, prevStateId, rest.input, stateId]);

	React.useEffect(() => {
		if (stateId && stateId !== prevStateId) {
			handleState();
		}
	}, [handleState, prevStateId, stateId]);

	React.useEffect(() => {
		if (!loading && data) {
			setOptions(
				data.cities.map(item => ({
					label: item.name,
					value: item.id,
				}))
			);
		}
	}, [loading, data]);

	return (
		<SelectField
			{...rest}
			options={options}
			disabled={loading || disabled || !stateId || error?.message}
			IconComponent={loading ? LoadingSmall : undefined}
		/>
	);
};

export default React.memo(CityField);
