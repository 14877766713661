import deepmerge from 'deepmerge';

const offsetMerge = (existing = makeEmptyData(), incoming, { args }, opts) => {
	const incomingPageInfoInfo = incoming?.pageInfo ? { ...incoming.pageInfo } : {};

	const incomingEdges = incoming.edges.slice(0);
	const pageInfo = deepmerge(existing.pageInfo, incomingPageInfoInfo);

	let edges;
	if (opts.concat) {
		let existingEdges = [...existing.edges.slice(0)];

		edges = [
			...existingEdges,
			...incomingEdges.filter(
				item => !existingEdges.find(i => i.__ref === item.__ref)
			),
		];
	} else {
		edges = [...incomingEdges];
	}

	const data = {
		...existing,
		...incoming,
		edges,
		pageInfo: {
			...pageInfo,
			hasNextPage: pageInfo.hasNextPage && edges.length < incoming.totalCount,
		},
	};

	return data;
};

const defaultOpts = { concat: true, reverse: false, preventInfiniteKeys: [] };

/**
which should just reload the current query instead of paginate, I've created an escape hatch. It's a bit hacky and I've opened a feature request to be able to do this at query level, but in any case. on your pagination typePolicy, you can add an optional array of keys. If any of these are present in the args of the query, it will not use infinite concatenation.

export const typePolicy = {
	Query: {
		fields: {
			social__groupSuggestions: pagination(undefined, {
				preventInfiniteKeys: ['random'],
			})
		}
	}
}

 * other options you can pass are:
 *	- concat: false (default: true): concat creates infinite results or not
 *	- reverse: true (default: false): adds new fetchMore result on top instead of at the bottom
 *
 */
const offsetPagination = (keyArgs = [], opts = defaultOpts) => {
	const myOpts = {
		...defaultOpts,
		...opts,
	};
	return {
		// https://github.com/apollographql/apollo-client/issues/6537
		keyArgs,
		merge(existing = makeEmptyData(), incoming, { args, ...rest }) {
			return offsetMerge(existing, incoming, { args }, myOpts);
		},
	};
};

function makeEmptyData() {
	return {
		edges: [],
		pageInfo: {
			hasPreviousPage: false,
			hasNextPage: false,
			startCursor: '',
			endCursor: '',
			page: 0,
		},
		totalCount: 0,
	};
}

export default offsetPagination;
