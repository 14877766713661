import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const LogoStyled = styled.div`
	padding: 2em 0;

	width: ${({ $width }) => $width}px;
	height: ${({ $height }) => $height}px;

	padding: 0;

	transition: width 0.2s, height 0.2s;
`;

const SymbolEvoxx = ({ size }) => {
	return (
		<LogoStyled $width={size} $height={size}>
			<svg viewBox="0 0 72 74" version="1.1" xmlns="http://www.w3.org/2000/svg">
				<title>EVOXX</title>
				<defs>
					<radialGradient
						cx="41.743%"
						cy="30.4210987%"
						fx="41.743%"
						fy="30.4210987%"
						r="68.545%"
						gradientTransform="translate(0.417430,0.304211),scale(1.000000,0.972973),translate(-0.417430,-0.304211)"
						id="radialGradient-1"
					>
						<stop stopColor="#53C6D4" offset="30%"></stop>
						<stop stopColor="#00909F" offset="96%"></stop>
					</radialGradient>
				</defs>
				<g
					id="Page-1"
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
				>
					<path
						d="M52.192,8.119 L62.962,16.156 C64.351,17.187 65.524,19.584 65.524,21.38 L65.524,41.933 C65.524,44.346 64.192,47.381 62.616,48.559 L38.12,66.918 C36.64,68.028 34.136,68.028 32.655,66.918 L31.323,65.92 C32.0276948,65.7665662 32.6927772,65.4682497 33.276,65.044 L58.474,46.344 C60.149,45.097 61.508,42.329 61.508,40.16 L61.508,25.24 L20.81,55.573 C20.1513505,56.043616 19.3731111,56.318513 18.565,56.366 L15.535,54.096 L43.328,33.34 C45.005,32.093 46.362,29.325 46.362,27.156 L46.362,12.246 L5.321,42.926 C5.27611961,42.5972074 5.25273663,42.2658371 5.251,41.934 L5.251,37.617 L26.857,21.096 C28.534,19.849 29.892,17.082 29.892,14.912 L29.892,0 L0,22.722 L0,41.933 C0,46.53 2.254,51.555 5.357,53.88 L29.853,72.233 C33.007,74.589 37.763,74.589 40.918,72.233 L67.722,52.209 C70.089,50.442 72,46.63 72,43.69 L72,20.71 C72,17.766 70.087,13.956 67.726,12.187 L57.03,4.277 L52.192,8.117 L52.192,8.119 Z"
						id="symbolEvoxx"
						fill="url(#radialGradient-1)"
						fillRule="nonzero"
					></path>
				</g>
			</svg>
		</LogoStyled>
	);
};

SymbolEvoxx.propTypes = {
	size: PropTypes.number,
	color: PropTypes.string,
	textColor: PropTypes.string,
};

SymbolEvoxx.defaultProps = {
	size: 100,
	color: undefined,
	textColor: undefined,
};

export default React.memo(SymbolEvoxx);
