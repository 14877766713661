import React from 'react';
import { useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';

import SelectField from 'components/form/SelectField';

import { QUERY_STATES } from 'components/states/graphql';

import Loading from 'UI/Loading';

const LoadingSmall = () => (
	<Box pr={1}>
		<Loading size={18} />
	</Box>
);

const StateField = ({ disabled, ...rest }) => {
	const { loading, data } = useQuery(QUERY_STATES);
	const [options, setOptions] = React.useState([]);

	React.useEffect(() => {
		if (!loading && data && !options.length) {
			setOptions(
				data.states.map(item => ({
					label: `${item.code} - ${item.name} `,
					value: item.id,
				}))
			);
		}
	}, [loading, data, options]);

	return (
		<SelectField
			{...rest}
			options={options}
			disabled={loading || disabled}
			IconComponent={loading ? LoadingSmall : undefined}
		/>
	);
};

// StateField.propTypes = propTypes;

// StateField.defaultProps = defaultProps;

export default React.memo(StateField);
