import React from 'react';
import styled from 'styled-components/macro';
import Typography from '@material-ui/core/Typography';

const Card = styled.div`
	text-align: right;
	width: 100%;
	padding: 1rem;
	border-radius: ${p => p.theme.shape.borderRadius}px;
	background: ${p => p.$color};
	opacity: 0.9;
	position: relative;
	overflow: hidden;
	cursor: pointer;

	&:hover {
		opacity: 1;
	}
`;

const IconContainer = styled.div`
	position: absolute;
	top: -40px;
	left: -10px;

	svg {
		font-size: 180px;
		opacity: 0.06;
	}
`;

const CountCard = ({ color, label, count, onClick, icon }) => (
	<Card $color={color} onClick={onClick}>
		<IconContainer>{icon}</IconContainer>
		<Typography variant="h2">
			<strong>{count}</strong>
		</Typography>
		<Typography>{label}</Typography>
	</Card>
);

export default React.memo(CountCard);
