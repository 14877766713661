import React from 'react';
import { useQuery, useMutation } from '@apollo/client';

import UserForm from 'components/users/UserForm';
import { useNavigation } from 'components/navigation/hooks';
import { QUERY_USER, MUTATION_UPDATE_USER } from 'components/users/graphql';

import Loading from 'UI/Loading';

import validationSchema from './validationSchema';

const EXCLUDE_ON_SUBMIT = [
	'countryCode',
	'passwordConfirmation',
	'emailVerified',
	'__typename',
];

const UpdateUserForm = ({ userId }) => {
	const { navigate } = useNavigation();
	const [updateUser, { loading }] = useMutation(MUTATION_UPDATE_USER);
	const { loading: loadingUser, data: dataUser } = useQuery(QUERY_USER, {
		variables: {
			id: userId,
		},
	});

	const handleOnSubmit = React.useCallback(
		async input => {
			try {
				await updateUser({
					variables: {
						input,
					},
				});
				navigate('/admin/usuarios');
			} catch (err) {
				console.log('handleOnSubmit error', err);
				throw err;
			}
		},
		[updateUser, navigate]
	);

	if (!dataUser || loadingUser) {
		return <Loading fullScreen />;
	}

	const initialValues = {
		...dataUser.user,
		city: dataUser.user.city?.id || null,
		state: dataUser.user.state?.id || null,
		country: dataUser.user.country.id,
	};

	return (
		<UserForm
			loading={loading}
			initialValues={initialValues}
			onSubmit={handleOnSubmit}
			validationSchema={validationSchema}
			excludeOnSubmit={EXCLUDE_ON_SUBMIT}
		/>
	);
};

export default React.memo(UpdateUserForm);
