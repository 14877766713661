import React from 'react';
import styled, { css } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Container = styled.div`
	align-self: center;
	display: flex;

	${props =>
		props.fullScreen &&
		css`
			width: 100%;
			height: 100%;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1000;
		`}
`;
// background-color: rgba(0,0,0,0.1);

const Loading = ({ fullScreen, color, size }) => (
	<Container fullScreen={fullScreen}>
		<CircularProgress color={color} size={size} />
	</Container>
);

export default React.memo(Loading);
