import React from 'react';
import { Trans } from '@lingui/macro';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Logo from 'components/layout/LogoEvoxx';
import PasswordNewForm from 'components/auth/forms/PasswordNewForm';

const PasswordResetPage = () => (
	<Container maxWidth="xs">
		<Box
			py={6}
			display="flex"
			flexGrow={1}
			flexDirection="column"
			alignItems="center"
		>
			<Logo size={120} textColor="#000" />
		</Box>

		<Box m={1} display="flex" flexDirection="column">
			<Typography variant="h3" paragraph>
				<Trans id="auth.newPassword">Nova Senha</Trans>
			</Typography>

			<Box pt={1} pb={4}>
				<Typography>
					<Trans id="auth.addNewPwAndConfirm">
						Adicione a nova senha abaixo e confirme antes de enviar.
					</Trans>
				</Typography>
			</Box>

			<PasswordNewForm />
		</Box>
	</Container>
);

export default React.memo(PasswordResetPage);
