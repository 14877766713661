import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useMutation, useLazyQuery } from '@apollo/client';
import { Field } from 'react-final-form';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import FinalForm from 'components/form/Form';
import useStatus from 'components/status/hooks';
import TextField from 'components/form/TextField';
import SubmitButton from 'components/form/SubmitButton';
import { useNavigation } from 'components/navigation/hooks';
import {
	MUTATION_ADD_MY_AUTOCLAVE,
	QUERY_AUTOCLAVE_BY_SERIALNUMBER,
} from 'components/autoclaves/graphql';
import { onlyNumbers } from 'utils/formatters';

import Grid from 'UI/Grid';
import Button from 'UI/Button';
import Loading from 'UI/Loading';

import autoclave from 'assets/images/autoclave.png';
import validationSchema from './validationSchema';

const Form = styled.form`
	width: 100%;
	margin-top: ${props => props.theme.spacing(1)};
`;

const Image = styled.img`
	width: 100%;
	height: auto;
`;

const SerialNumber = styled(Typography)`
	color: #adadad;
	font-size: 1.2rem;
`;

const Model = styled(Typography)`
	font-size: 1rem;
`;

const INITIAL_VALUES = {
	serialNumber: '',
};

const MyAutoclaveAddForm = () => {
	const { addError } = useStatus();
	const { navigate } = useNavigation();
	const [autoclaveModel, setAutoclaveModel] = React.useState('_');

	const [autoclaveBySerialNumber, { data: autoclaveInfo, loading: autoclaveLoading }] =
		useLazyQuery(QUERY_AUTOCLAVE_BY_SERIALNUMBER);
	const [addMyAutoclave, { loading }] = useMutation(MUTATION_ADD_MY_AUTOCLAVE);

	const handleOnSubmit = React.useCallback(
		async serialNumber => {
			try {
				await addMyAutoclave({
					variables: {
						...serialNumber,
					},
				});
				navigate('/cliente/minhas-autoclaves');
			} catch (err) {
				console.log('handleOnSubmit error', err);
				throw err;
			}
		},
		[addMyAutoclave, navigate]
	);

	const handleSerialNumber = React.useCallback(
		async serialNumber => {
			try {
				if (serialNumber.length === 8) {
					await autoclaveBySerialNumber({
						variables: {
							serialNumber,
						},
					});
				} else if (serialNumber.length < 8 && autoclaveModel.length > 1) {
					setAutoclaveModel('_');
				}
			} catch (error) {
				addError(
					t({
						id: 'ui.error.tryLater',
						message: 'Houve um erro, tente novamente mais tarde.',
					})
				);
			}
		},
		[addError, autoclaveBySerialNumber, autoclaveModel]
	);

	React.useEffect(() => {
		const res = autoclaveInfo?.autoclaveBySerialNumber;

		if (!res) {
			return;
		}

		const modelName = autoclaveInfo?.autoclaveBySerialNumber?.model?.name;

		if (modelName) {
			setAutoclaveModel(modelName);
		} else {
			setAutoclaveModel(
				t({
					id: 'error.notFound',
					message: 'Não encontrado',
				})
			);
		}
	}, [addError, autoclaveInfo, autoclaveLoading]);

	return (
		<>
			{autoclaveLoading && <Loading fullScreen />}
			<Paper elevation={6}>
				<Box pt={4} pb={4} px={3}>
					<FinalForm
						formName="myAutoclaveAddForm"
						validateOnBlur={false}
						onSubmit={handleOnSubmit}
						initialValues={INITIAL_VALUES}
						validationSchema={validationSchema()}
						subscription={{
							values: true,
							errors: true,
							pristine: true,
						}}
						render={({ handleSubmit, submitting, pristine, values }) => {
							return (
								<Form onSubmit={handleSubmit}>
									<Grid container spacing={1}>
										<Grid item xs={12} md={4} lg={5}>
											<Box
												pr={3}
												pl={2}
												display="flex"
												alignItems="center"
												justifyContent="center"
												height="100%"
											>
												<Image
													src={autoclave}
													alt="Autoclave EVOXX"
												/>
											</Box>
										</Grid>
										<Grid item xs={12} md={8} lg={7}>
											<Box
												display="flex"
												flexDirection="column"
												justify="center"
												alignSelf="center"
											>
												<Box pb={0}>
													<SerialNumber>
														<Trans id="ui.abbr.serialNumber">
															S/N
														</Trans>{' '}
														{values.serialNumber}
													</SerialNumber>
													<Model>
														<Trans id="ui.model">
															Modelo
														</Trans>{' '}
														<strong>{autoclaveModel}</strong>
													</Model>
												</Box>
												<Field
													type="text"
													name="serialNumber"
													component="input"
													variant="outlined"
													required
													fullWidth
													label={t({
														id: 'ui.serialNumber',
														message: 'Número de série',
													})}
													autoComplete="off"
													maxLength={8}
													parse={onlyNumbers}
													onChange={handleSerialNumber}
												>
													{props => <TextField {...props} />}
												</Field>
												<Box pt={2} display="flex">
													<Box pr={2} display="inline-flex">
														<SubmitButton
															type="submit"
															size="large"
															variant="contained"
															loadingColor="accent"
															color="primary"
															disabled={submitting}
															isFormPristine={pristine}
															isLoading={
																submitting || loading
															}
														>
															<Trans id="ui.add">
																Adicionar
															</Trans>
														</SubmitButton>
													</Box>
													<Box pr={2} display="inline-flex">
														<Button
															type="button"
															size="large"
															variant="outlined"
															color="secondary"
															onClick={() =>
																navigate(
																	'/cliente/minhas-autoclaves'
																)
															}
														>
															<Trans id="ui.cancel">
																Cancelar
															</Trans>
														</Button>
													</Box>
												</Box>
											</Box>
										</Grid>
									</Grid>
								</Form>
							);
						}}
					/>
				</Box>
			</Paper>
		</>
	);
};

export default React.memo(MyAutoclaveAddForm);
