import React from 'react';
import { Trans, t } from '@lingui/macro';
import styled from 'styled-components/macro';
import { useLazyQuery } from '@apollo/client';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import Button from 'UI/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

import Grid from 'UI/Grid';
import Loading from 'UI/Loading';

import AutoclaveOwner from 'components/autoclaves/AutoclaveOwner';
import GenerateMaintenanceCode from 'components/maintenance/GenerateMaintenanceCode';
import ImportReportForm from 'components/autoclaves/ImportReportForm';
import { QUERY_CHECK_MAINTENANCE } from 'components/maintenance/graphql';
import { millissecondsToDateAndTime } from 'utils/date';

import SearchAutoclave from './SearchAutoclave';

const ButtonResponsive = styled(Button)`
	@media (max-width: 480px) {
		width: 100%;
	}
`;

const MaintenanceStepper = ({ onChange, serialNumber }) => {
	const [activeStep, setActiveStep] = React.useState(0);
	const [maintenance, setMaintenance] = React.useState(null);

	const [checkMaintenance, { data: recheckData, loading }] = useLazyQuery(
		QUERY_CHECK_MAINTENANCE,
		{
			fetchPolicy: 'network-only',
		}
	);

	const handleMaintenanceChange = React.useCallback(
		found => {
			if (found) {
				onChange(found.id);
				setMaintenance(found);
			} else {
				onChange(null);
				setMaintenance(null);
			}
		},
		[onChange]
	);

	const handleReportImportSuccess = React.useCallback(async () => {
		try {
			await checkMaintenance({
				variables: {
					serialNumber: maintenance?.autoclave?.serialNumber,
				},
			});
		} catch (err) {
			throw err;
		}
	}, [checkMaintenance, maintenance]);

	const handleGenerateCodeSuccess = React.useCallback(() => {
		handleReportImportSuccess();
	}, [handleReportImportSuccess]);

	const handleNext = React.useCallback(
		() => setActiveStep(prevActiveStep => prevActiveStep + 1),
		[]
	);

	const handleBack = React.useCallback(
		() => setActiveStep(prevActiveStep => prevActiveStep - 1),
		[]
	);

	const checkIsNextDisabled = React.useCallback(() => {
		if (activeStep === 0) {
			return (
				!maintenance ||
				(!!maintenance && !maintenance?.unlocked) ||
				(!!maintenance && maintenance?.maintenance?.done)
			);
		} else if (activeStep === 1) {
			return maintenance?.needUpdate || !maintenance?.maintenance;
		} else if (activeStep === 2) {
			return maintenance?.needUpdate || !maintenance?.maintenance?.code;
		}
	}, [activeStep, maintenance]);

	const getStepContent = React.useCallback(
		step => {
			switch (step) {
				case 0:
					return (
						<>
							{!!maintenance &&
								maintenance?.maintenance?.done &&
								!maintenance?.maintenance?.codeGeneratedAt && (
									<Box mb={2}>
										<Alert severity="info">
											<Trans id="maintenance.inconsistencyData">
												Inconsistência no histórico de manutenção,
												por favor, entre em contato com a equipe
												de suporte.
											</Trans>
										</Alert>
									</Box>
								)}

							{!!maintenance &&
								maintenance?.maintenance?.done &&
								maintenance?.maintenance?.codeGeneratedAt && (
									<Box mb={2}>
										<Alert severity="info">
											<Trans id="maintenance.alreadyDone">
												Manutenção de{' '}
												{maintenance?.maintenance?.ofCycles}{' '}
												ciclos já <b>realizada</b> em{' '}
												{millissecondsToDateAndTime(
													maintenance?.maintenance
														?.codeGeneratedAt
												)}
												.
											</Trans>
										</Alert>
									</Box>
								)}

							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Typography align="left">
										<Trans id="maintenance.addSerialNumber">
											Informe o Número de Série da Autoclave Evoxx
											para iniciar.
										</Trans>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<SearchAutoclave
										serialNumber={serialNumber}
										onChange={handleMaintenanceChange}
									/>
								</Grid>
								{!!maintenance && !maintenance?.unlocked && (
									<Grid item xs={12}>
										<Box mt={4}>
											<Alert severity="error">
												<Trans id="maintenance.notUnlocked">
													Esta Autoclave EVOXX não foi
													desbloqueada.
												</Trans>
											</Alert>
										</Box>
									</Grid>
								)}
							</Grid>
						</>
					);

				case 1:
					return (
						<>
							{!!maintenance &&
								!maintenance?.needUpdate &&
								!maintenance?.maintenance && (
									<>
										<Box mb={2}>
											<Alert severity="info">
												<Trans id="maintenance.cyclesNotReached600">
													Esta Autoclave EVOXX não atingiu os
													600 ciclos.
													<br />
												</Trans>
											</Alert>
										</Box>
										<Box mb={2}>
											<Alert severity="warning">
												<Trans id="maintenance.cyclesNotReached">
													<b>
														Caso a Autoclave esteja
														solicitando manutenção
													</b>
													: Faça um novo ciclo de manutenção na
													Autoclave e{' '}
													<b>envie o novo arquivo</b> para
													continuar.
												</Trans>
											</Alert>
										</Box>
									</>
								)}

							{!!maintenance &&
								maintenance?.needUpdate &&
								!recheckData?.checkMaintenance && (
									<Box mb={2}>
										<Alert severity="warning">
											<Trans id="maintenance.needCycleUpdate">
												É necessário enviar um relatório de ciclos
												atual para continuar.
											</Trans>
										</Alert>
									</Box>
								)}

							{!!maintenance &&
								!maintenance?.needUpdate &&
								maintenance?.maintenance && (
									<Box mb={2}>
										<Alert severity="info">
											<Trans id="maintenance.updatedCanProceed">
												<b>Registro está atualizado</b>, envie um
												novo relatório ou <b>clique em Avançar</b>
												.
											</Trans>
										</Alert>
									</Box>
								)}

							{recheckData?.checkMaintenance?.needUpdate && (
								<Box mb={2}>
									<Alert severity="error">
										<Trans id="maintenance.fileDate">
											Arquivo precisa ser de um ciclo feito em no{' '}
											<b>máximo 2 dias</b>.
											<br />
											<b>Faça um novo ciclo de manutenção</b> na
											Autoclave e <b>envie o novo arquivo</b> para
											continuar.
										</Trans>
									</Alert>
								</Box>
							)}

							<Box mb={2}>
								<Typography align="left">
									<Trans id="report.selectFile">
										<Trans id="maintenance.selectFileToCreateMaintenanceReport">
											Selecione o arquivo .txt do cartão de memória
											da Autoclave para importar e criar o relatório
											de manutenção.
										</Trans>
									</Trans>
								</Typography>
							</Box>

							<ImportReportForm
								serialNumber={maintenance?.autoclave?.serialNumber}
								onSuccess={handleReportImportSuccess}
							/>
						</>
					);

				case 2:
					return (
						<GenerateMaintenanceCode
							code={maintenance?.maintenance?.code}
							ofCycles={maintenance?.maintenance?.ofCycles}
							autoclave={maintenance?.autoclave}
							onSuccess={handleGenerateCodeSuccess}
						/>
					);

				case 3:
					return (
						<>
							<Box mb={2}>
								<Typography align="left">
									<Trans id="maintenance.doCycleToConfirm">
										Faça um <b>novo ciclo de manutenção</b> depois de
										inserir o código na Autoclave e{' '}
										<b>envie o novo arquivo</b> para concluir e
										confirmar a manutenção.
									</Trans>
								</Typography>
							</Box>

							<ImportReportForm
								serialNumber={maintenance?.autoclave?.serialNumber}
								onSuccess={handleReportImportSuccess}
							/>

							<Box mt={2}>
								<Alert severity="info">
									<Trans id="maintenance.confirmLater">
										Você pode confirmar depois{' '}
										<b>caso algo aconteça</b>, para isso acesse
										Relatório e clique em Adicionar para enviar o
										relatório. Entretanto é aconselhável que você{' '}
										<b>não pule</b> essa etapa.
									</Trans>
								</Alert>
							</Box>
						</>
					);

				default:
					return '...';
			}
		},
		[
			serialNumber,
			handleMaintenanceChange,
			maintenance,
			recheckData,
			handleReportImportSuccess,
			handleGenerateCodeSuccess,
		]
	);

	React.useEffect(() => {
		if (!loading && !!recheckData) {
			handleMaintenanceChange(recheckData?.checkMaintenance);
		}
	}, [recheckData, handleMaintenanceChange, loading]);

	const isNextDisabled = checkIsNextDisabled();

	const STEPS = [
		t({
			id: 'ui.serialNumber',
			message: 'Número de Série',
		}),
		t({
			id: 'ui.sendReport',
			message: 'Enviar Relatório',
		}),
		t({
			id: 'ui.maintenanceCode',
			message: 'Código de Manutenção',
		}),
		t({
			id: 'ui.confirm',
			message: 'Confirmar',
		}),
	];

	return (
		<>
			{loading && <Loading fullScreen />}

			<Box px={5} pb={3}>
				<AutoclaveOwner
					user={maintenance?.autoclave?.userId}
					serialNumber={maintenance?.autoclave?.serialNumber}
					modelName={maintenance?.autoclave?.model?.name}
				/>
			</Box>

			<Stepper activeStep={activeStep} orientation="vertical">
				{STEPS.map((label, index) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>

						<StepContent>
							<Box mt={2}>{getStepContent(index)}</Box>

							<Box p={1} mt={4} mb={2} display="flex" bgcolor="#f7f7f7">
								<Grid container spacing={1}>
									<Grid item container xs={12} sm={6}>
										{activeStep !== 0 && (
											<ButtonResponsive
												disabled={activeStep === 0}
												onClick={handleBack}
												startIcon={<NavigateBeforeIcon />}
											>
												<Trans id="ui.back">Voltar</Trans>
											</ButtonResponsive>
										)}
									</Grid>
									<Grid
										item
										container
										justify="flex-end"
										xs={12}
										sm={6}
									>
										<ButtonResponsive
											variant="contained"
											color="primary"
											onClick={handleNext}
											disabled={isNextDisabled}
											endIcon={<NavigateNextIcon />}
										>
											{activeStep === STEPS.length - 1
												? t({
														id: 'ui.finish',
														message: 'Finalizar',
												  })
												: t({
														id: 'ui.next',
														message: 'Avançar',
												  })}
										</ButtonResponsive>
									</Grid>
								</Grid>
							</Box>
						</StepContent>
					</Step>
				))}
			</Stepper>

			{activeStep === STEPS.length && (
				<Paper square elevation={0}>
					<Box mt={2}>
						<Alert severity="info">
							<Trans id="maintenance.completed">
								<b>Finalizado</b>. Lembre-se de de confirmar a manutenção
								caso não tenha feito.
							</Trans>
						</Alert>
					</Box>
				</Paper>
			)}
		</>
	);
};

export default React.memo(MaintenanceStepper);
