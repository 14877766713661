import { object, string } from 'yup';
import { t } from '@lingui/macro';

const validationSchema = () => {
	const required = t({
		id: 'validation.required',
		message: 'Requirido',
	});

	return object().shape({
		email: string().nullable().required(required),
		password: string().nullable().required(required),
	});
};

export default validationSchema;
