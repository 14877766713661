const roles = {
	ADMIN: ['ADMIN'],
	USER: ['CUSTOMER', 'TECHNICIAN', 'ADMIN'],
};

export default {
	api: process.env.REACT_APP_API_URL,
	appUrl: process.env.REACT_APP_APP_URL,
	storagekey: 'exxe_',
	roles,
};
