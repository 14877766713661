import React from 'react';
import styled from 'styled-components/macro';

const Content = styled.div`
	flex-grow: 1;
	overflow: auto;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	/* width: calc(100vw - 290px); */

	@media (max-width: 600px) {
		min-width: calc(100vw - 57px);
	}
`;

const TheContent = ({ children, drawerIsOpen }) => (
	<Content $drawerIsOpen={drawerIsOpen}>{children}</Content>
);

export default React.memo(TheContent);
